import {Grid, Typography} from "@material-ui/core";
import {useTranslation} from 'react-i18next';
import {formatDate} from 'shared/utility';

export default function ConsolidatedInvoiceDetails({consolidatedInvoice}) {
  const [t] = useTranslation();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h3">
          {t("payments.consolidatedInvoice")}
        </Typography>
      </Grid>
      {consolidatedInvoice.id &&
      <Grid item xs={12}>
        <Typography variant="subtitle1" color="primary">{t("payments.invoiceDate")}</Typography>
        <Typography variant="body1">{formatDate(consolidatedInvoice.invoiceDate)}</Typography>
      </Grid>}
      {consolidatedInvoice.id &&
      <Grid item xs={12}>
        <Typography variant="subtitle1" color="primary">{t("payments.supplyOfService")}</Typography>
        <Typography variant="body1">{formatDate(consolidatedInvoice.supplyOfService)}</Typography>
      </Grid>}
      {consolidatedInvoice.id &&
      <Grid item xs={12}>
        <Typography variant="subtitle1" color="primary">{t("payments.invoiceNumber")}</Typography>
        <Typography variant="body1">{consolidatedInvoice.invoiceNumber}</Typography>
      </Grid>}
    </Grid>
  );
}
