import {useDeepCompareEffect} from 'shared/useDeepCompareEffect';
import {TableRow, TableFooter, TableCell} from "@material-ui/core";
import {formatDecimal} from 'shared/utility';

import {getCommissionBasedData} from './Types/CommissionBased';
import {getQuantityBasedData} from './Types/AmountBased';
import {getNegotiationBasedData} from './Types/NegotiationBasedFee';
import {getSelectBasedData} from './Types/SelectBasedFee';

export default function CustomFooter({invoiceLines, updateTotals}) {

  const totals = {}

  invoiceLines.forEach((invoiceLine, index) => {
    switch (invoiceLine.commissionFee?.type || "NEGOTIATED") {
      case "QUANTITY_BASED":
        totals[index] = getQuantityBasedData(invoiceLine)
        return;
      case "COMMISSION_BASED":
        totals[index] = getCommissionBasedData(invoiceLine)
        return;
      case "NEGOTIATED":
        totals[index] = getNegotiationBasedData(invoiceLine)
        return;
      case "SELECTION_BASED":
        totals[index] = getSelectBasedData(invoiceLine)
        return;
      default:
        return "Fee type not found.";
    }
  });

  const keys = Object.keys(totals);

  useDeepCompareEffect(() => {
    updateTotals(totals)
  }, [totals, updateTotals])

  return (
    <TableFooter>
      <TableRow>
        <TableCell/>
        <TableCell align={"right"}/>
        <TableCell align={"right"}/>
        <TableCell align={"right"}/>
        <TableCell align={"right"}>
          {totals && formatDecimal(keys.reduce((a, b) => a + totals[b].netAmount, 0))}
        </TableCell>
        <TableCell align={"right"}/>
        <TableCell align={"right"}>
          {totals && formatDecimal(keys.reduce((a, b) => a + totals[b].vatEUR, 0))}
        </TableCell>
        <TableCell align={"right"}>
          {totals && formatDecimal(keys.reduce((a, b) => a + totals[b].totalEUR, 0))}
        </TableCell>
      </TableRow>
    </TableFooter>);
}
