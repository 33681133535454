import {useEffect} from 'react';
import {useSetUser} from '@postinumero/authorization';
import localStorageJson from '@postinumero/storage/localStorageJson';
import sessionStorageJson from '@postinumero/storage/sessionStorageJson';
import useLocalStorageJsonValue from "@postinumero/storage/useLocalStorageJsonValue";
import useSessionStorageJsonValue from "@postinumero/storage/useSessionStorageJsonValue";

const KEY = 'user';

export function useDetectLoginChange() {
  const setUser = useSetUser();
  const local = useLocalStorageJsonValue(KEY);
  const session = useSessionStorageJsonValue(KEY);
  const user = session ?? local ?? null;
  useEffect(() => {
    setUser(user);
  }, [setUser, user]);
}

export function logIn({username, password, rememberLogin}) {
  const mockUser = {
    name: username,
    roles: username === 'admin' ? ['admin'] : undefined
  };
  // Maybe set auth token
  // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  (rememberLogin
      ? localStorageJson
      : sessionStorageJson
  ).setItem(KEY, mockUser);
}

export function logOut() {
  localStorageJson.clear();
  sessionStorageJson.clear();
  // delete axios.defaults.headers.common['Authorization'];
}
