import {Select, MenuItem, FormControl, InputLabel, Box} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

export default function ReusableSelect({reusableBeneficiaries, selected, setSelected}) {
  const [t] = useTranslation();

  return (
    <div>
      <Box my={2}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>
            {t('reusables.beneficiaryLabel')}
          </InputLabel>
          <Select
            labelId="label-selected"
            onChange={(event) => setSelected(event.target.value)}
            value={selected}
            label={t('reusables.beneficiaryLabel')}
            fullWidth
          >
            {reusableBeneficiaries.map((beneficiary =>
              <MenuItem key={beneficiary.id} value={beneficiary.id}>
                {beneficiary.dataName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
}
