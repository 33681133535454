import {useTranslation} from 'react-i18next';
import MUIDataTable from 'mui-datatables';
import {MuiThemeProvider} from '@material-ui/core';
import {theme} from './tableTheme';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CallMergeIcon from '@material-ui/icons/CallMerge';
import {useHistory} from 'react-router-dom';
import {formatDecimal, formatDate, removeUnderscores} from 'shared/utility';


export default function Table({invoices, user}) {
  const [t] = useTranslation();
  const history = useHistory();

  const columns = [
    {
      name: "id",
      options: {
        customBodyRender: value => {
          const invoice = invoices.find(invoice => invoice?.id === value)
          if (invoice) {
            return (
              invoice.isCons ?
                <CallMergeIcon color="primary"/> :
                <CallMadeIcon color="primary" style={{ transform: invoice.corporateAction.organisation !== user.groups ? 'none ': 'rotate(90deg)'}}/>
            )
          } else {
            return <div/>
          }
        },
        customHeadLabelRender: () => ""
      }
    },
    {
      label: t("invoices.issuer"),
      name: "corporateAction",
      options: { customBodyRender: (value) => (<div>{value?.issuer}</div>), sort: false}
    },
    {
      label: t("invoices.eventType"),
      name: "corporateAction",
      options: { customBodyRender: (value) => (<div>{value?.actionEventType?.type}</div>), sort: false}
    },
    {
      label: t("invoices.beneficiary"),
      name: "beneficiaryData",
      options: { customBodyRender: (value) => (<div>{value?.beneficiaryName}</div>), sort: false}
    },
    {
      label: t("invoices.total"),
      name: "totalAmount",
      options: { customBodyRender: (value) => (<div>{formatDecimal(value)}</div>), sort: false }
    },
    {
      label: t("invoices.state"),
      name: "currentStatus",
      options: { customBodyRender: (value) => (<div>{removeUnderscores(value)}</div>), sort: false },
    },
    {
      label: t("invoices.eventId"),
      name: "corporateAction",
      options: { customBodyRender: (value) => (<div>{value?.eventId}</div>), sort: false}
    },
    {
      label: t("invoices.invoiceDate"),
      name: "invoiceDate",
      options: { customBodyRender: (value) => (<div>{formatDate(value)}</div>), sort: true } },
    {
      label: t("invoices.dueDate"),
      name: "dueDate",
      options: { customBodyRender: (value) => (<div>{formatDate(value)}</div>), sort: true }
    },
  ]

  const options = {
    pagination: false,
    print: false,
    download: false,
    filter: false,
    search: false,
    responsive: 'standard',
    viewColumns: false,
    selectableRows: 'none',
    elevation: 0,
    onRowClick: (rowData, rowState) => {
      const invoice = invoices[rowState.dataIndex]
      const path = invoice.isCons ? 'consolidated-invoice' : 'invoice'
      history.push(`/${path}/${invoice.id}`)
    },
    sortOrder: { direction: 'desc', name: "invoiceDate" }
  };

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <MUIDataTable
          columns={columns}
          data={invoices}
          options={options}
        />
      </MuiThemeProvider>
    </>
  );
}
