import {useState} from 'react'
import Grid from '@material-ui/core/Grid';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {ValidationProvider} from 'shared/useValidation';
import {
  useBankHolidaysDate,
  useBankHolidaysWithinMonth,
  postInvoicingPeriod,
  putInvoicingPeriod,
  removeInvoicingPeriod
} from 'api';
import Actions from './Actions';
import Fields from './Fields';
import Status from '../../CorporateAction/Status';
import {ValidationErrors, DeletionConfirmationDialog} from 'shared/FormComponents';
import {corporateActions} from 'App/Pages';
import {formatDate} from 'shared/utility';
import useModal from 'shared/useModal';
import useSnackBar from 'shared/SnackBar/useSnackBar';
import {useReusablePayerDetails} from 'api';
import {INVALID} from 'shared/Statuses';
import InfoDialog from './InfoDialog';

export default function Form({corporateAction, invoicingPeriod}) {
  const [t] = useTranslation();
  const history = useHistory();
  const {open, handleOpen, handleClose} = useModal();
  const {open: show, handleOpen: handleShow, handleClose: handleHide} = useModal();
  const [newStatus, setNewStatus] = useState();
  const {showError} = useSnackBar();
  const saveInvoicingPeriod = invoicingPeriod ? putInvoicingPeriod : postInvoicingPeriod;
  let defStartDate = formatDate(new Date(), 'YYYY-MM-DD');
  if (corporateAction && corporateAction.paymentDate && corporateAction.paymentDate > defStartDate) {
    defStartDate = formatDate(corporateAction.paymentDate, 'YYYY-MM-DD');
  }
  const defLockedDate = useBankHolidaysDate(defStartDate, 14);
  const bankHolidays = useBankHolidaysWithinMonth().map(bankHoliday => new Date(bankHoliday));
  const reusablePayerDetails = useReusablePayerDetails('Commission');
  const sortedPayerDetails = reusablePayerDetails.sort((a, b) => a.dataName.localeCompare(b.dataName));
  const [selectedDetail, setSelectedDetail] = useState(invoicingPeriod?.payerName ? -1 :
    (sortedPayerDetails.length ? sortedPayerDetails[0].id : -1));
  const details = [{id: -1, dataName: t("invoicingPeriod.manually")}].concat(sortedPayerDetails);

  const findDefaultDueDate = () => {
    if (corporateAction && corporateAction.paymentDate) {
      const date = new Date(corporateAction.paymentDate);
      date.setDate(date.getDate() + 28);

      while (bankHolidays.some(bh => bh.getDate() === date.getDate())) {
        date.setDate(date.getDate() + 1);
      }

      return formatDate(date, 'YYYY-MM-DD');
    }

    return null;
  };

  const initialPayerDetail = selectedDetail > -1 ? reusablePayerDetails.find(detail => detail.id === selectedDetail) : null;
  const config = fields => ({
    inputs: {
      startDate: {
        isRequired: {message: t("validation.isRequired", {name: t("invoicingPeriod.startDate")})},
        isValidDate: {message: t("validation.isValidDate", {name: t("invoicingPeriod.startDate")})},
        isBefore: {
          value: fields.endDate,
          message: t("validation.isValidDate", {name: t("invoicingPeriod.startDate")})
        },
        initialValue: invoicingPeriod ? invoicingPeriod.startDate : defStartDate,
      },
      endDate: {
        isRequired: {message: t("validation.isRequired", {name: t("invoicingPeriod.endDate")})},
        isValidDate: {message: t("validation.isValidDate", {name: t("invoicingPeriod.endDate")})},
        isTodayOrLater: {message: t("validation.isTodayOrLater", {name: t("invoicingPeriod.endDate")})},
        initialValue: invoicingPeriod ? invoicingPeriod.endDate : defLockedDate?.date
      },
      payerName: {
        isRequired: {message: t("validation.isRequired", {name: t("invoicingPeriod.payerName")})},
        initialValue: invoicingPeriod?.payerName ?? initialPayerDetail?.payerDetails?.payerName ?? ''
      },
      deliveryAddress: {
        isRequired: {message: t("validation.isRequired", {name: t("deliveryDetails.deliveryAddress")})},
        initialValue: invoicingPeriod?.deliveryAddress ?? initialPayerDetail?.payerDetails?.deliveryAddress ?? ''
      },
      postalCode: {
        isRequired: {message: t("validation.isRequired", {name: t("deliveryDetails.postalCode")})},
        isValidFinnishPostalCode: {
          value: fields.country,
          message: t("validation.isValidFinnishPostalCode", {name: t("deliveryDetails.postalCode")})
        },
        initialValue: invoicingPeriod?.postalCode ?? initialPayerDetail?.payerDetails?.postalCode ?? ''
      },
      city: {
        isRequired: {message: t("validation.isRequired", {name: t("deliveryDetails.city")})},
        initialValue: invoicingPeriod?.city ?? initialPayerDetail?.payerDetails?.city ?? ''
      },
      email: {
        isRequired: {message: t("validation.isRequired", {name: t("deliveryDetails.email")})},
        isEmail: {message: t("validation.isEmail", {name: t("deliveryDetails.email")})},
        initialValue: invoicingPeriod?.email ?? initialPayerDetail?.payerDetails?.email ?? ''
      },
      spareLine: {
        initialValue: invoicingPeriod?.spareLine ?? ''
      },
      deliveryAddressSecondLine: {
        initialValue: invoicingPeriod?.deliveryAddressSecondLine ?? ''
      },
      payerNameSupplement: {
        initialValue: invoicingPeriod?.payerNameSupplement ?? ''
      },
      country: {
        initialValue: invoicingPeriod?.country ?? ''
      },
      instructionsForAO: {
        initialValue: invoicingPeriod?.instructionsForAO ?? ''
      },
      electronicInvoiceAddress: {
        initialValue: invoicingPeriod?.electronicInvoiceAddress ?? ''
      },
      electronicInvoiceIntermediator: {
        initialValue: invoicingPeriod?.electronicInvoiceIntermediator ?? ''
      },
      dueDate: {
        isRequired: {message: t("validation.isRequired", {name: t("invoicingPeriod.dueDate")})},
        isValidDate: {message: t("validation.isValidDate", {name: t("invoicingPeriod.dueDate")})},
        initialValue: invoicingPeriod?.dueDate ?? findDefaultDueDate()
      }
    },
    showErrors: 'submit',
    onClick: async (state, status, closeDialog) => {
      setNewStatus(status);
      if (!state.errors || Object.keys(state.errors).length === 0 || status === INVALID) {
        const selectedPayerDetail = reusablePayerDetails.find(detail => detail.id === selectedDetail)
        const caId = invoicingPeriod ? invoicingPeriod.corporateActionEventId : corporateAction.id;
        const ip = invoicingPeriod ? {
          ...state.values,
          id: invoicingPeriod.id,
          currentStatus: status,
          corporateActionEventId: caId
        } : {...state.values, corporateActionEventId: caId, currentStatus: status};
        const payload = selectedPayerDetail ? {...ip, ...selectedPayerDetail.payerDetails} : ip
        await saveInvoicingPeriod(payload).then((resp) => {
          const redr = corporateAction?.id ?? invoicingPeriod?.corporateActionEventId;
          history.push({pathname: corporateActions, search: '?ca=' + redr})
        }).catch((err) => {
          if (err.response.type === 'dialog') {
            handleShow()
          } else {
            showError(err.response)
          }
        });
      } else {
        if (closeDialog) {
          closeDialog();
        }
      }
    },
    deletionConfirmationFunction: handleOpen,
    deleteFunction: () => {
      removeInvoicingPeriod(invoicingPeriod.id).then(() => {
        const redr = corporateAction?.id ?? invoicingPeriod?.corporateActionEventId;
        history.push({pathname: corporateActions, search: '?ca=' + redr});
      }).catch((err) => {
        showError(err.response)
      });
    }
  });
  return (
    <ValidationProvider config={config}>
      <DeletionConfirmationDialog onClose={handleClose} open={open} itemName={t('invoicingPeriod.name')}/>
      <InfoDialog open={show} onClose={handleHide}/>
      <Status statuses={invoicingPeriod && invoicingPeriod.statuses}/>
      <Grid container spacing={5} justify="center">
        <Grid item lg={9} md={12}>
          {newStatus !== INVALID && <ValidationErrors/>}
        </Grid>
        <Fields
          corporateAction={corporateAction}
          status={invoicingPeriod?.currentStatus ? invoicingPeriod?.currentStatus : 'DRAFT'}
          selected={selectedDetail} setSelected={setSelectedDetail}
          details={details}
          disableInputs={true}
        />
        <Actions status={invoicingPeriod?.currentStatus ? invoicingPeriod?.currentStatus : 'NOT_SAVED'}
                 caId={invoicingPeriod?.corporateActionEventId ?? corporateAction?.id}/>
      </Grid>
    </ValidationProvider>
  );
}
