import {useContext} from 'react';
import Grid from '@material-ui/core/Grid';
import {useTranslation} from 'react-i18next';
import {
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  MenuItem,
  InputLabel,
  Select,
  RadioGroup
} from '@material-ui/core';
import {DateInput} from "shared/FormComponents";
import {formatDate} from 'shared/utility';
import {ValidationContext} from 'shared/useValidation';


export default function Fields({caeTypes, disableInputs}) {
  const [t] = useTranslation();
  const {values, getInputProps, setValue} = useContext(ValidationContext);
  return (
    <>
      <Grid item container lg={4} md={6} spacing={2} alignContent={'flex-start'}>
        <Grid item sm={12}>
          <TextField
            variant="outlined"
            label={t('event.issuer')}
            required
            fullWidth
            {...getInputProps('issuer')}
            disabled={disableInputs}
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            variant="outlined"
            label={t('event.issuerBusinessId')}
            required
            fullWidth
            {...getInputProps('issuerBusinessId')}
            disabled={disableInputs}
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            variant="outlined"
            label={t('event.issuerISIN')}
            fullWidth
            {...getInputProps('issuerISIN')}
            disabled={disableInputs}
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            variant="outlined"
            label={t('event.instructionsForAO')}
            multiline
            rows={4}
            fullWidth
            {...getInputProps('instructionsForAO')}
            disabled={disableInputs}
          />
        </Grid>
      </Grid>
      <Grid item container lg={4} md={6} spacing={2} alignContent={'flex-start'}>
        <Grid item sm={12}>
          <TextField
            variant="outlined"
            label={t('event.eventId')}
            fullWidth
            {...getInputProps('eventId')}
            disabled={disableInputs}
          />
        </Grid>
        <Grid item sm={12}>
          <FormControl variant="outlined" required fullWidth>
            <InputLabel id="label-eventType">{t('event.actionEventType')}</InputLabel>
            <Select
              labelId="label-eventType"
              required
              {...getInputProps('actionEventType')}
              onChange={(event) => {
                setValue('actionEventType', event.target.value);
                setValue('eventClassification', caeTypes.find(cae => event.target.value === cae.id).eventClassification)
              }}
              label={t('event.actionEventType')}
              disabled={disableInputs}
            >
              {caeTypes.map(((type, index) => <MenuItem key={type.id}
                                                        value={type.id}>{type.type} ({type.description})</MenuItem>))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={12}>
          <FormControl component="fieldset" style={{margin: '20px 0'}}>
            <FormLabel
              component="legend"
              required
              style={{fontSize: '0.75rem', marginBottom: '10px'}}
            >
              {t('event.eventClassification')}
            </FormLabel>
            <RadioGroup>
              <FormControlLabel
                control={<Radio required/>}
                label={t('event.mandatory')}
                checked={values?.actionEventType && Array.isArray(caeTypes) ? caeTypes.find(cae => values.actionEventType === cae.id).eventClassification === 'MANDATORY' : false}
                {...getInputProps('eventClassification', {value: 'MANDATORY', error: ''})}
                disabled={true}
              />
              <FormControlLabel
                control={<Radio required/>}
                label={t('event.voluntary')}
                checked={values?.actionEventType && Array.isArray(caeTypes) ? caeTypes.find(cae => values.actionEventType === cae.id).eventClassification === 'VOLUNTARY' : false}
                {...getInputProps('eventClassification', {value: 'VOLUNTARY', error: ''})}
                disabled={true}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item sm={6}>
          <DateInput
            label={t('event.recordDate')}
            required
            error={false}
            helperText={null}
            {...getInputProps('recordDate', {
              onChange: (event) => {
                setValue('recordDate', formatDate(event, 'YYYY-MM-DD'))
              }
            })}
            disabled={disableInputs}
          />
        </Grid>
        <Grid item sm={6}>
          <DateInput
            label={t('event.paymentDate')}
            required={values?.eventClassification === "MANDATORY"}
            error={false}
            helperText={null}
            initialFocusedDate={values?.recordDate ?? new Date()}
            minDate={values?.recordDate ?? new Date('1900-01-01')}
            {...getInputProps('paymentDate', {
              onChange: (event) => {
                setValue('paymentDate', formatDate(event, 'YYYY-MM-DD'))
              }
            })}
            disabled={disableInputs}
          />
        </Grid>
      </Grid>
    </>
  );
}
