import Button from '@material-ui/core/Button';
import {useUser} from '@postinumero/authorization';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import {useKeycloak} from '@react-keycloak/web';
import UserMenu from './UserMenu';
import Navigation from './Navigation';
import {useHistory} from 'react-router-dom';
import {useIdleTimer} from 'react-idle-timer';

const useStyles = makeStyles(theme => ({
  logo: {
    fontSize: '2.6rem',
    color: 'white',
    fontWeight: '600',
    lineHeight: '1em',
    textTransform: 'lowercase',
    paddingRight: '30px',
    [theme.breakpoints.down('xs')]: {
      width: 48
    }
  },
  title: {
    marginLeft: theme.spacing(1),
    flexGrow: 1
  },
  logBtn: {
    color: 'white'
  },
  alignright: {
    marginRight: 0,
    marginLeft: 'auto'
  }
}));

export default function AppBar() {
  const classes = useStyles();
  const user = useUser();
  const [t] = useTranslation();
  const {keycloak} = useKeycloak();
  const history = useHistory();
  const logout = ({idle=false}) => {
    if (idle) {
      history.push({search: '?idle=1'})
    } else {
      history.push('/')
    }
    keycloak.logout();
    localStorage.clear();
  }
  const handleOnIdle = () => {
    if (keycloak.authenticated) logout({idle: true})
  }

  const minute = 1000 * 60
  useIdleTimer({
    timeout: minute * 30,
    onIdle: handleOnIdle
  })

  return (
    <>
      <div className={classes.logo}>fees</div>
      <Navigation/>
      <div className={classes.alignright}>
        {user ? <UserMenu actionLogout={logout}/> :
          <Button className={classes.logBtn} onClick={() =>
            keycloak.login(
              process.env.KEYCLOAK_LOGIN_OPTIONS &&
              JSON.parse(process.env.KEYCLOAK_LOGIN_OPTIONS)
            )}
          >
            {t('user.login')}
          </Button>}
      </div>

    </>
  );
}
