import {useTranslation} from 'react-i18next';
import {useFeeType} from "api";
import {Typography, Grid, Link} from "@material-ui/core";
import DataTable from './DataTable';
import {useState} from 'react';
import {useDeepCompareEffect} from 'shared/useDeepCompareEffect';

const formLines = (lines, tariffData, invoiceId) => {
  if (Array.isArray(lines) && lines.length > 0) {
    return lines;
  }

  const newLines = [];
  if (!invoiceId && (!Array.isArray(lines) || lines.length < 1) && tariffData?.commissionFees.length > 0) {
    tariffData.commissionFees.forEach(commissionFee => {
      const newLine = {
        invoiceId: invoiceId ? invoiceId : null,
        commissionFee: commissionFee,
        quantity: '',
        amount: '',
        vat: '',
        description: '',
        selectionId: ''
      };
      newLines.push(newLine);
    })
  }

  return newLines;
};

/**{"id":10005,"organisation":"Test bank","corporateActionEventType":"Stock dividend payment (DVSE)","infinityEventType":"12345","publishDate":"2020-01-01","validUntil":"2022-01-01","commissionFees":[{"id":10008,"type":"AMOUNT_BASED","description":"This is some other description","pricePerAmount":50.00,"minimumPrice":100.00,"fixedFee":0.00,"vat":23.0}]}**/
export default function CommissionFees({caType, disableInputs, updateState, lines, invoiceId, validateLines, showLineErrors, setShowLineErrors}) {
  const [t] = useTranslation();
  const tariffData = useFeeType(caType);
  const defaultLines = formLines(lines, tariffData, invoiceId)
  const [invoiceLines, setLines] = useState(defaultLines);

  useDeepCompareEffect(() => {
    updateState(invoiceLines, 'invoiceLines');
  }, [invoiceLines]);
  const updateLine = (lineIndex, newLine) => {
    let tempArr = [...invoiceLines];

    if (!newLine) {
      tempArr.splice(lineIndex, 1)
    } else {
      tempArr[lineIndex] = newLine;
    }

    if (validateLines(tempArr) && showLineErrors) {
      setShowLineErrors(false);
    }

    setLines(tempArr);
    updateState(invoiceLines, 'invoiceLines');
  };

  const addLine = () => {
    const newLine = {
      description: '',
      vat: '',
      amount: ''
    }
    setLines([...invoiceLines, newLine])
  }

  return (
    <Grid item xs={12}>
      <Typography variant="h3">
        {t("commissionFees.title")}
        {!disableInputs && <Link component="button" type="button" onClick={addLine}>
          <Typography style={{marginLeft: '10px'}} variant="overline">Add fee</Typography>
        </Link>}
      </Typography>
      <DataTable
        disableInputs={disableInputs}
        invoiceLines={invoiceLines}
        updateLine={updateLine}
      />
    </Grid>);
}
