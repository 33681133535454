import {Button, Grid} from "@material-ui/core";
import {useContext} from "react";
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {ValidationContext} from "shared/useValidation";
import {DRAFT, READYTOSEND, INVOICESENT, DONE, PAID, INVALID, SENDINGREQUESTED} from "shared/Statuses";
import useModal from 'shared/useModal';
import CloseConfirmationDialog from "./CloseConfimationDialog";
import InvalidateConfirmationDialog from './InvalidateConfirmationDialog';
import {payments, corporateActions} from "../../index";

export default function Actions({status, invoiceExist, disableActions, id}) {
  const [t] = useTranslation();
  const {getActionProps, getDeletionConfirmationProp, getExportProps} = useContext(ValidationContext);
  const {open, handleOpen, handleClose} = useModal();
  const {open: show, handleOpen: handleShow, handleClose: handleHide} = useModal();
  const history = useHistory();
  const exportableStatuses = [INVOICESENT, PAID, DONE, INVALID]
  const canBeInvalidated = invoiceExist && status && [INVOICESENT, PAID, DONE].includes(status)

  return (
    <>
      <CloseConfirmationDialog open={open} onConfirm={history.goBack} onCancel={handleClose}/>
      <InvalidateConfirmationDialog open={show} onClose={handleHide}/>
      <Grid item>
        <Button
          onClick={!invoiceExist ? handleOpen : () => history.push({pathname: corporateActions})}
          variant="outlined"
          size="large"
          color="primary">
          {t("form.cancel")}
        </Button>
      </Grid>
      {invoiceExist &&
      <Grid item>
        <Button
          onClick={window.print}
          variant="outlined"
          size="large"
          color="primary">
          {t("invoice.print")}
        </Button>
      </Grid>}
      {!disableActions && <>
        {canBeInvalidated &&
        <Grid item>
          <Button
            variant="outlined"
            size="large"
            color="primary"
            onClick={handleShow}
          >
            {t("consolidatedInvoice.invalidate")}
          </Button>
        </Grid>}
        {invoiceExist && status && (status === DRAFT || status === READYTOSEND) && <Grid item>
          <Button
            {...getDeletionConfirmationProp()}
            variant="outlined"
            size="large"
            color="primary">
            {t("form.remove")}
          </Button>
        </Grid>}
        {status && exportableStatuses.includes(status) &&
        <Grid item>
          <Button
            {...getExportProps()}
            variant="outlined"
            size="large"
            color="primary">
            {t("form.export")}
          </Button>
        </Grid>}
        {invoiceExist && status &&
        <Grid item>
          <Button
            onClick={() => history.push({pathname: payments.replace(":id?", id)})}
            variant="outlined"
            size="large"
            color="primary">
            {t("consolidatedInvoice.showPayments")}
          </Button>
        </Grid>}
        {status && status === DRAFT &&
        <Grid item>
          <Button
            {...getActionProps(READYTOSEND)}
            variant="outlined"
            size="large"
            color="primary">
            {t("form.readyToSend")}
          </Button>
        </Grid>}
        {status && (status === DRAFT || status === READYTOSEND) && <Grid item>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            {...getActionProps(DRAFT, true)}>
            {t("form.save")}
          </Button>
        </Grid>}
        {status && (status === INVOICESENT || status === PAID) &&
        <Grid item>
          <Button
            color="primary"
            size="large"
            variant="outlined"
            {...getActionProps(DONE)}
          >
            {t('form.done')}
          </Button>
        </Grid>}
        {invoiceExist && status && (status === DRAFT || status === READYTOSEND) &&
        <Grid item>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            {...getActionProps(SENDINGREQUESTED)}>
            {t("form.sendInvoice")}
          </Button>
        </Grid>}
        {status && (status === INVOICESENT || status === DONE) &&
        <Grid item>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            {...getActionProps(PAID)}>
            {t("form.paid")}
          </Button>
        </Grid>}
      </>}
    </>
  );
}
