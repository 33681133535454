import {useTranslation} from 'react-i18next';
import {Authorize} from '@postinumero/authorization';
import Page from '../../Page';
import HasInvoices from './HasInvoices';

export default function Invoice() {
  const [t] = useTranslation();
  return (
    <Page title={t("invoices.pageTitle")}>
      <Authorize allow={['Issuer agent', 'Account operator', 'Inspector']}>
        <HasInvoices/>
      </Authorize>
    </Page>
  );
}
