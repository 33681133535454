import {useTranslation} from 'react-i18next';

export default function ({type, selectedPayer, fields, payers}) {
  const [t] = useTranslation();
  const edit = type === 'edit'
  const existing = edit ? payers.filter(payer => payer.id !== selectedPayer.id) : payers
  const inputs = {
    dataName: {
      isRequired: {message: t("validation.isRequired", {name: t("payer.payerName")})},
      initialValue: edit ? selectedPayer?.dataName : '',
      isNotDuplicate: {
        message: t('validation.isNotDuplicate', {
          name: t('paymentDetails.dataName')
        }),
        existingValues: existing.map(detail => detail.dataName)
      },
    },
    payerName: {
      isRequired: {message: t("validation.isRequired", {name: t("payer.payerName")})},
      initialValue: edit ? selectedPayer?.payerDetails.payerName : ''
    },
    payerNameSupplement: {
      initialValue: edit ? selectedPayer?.payerDetails.payerNameSupplement : ''
    },
    spareLine: {
      initialValue: edit ? selectedPayer?.payerDetails.spareLine : ''
    },
    deliveryAddress: {
      isRequired: {message: t("validation.isRequired", {name: t("deliveryDetails.deliveryAddress")})},
      initialValue: edit ? selectedPayer?.payerDetails.deliveryAddress : ''
    },
    deliveryAddressSecondLine: {
      initialValue: edit ? selectedPayer?.payerDetails.deliveryAddressSecondLine : ''
    },
    postalCode: {
      isRequired: {message: t("validation.isRequired", {name: t("deliveryDetails.postalCode")})},
      isValidFinnishPostalCode: {
        value: fields.country,
        message: t("validation.isValidFinnishPostalCode", {name: t("deliveryDetails.postalCode")})
      },
      initialValue: edit ? selectedPayer?.payerDetails.postalCode : ''
    },
    city: {
      isRequired: {message: t("validation.isRequired", {name: t("deliveryDetails.city")})},
      initialValue: edit ? selectedPayer?.payerDetails.city : ''
    },
    country: {
      initialValue: edit ? selectedPayer?.payerDetails.country : ''
    },
    email: {
      isRequired: {message: t("validation.isRequired", {name: t("deliveryDetails.email")})},
      isEmail: {message: t("validation.isEmail", {name: t("deliveryDetails.email")})},
      initialValue: edit ? selectedPayer?.payerDetails.email : ''
    },
    electronicInvoiceAddress: {
      initialValue: edit ? selectedPayer?.payerDetails.electronicInvoiceAddress : ''
    },
    electronicInvoiceIntermediator: {
      initialValue: edit ? selectedPayer?.payerDetails.electronicInvoiceIntermediator : ''
    }
  }
  return inputs
}
