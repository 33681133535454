import HasRelatedData from './HasRelatedData';
import BackButton from 'App/BackButton';

export default function HasItemId({invoice}) {
  return (
    <>
      {invoice ?
        <HasRelatedData invoicingPeriodId={invoice?.invoicingPeriodId} invoice={invoice}/> :
        <BackButton/>
      }
    </>
  );
}
