import {generatePath, Link as RouterLink} from 'react-router-dom';
import {Accordion, AccordionSummary, AccordionDetails, Typography, Link, Grid, Box} from "@material-ui/core";
import {Alert} from '@material-ui/lab';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import {Authorize, useUser} from '@postinumero/authorization';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useTranslation} from 'react-i18next';
import InvoicingPeriodsList from './InvoicingPeriodsList';
import {makeStyles} from '@material-ui/core/styles';
import {editCA, newInvoicingPeriod} from 'App/Pages';
import {PUBLISHED} from 'shared/Statuses';
import History from 'App/Pages/CorporateAction/Status/History';
import useModal from 'shared/useModal';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    marginTop: 0,
    width: 'fit-content',
    background: 'transparent',
    paddingLeft: '15px',
    '&:before': {
      height: 0
    },
    '&:first-of-type': {
      marginTop: theme.spacing(3)
    },
    '&:last-child': {
      marginBottom: '50px'
    },
    '&.Mui-expanded': {
      borderLeft: 'solid 3px ' + theme.palette.primary.light
    }
  },
  childRoot: {
    boxShadow: 'none',
    marginTop: 0,
    width: 'fit-content',
    background: 'transparent'
  },
  actions: {
    marginBottom: '10px',
    '& >:not(:last-child):after': {
      content: '"|"',
      display: 'inline-block',
      margin: '0 10px',
      textDecoration: '0',
      color: theme.palette.primary.main
    },
    '& a': {
      cursor: 'pointer'
    }
  },
  summary: {
    justifyContent: 'flex-start',
    minHeight: '0',
    padding: 0,
    '&.Mui-expanded': {
      minHeight: '0'
    },
    '& .MuiAccordionSummary-content': {
      flexGrow: 0,
      margin: '0',
      alignItems: 'center'
    }
  },
  content: {
    padding: '0px 20px 20px 45px',
    flexWrap: 'wrap'
  },
  innerContent: {
    padding: '0px 20px 20px 33px',
    flexWrap: 'wrap'
  },
  overflowContainer: {
    maxHeight: '60vh',
    overflowY: 'scroll',
    margin: '15px 20px 15px 0px'
  }
}));

export default function List({corporateActions, handleChange, expanded}) {
  const [t] = useTranslation();
  const classes = useStyles();
  const user = useUser();
  const organisation = user?.groups;
  const {open, handleOpen, handleClose, data} = useModal();
  return (
    <>
      <History onClose={handleClose} open={open} data={data}/>
      <Typography variant="h3">{t('events.title')}</Typography>
      <Box className={classes.overflowContainer}>
        {corporateActions.length > 0 ? corporateActions.map((item, index) => {
            const id = item.id;
            const isExpanded = expanded === id;
            const invoicingPeriods = item?.invoicingPeriods?.sort((a, b) => (new Date(b.startDate) - (new Date(a.startDate))));
            return (
              <Accordion
                className={classes.root}
                key={id}
                expanded={isExpanded}
                onChange={handleChange(id)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon color="primary"/>}
                  aria-controls={'panel_' + id}
                  className={classes.summary}
                  id={id}
                >
                  <WorkOutlineIcon
                    fontSize="large"
                    style={{marginRight: '10px', fill: isExpanded ? '#0062A3' : '#646464'}}
                  />
                  <Typography variant={isExpanded ? "h3" : "body1"}>
                    {/*{t('events.panelSummary',{actionEventType:item.actionEventType,issuer:item.issuer,eventId:item.eventId,accountOperator:item.organisation})}*/}
                    {t('events.panelSummary', {
                      actionEventType: item.actionEventType.type + " (" + item.actionEventType.description + ")",
                      issuer: item.issuer,
                      eventId: item.eventId,
                      accountOperator: item.organisation
                    })}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.content}>
                  {organisation === item.organisation ?
                    <Grid item sm={12} className={classes.actions}>
                      <Authorize allow="Issuer agent">
                        <Typography variant="overline">
                          <Link component={RouterLink} to={generatePath(editCA, {caId: id})}>{t("events.edit")}</Link>
                        </Typography>
                        {item.currentStatus === PUBLISHED &&
                        <Typography variant="overline">
                          <Link
                            to={{pathname: newInvoicingPeriod, search: '?ca=' + id}}
                            component={RouterLink}>
                            {t("invoicingPeriod.addTitle")}
                          </Link>
                        </Typography>
                        }
                      </Authorize>
                      <Authorize allow="Inspector">
                        <Typography variant="overline">
                          <Link type="button" cursor="pointer" onClick={() => handleOpen(item?.statuses)}>
                            {t("events.viewHistory")}
                          </Link>
                        </Typography>
                      </Authorize>
                    </Grid> : null}
                  {(invoicingPeriods && invoicingPeriods.length !== 0) ?
                    invoicingPeriods.map(period => {
                      return (
                        <Grid item sm={12} key={period.id}>
                          <InvoicingPeriodsList
                            key={period.id}
                            invoicingPeriod={period}
                            openHistory={handleOpen}
                            organisation={item.organisation}
                            classes={classes}/>
                        </Grid>);
                    })
                    : <Typography variant="body1">No invoicing periods.</Typography>}

                </AccordionDetails>
              </Accordion>
            );
          })
          : <Alert severity="warning">{t("events.noCAsFound")}</Alert>}
      </Box>
    </>
  );
}
