import {useState, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {ValidationContext} from 'shared/useValidation';

const useStyles = makeStyles((theme) => ({
  noTopSpace: {
    marginTop: theme.spacing(-2)
  }
}));

export default function DeliveryDetails({hideEInvoice, disableInputs = false}) {
  const [t] = useTranslation();
  const classes = useStyles();
  const {values, getInputProps, setValue} = useContext(ValidationContext);
  const [toggles, setToggles] = useState({
    spareLine: values.spareLine !== '',
    deliveryAddressSecondLine: values.deliveryAddressSecondLine !== '',
    country: values.country !== ''
  });
  const onToggle = (input) => {
    setToggles({...toggles, [input]: !toggles[input]});
    setValue(input, '');
  };
  return (
    <>
      <Grid className={classes.noTopSpace} item sm={12}>
        {!disableInputs &&
        <Button
          onClick={() => onToggle('spareLine')}
        >
          <Typography variant="overline" color="primary">
            {t("deliveryDetails.sparelineToggle", {action: toggles.spareLine ? 'Remove' : 'Add'})}
          </Typography>
        </Button>}
        {toggles.spareLine ?
          <TextField
            variant="outlined"
            fullWidth
            label={t("deliveryDetails.spareline")}
            {...getInputProps('spareLine')}
            helperText={t("deliveryDetails.spareLineHelper")}
            disabled={disableInputs}
          />
          : null}
      </Grid>
      <Grid item sm={12}>
        <TextField
          variant="outlined"
          fullWidth
          required
          label={t("deliveryDetails.deliveryAddress")}
          helperText={t("deliveryDetails.deliveryAddressHelper")}
          {...getInputProps('deliveryAddress')}
          disabled={disableInputs}
        />

      </Grid>
      <Grid className={classes.noTopSpace} item sm={12}>
        {!disableInputs &&
        <Button
          onClick={() => onToggle('deliveryAddressSecondLine')}
        >
          <Typography variant="overline" color="primary">
            {t("deliveryDetails.deliveryAddressSecondLineToggle", {action: toggles.deliveryAddressSecondLine ? 'Remove' : 'Add'})}
          </Typography>
        </Button>}
        {toggles.deliveryAddressSecondLine ?
          <TextField
            variant="outlined"
            fullWidth
            label={t("deliveryDetails.deliveryAddressSecondLine")}
            {...getInputProps('deliveryAddressSecondLine')}
            disabled={disableInputs}
          /> : null}
      </Grid>
      <Grid item xs={12} sm={6} md={5}>
        <TextField
          variant="outlined"
          fullWidth
          required
          label={t("deliveryDetails.postalCode")}
          {...getInputProps('postalCode')}
          disabled={disableInputs}
        />

      </Grid>
      <Grid item xs={12} sm={6} md={7}>

        <TextField
          variant="outlined"
          fullWidth
          required
          label={t("deliveryDetails.city")}
          {...getInputProps('city')}
          disabled={disableInputs}
        />
      </Grid>

      <Grid className={classes.noTopSpace} item sm={12}>
        {!disableInputs &&
        <Button
          onClick={() => onToggle('country')}
        >
          <Typography variant="overline" color="primary">
            {t("deliveryDetails.countryToggle", {action: toggles.country ? 'Remove' : 'Add'})}
          </Typography>
        </Button>}
        {toggles.country ?
          <TextField
            variant="outlined"
            fullWidth
            label={t("deliveryDetails.country")}
            {...getInputProps('country')}
            disabled={disableInputs}
          />
          : null}
      </Grid>
      <Grid item sm={12}>
        <TextField
          variant="outlined"
          fullWidth
          required
          label={t("deliveryDetails.email")}
          {...getInputProps('email')}
          disabled={disableInputs}
        />
      </Grid>
      {!hideEInvoice && <>
        <Grid item sm={12}>
          <TextField
            variant="outlined"
            fullWidth
            label={t("deliveryDetails.electronicInvoiceIntermediator")}
            {...getInputProps('electronicInvoiceIntermediator')}
            disabled={disableInputs}
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            variant="outlined"
            fullWidth
            label={t("deliveryDetails.electronicInvoiceAddress")}
            {...getInputProps('electronicInvoiceAddress')}
            disabled={disableInputs}
          />
        </Grid>
      </>}
    </>
  );
}
