import {useState} from 'react';
import {Typography, Box} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import Actions from './Actions';
import List from  './List';
import Selected from './Selected';
import {useReusablePayerDetails} from 'api';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CallMergeIcon from '@material-ui/icons/CallMerge';

export default function Payer({data, type, savedId}) {
  const [t] = useTranslation();

  const payersData = useReusablePayerDetails(type)

  const saved = savedId ? payersData.find(obj => obj.id === parseInt(savedId)) : null
  const active = saved ? saved.id : payersData.length ? payersData[0].id : ''

  const [selected, setSelected] = useState(active)
  const selectedPayer = payersData.find(payer => payer.id === selected)


  return (
    <div>
      <Typography variant={'h2'}>
        {type === 'Commission' ?
          <CallMadeIcon color="primary" style={{marginRight: '10px'}}/> :
          <CallMergeIcon color="primary" style={{marginRight: '10px'}}/>
        }
        {t(`reusables.${type}PayerTitle`)}
      </Typography>

      <List payersData={payersData} selected={selected} setSelected={setSelected} type={type} />

      <Box my={2}>
        <Actions type={type} payersData={payersData} selected={selectedPayer} />
      </Box>

      <Selected payer={selectedPayer} />
    </div>
  );
}
