import {useTranslation} from 'react-i18next';
import {ValidationProvider} from 'shared/useValidation';
import Layouts from './Layouts';
import {postConsolidatedInvoice, putConsolidatedInvoice, removeConsolidatedInvoice, exportConsolidatedInvoice} from 'api';
import MomentAdapter from '@date-io/moment';
import {useHistory} from 'react-router-dom';
import {corporateActions, consolidatedInvoice as consolidatedInvoicePath} from '..';
import useModal from 'shared/useModal';
import DeletionConfirmationDialog from 'shared/FormComponents/DeletionConfirmationDialog';
import useSnackBar from 'shared/SnackBar/useSnackBar';
import {INVALID} from 'shared/Statuses';

export default function Form({invoicingPeriod, corporateAction, invoice}) {
  const [t] = useTranslation();
  const history = useHistory();
  const {open, handleOpen, handleClose} = useModal();
  const {showError} = useSnackBar();

  const saveInvoice = invoice ? putConsolidatedInvoice : postConsolidatedInvoice

  const moment = new MomentAdapter();
  const invoiceDate = moment.format(moment.date(), 'YYYY-MM-DD');

  const config = {
    inputs: {
      payerData: {
        isRequiredObject: {message: t('validation.isRequiredObject', {name: t('consolidatedInvoice.payerData')})},
      },
      beneficiaryData: {
        isRequiredObject: {message: t('validation.isRequiredObject', {name: t('consolidatedInvoice.beneficiaryData')})},
      },
      paymentDetails: {
        isRequiredObject: {message: t('validation.isRequiredObject', {name: t('consolidatedInvoice.paymentDetails')})}
      },
      dueDate: {
        isRequired: {message: t('validation.isRequired', {name: t('invoice.dueDate')})},
        isValidDate: {message: t('validation.isValidDate', {name: t('invoice.dueDate')})},
        initialValue: invoice?.dueDate ?? null
      },
      invoiceNumber: {
        initialValue: invoice?.invoiceNumber ?? '',
        isMaxLength: {
          maxLength: 19,
          message: t('validation.isMaxLength', {name: t('invoice.invoiceNumber'), max: 20})
        },
        isOnlyDigits: {
          message: t('validation.isOnlyDigits', {name: t('invoice.invoiceNumber')})
        }
      },
      referenceNumber: {
        initialValue: invoice?.referenceNumber ?? '',
        isMaxLength: {
          maxLength: 19,
          message: t('validation.isMaxLength', {name: t('invoice.referenceNumber'), max: 19})
        },
        isNotValidReferenceNumber: {message: t('validation.isNotValidReferenceNumber', {name: t('invoice.referenceNumber')})}
      },
      vatExclusionReason: {
        initialValue: invoice?.vatExclusionReason ?? '',
        isMaxLength: {
          maxLength: 60,
          message: t('validation.isMaxLength', {name: t('invoice.vatExclusionReason'), max: 60})
        }
      },
      additionalInformation: {
        initialValue: invoice?.additionalInformation ?? '',
        isMaxLength: {
          maxLength: 200,
          message: t('validation.isMaxLength', {name: t('invoice.additionalInformation'), max: 200})
        }
      },
      supplyOfService: {
        initialValue: invoice?.supplyOfService ?? corporateAction?.recordDate
      },
      invoices: {
        initialValue: invoice?.invoices ? invoice.invoices : invoice?.currentStatus === INVALID ? [] : invoicingPeriod.invoices.filter(inv => inv.currentStatus !== INVALID)
      }
    },
    onClick: async (state, status, stay) => {
      if (!state.errors || Object.keys(state.errors).length === 0) {
        const payload = {
          ...state.values,
          payerDetails: state.values.payerData,
          currentStatus: status,
          invoicingPeriodId: invoicingPeriod.id,
          invoiceDate: invoiceDate,
          id: invoice?.id
        };

        // Remove white spaces from IBAN
        payload.paymentDetails.bankAccount = payload.paymentDetails.bankAccount.replaceAll(/\s/g,'');

        saveInvoice(payload).then(response => {
          if (stay) {
            history.push({pathname: consolidatedInvoicePath.replace(":id?", response.data.id)});
          } else {
            history.push({pathname: corporateActions, search: '?ca=' + corporateAction?.id});
          }
        }).catch(err => {
          showError(err.response)
        })
      }
    },
    deletionConfirmationFunction: handleOpen,
    deleteFunction: () => {
      removeConsolidatedInvoice(invoice.id).then(() => {
        history.push({pathname: corporateActions});
      }).catch((err) => {
        showError(err.response)
      });
    },
    exportXml: () => {
      exportConsolidatedInvoice(invoice.id).then((res) => {
        const xmlString = res.data
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:attachment/text,' + encodeURI(xmlString);
        hiddenElement.target = '_blank';
        hiddenElement.download = `invoice${invoice.id}.xml`;
        hiddenElement.click();
      }).catch((err) => {
        showError(err.response)
      })
    }
  };
  return (
    <ValidationProvider config={config}>
      <DeletionConfirmationDialog onClose={handleClose} open={open} itemName={t('consolidatedInvoice.name')}/>
      <Layouts invoicingPeriod={invoicingPeriod} corporateAction={corporateAction} invoice={invoice}/>
    </ValidationProvider>
  );
}
