import {Select, MenuItem, FormControl, InputLabel, Box} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

export default function ReusableSelect({details, selected, setSelected, disableInputs}) {
  const [t] = useTranslation();

  return (
    <div>
      <Box my={2}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>
            {t('invoicingPeriod.payerOption')}
          </InputLabel>
          <Select
            labelId="label-selected"
            onChange={(event) => setSelected(event.target.value)}
            value={selected}
            label={t('invoicingPeriod.payerOption')}
            fullWidth
            disabled={disableInputs}
          >
            {details.map((detail =>
              <MenuItem key={detail.id} value={detail.id}>
                {detail.dataName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
}
