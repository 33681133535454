import {useTranslation} from 'react-i18next';
import {Authorize} from '@postinumero/authorization';
import Page from '../../Page';
import Forbidden from '../Forbidden';
import Layout from './Layout';

export default function CorporateActions() {
  const [t] = useTranslation();

  return (
    <Page title={t("events.title")}>
      <Authorize allow={['Issuer agent', 'Account operator', 'Inspector']} fallback={<Forbidden/>}>
        <Layout/>
      </Authorize>
    </Page>
  );
}
