import {createMuiTheme} from '@material-ui/core';

export const theme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      root: {
        backgroundColor: '#ffffff',
        fontFamily: ['Open Sans', 'Arial', 'sans-serif'].join(','),
        height: 25
      }
    },
    MuiTableRow: {
      root: {
        backgroundColor: '#F2F2F2',
        height: 30,
        overflow: 'hidden'
      },
      footer: {
        backgroundColor: 'transparent'
      }
    },
    MuiTableCell: {
      root: {
        padding: '5px 5px 5px 5px',
        borderBottom: 'none',
        fontFamily: 'Open Sans,Arial,sans-serif',
        fontSize: '1rem',
        height: 30,
        overflow: "hidden",
        textAlign: 'right',
        '&:nth-child(1)': {
            textAlign: 'left'
        }
      },
      head: {
        padding: '0 10px 3px 5px',
        color: '#0062A3',
        fontSize: '0.9rem',
        fontWeight: '600',
        lineHeight: '1.3em',
        letterSpacing: '0.2px'
      },
      footer: {
        fontWeight: '700',
        fontSize: '1rem',
        color: '#282828',
        padding: '5px'
      }
    },
    MuiTableFooter: {
      root: {
        '& .MuiToolbar-root': {
          backgroundColor: 'white',
        },
      },
    },
    MuiTypography: {
      body1: {
        letterSpacing: '0.02em',
        fontFamily: ['Open Sans', 'Arial', 'sans-serif'].join(','),
      }
    },
    MuiSelect: {
      root: {
        fontFamily: ['Open Sans', 'Arial', 'sans-serif'].join(','),
        height: 25,
        padding: 0,
        paddingLeft: 4
      }
    },
  },
});
