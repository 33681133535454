import useModal from 'shared/useModal';
import {makeStyles} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import RemoveRowConfirmationDialog from './RemoveRowConfirmationDialog';

const useStyles = makeStyles(theme => ({
  root: {
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.7'
    }
  },

}));

export default function RemoveRowIcon ({ onRemove, description }) {
  const classes = useStyles();
  const {open, handleOpen, handleClose} = useModal();
  return (
    <>
      <RemoveRowConfirmationDialog open={open} onClose={handleClose} onConfirm={onRemove} description={description}/>
      <HighlightOffIcon className={classes.root} onClick={handleOpen} style={{verticalAlign: 'middle', color: '#EB5757'}}/>
    </>
  )
}
