import {useEffect} from 'react'
import Page from 'App/Page';
import {useTranslation} from 'react-i18next';
import {Authorize, useUser} from '@postinumero/authorization';
import Layout from './Layout'
import {useHistory} from 'react-router-dom';

export default function Home() {
  const [t] = useTranslation();
  const history = useHistory();
  const roles = useUser()?.roles
  useEffect(() => {
    if (roles?.includes('Inspector')) {
      history.push('/corporate-actions')
    } else if (roles?.includes('Administrator')) {
      history.push('/tariffs')
    }
  }, [roles, history])

  return (
    <Page title={t('notifications.title')}>
      <Authorize allow={['Issuer agent', 'Account operator']}>
        <Layout/>
      </Authorize>
    </Page>
  );
}
