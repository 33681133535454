import {ListItem, ListItemText, ListItemIcon, makeStyles, Typography} from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import {useUser} from '@postinumero/authorization';
import {removeUnderscores, formatDate} from 'shared/utility';

const useStyles = makeStyles((theme) => ({
  reversed: {
    display: 'flex',
    flexDirection: 'column-reverse'
  },
  iconRoot: {
    minWidth: '35px'
  }
}));

export default function Element({status}) {
  const classes = useStyles();
  const user = useUser();
  const date = status ? status.timestamp : new Date();
  return (
    <ListItem disableGutters dense>
      <ListItemIcon className={classes.iconRoot}>
        <HistoryIcon color="primary"/>
      </ListItemIcon>
      <ListItemText
        className={classes.reversed}
        primary={<Typography
          variant="body1">{status ? removeUnderscores(status.status) + " by " + status.author : "NOT SAVED by " + user?.name + ' (' + user?.groups + ')'}</Typography>}
        secondary={<Typography variant="caption" color="primary">{formatDate(date, "DD.MM.YYYY HH:mm")}</Typography>}/>
    </ListItem>
  );
}
