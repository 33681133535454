import {Box, Grid} from '@material-ui/core';
import Status from 'App/Pages/CorporateAction/Status';
import CorporateActionDetails from '../../Invoice/CorporateActionDetails';
import BeneficiaryInfo from 'App/Pages/Invoice/BeneficiaryInfo';
import PaymentInfo from 'App/Pages/Invoice/PaymentInfo';
import Fields from 'App/Pages/Invoice/Fields';
import AdditionalInformation from 'App/Pages/Invoice/AdditionalInformation';
import DueDate from 'App/Pages/Invoice/DueDate';
import VatExclusion from 'App/Pages/Invoice/VatExclusion';
import ReferenceNumber from 'App/Pages/Invoice/ReferenceNumber';
import Actions from './Actions';
import PayerData from '../PayerData';
import CommissionFees from './CommissionFees';
import {DRAFT, READYTOSEND} from 'shared/Statuses';
import {useUser} from '@postinumero/authorization';
import PrintLayout from './Print';

export default function Edit({corporateAction, invoicingPeriod, invoiceState, updateState, classes, status, id}) {
  const user = useUser();
  const disableActions = user.roles.includes('Inspector')
  const disableInputs = ![DRAFT, READYTOSEND].includes(status) || disableActions

  return (
    <>
      <Box display="block" displayPrint="none">
        <Status statuses={invoiceState?.statuses}/>
        <Grid container item>
          <Grid container item sm={12} md={5}>
              <Grid container item sm={12}>
                <Grid item sm={12} md={6} className={classes.row} style={{width: '100%'}}>
                  <PayerData payer={invoiceState?.payerData} setInvoice={updateState} disableInputs={disableInputs}/>
                </Grid>
                <Grid item sm={12} md={6} className={classes.row} style={{width: '100%'}}>
                  <Fields
                    invoiceDate={invoiceState?.invoiceDate}
                    disableInputs={disableInputs}
                  />
                </Grid>
              </Grid>
              <Grid item sm={12} className={classes.row}>
                <CorporateActionDetails corporateAction={corporateAction}/>
              </Grid>
              <Grid item sm={12} className={classes.row}>
                <Grid item sm={12} className={classes.row}>
                  <PaymentInfo
                    paymentDetails={invoiceState?.paymentDetails}
                    setInvoice={updateState}
                    disableInputs={disableInputs}
                  />
                </Grid>
                <Grid container item sm={12} className={classes.rowWithSpacing}>
                  <Grid item sm={12} md={6}>
                    <ReferenceNumber disableInputs={disableInputs}/>
                  </Grid>
                  <Grid item sm={12} md={6} pt={2}>
                    <DueDate disableInputs={disableInputs} noInput={false} />
                  </Grid>
                </Grid>
                <Grid item sm={12} className={classes.rowWithSpacing}>
                  <VatExclusion disableInputs={disableInputs}/>
                </Grid>
                <Grid item sm={12} className={classes.rowWithSpacing}>
                  <AdditionalInformation disableInputs={disableInputs}/>
                </Grid>
              </Grid>
              <Grid item sm={12}>
                <BeneficiaryInfo
                  beneficiary={invoiceState?.beneficiaryData}
                  setInvoice={updateState}
                  disableInputs={disableInputs}
                />
              </Grid>
          </Grid>
          <Grid container item sm={12} md={7}>
            <CommissionFees/>
          </Grid>
        </Grid>
        <Grid className={"no-print"} item xs={12} container justify="flex-end" spacing={3}>
            <Actions status={status} invoiceExist={!!invoiceState.id} disableActions={disableActions} id={id}/>
        </Grid>
      </Box>
      <PrintLayout
        corporateAction={corporateAction}
        invoicingPeriod={invoicingPeriod}
        invoiceState={invoiceState}
        updateState={updateState}
        disableInputs={disableInputs}
      />
    </>
  )
}
