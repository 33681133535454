import {useInvoicingPeriod, useCA} from 'api';
import Form from '../Form';

export default function HasItemId({id}) {
  const item = useInvoicingPeriod(id);
  const corporateAction = useCA(item?.corporateActionEventId)
  return (
    <Form invoicingPeriod={item} corporateAction={corporateAction}/>
  );
}
