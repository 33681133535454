import {useContext} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {makeStyles, Paper, Box} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import {ValidationContext} from 'shared/useValidation';
import {useTranslation} from 'react-i18next';
import Form from './Form';
import {ValidationErrors} from 'shared/FormComponents';
import Select from  './Select';
import PayerDetails from 'shared/Components/PayerDetails';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialogContent-root': {
      padding: '20px 24px',

    },
    '& .MuiDialogActions-root': {
      paddingLeft: '24px',
      paddingRight: '24px'
    }
  },

}));

export default function DetailsDialog({open, onClose, reusablePayerDetails, selected, setSelected}) {
  const [t] = useTranslation();
  const classes = useStyles();
  const {getActionProps} = useContext(ValidationContext);
  return (
    <Dialog className={classes.root} onClose={onClose} open={open}>
      <DialogTitle disableTypography>
        <Typography variant="h1">{t("consolidatedInvoice.addPayer")}</Typography>
      </DialogTitle>
      <DialogContent>
        <Select selected={selected} setSelected={setSelected} details={reusablePayerDetails} />
        {selected === -1 ? <>
          <ValidationErrors/>
          <Form/>
        </> : <Paper elevation={2}>
          <Box p={4}>
            <PayerDetails payerDetails={reusablePayerDetails.find(obj => obj.id === selected).payerDetails} />
          </Box>
          </Paper>
        }
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={() => {
          onClose();
        }}>
          {t('form.cancel')}
        </Button>
        <Button
          color="primary"
          variant="outlined"
          {...getActionProps()}
        >
          {t('paymentDetails.addToInvoice')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
