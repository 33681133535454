import {Typography, makeStyles, Box} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import {ValidationContext} from "shared/useValidation";
import MomentAdapter from '@date-io/moment';
import SmallTextField from "shared/FormComponents/SmallTextField";

const useStyles = makeStyles(theme => ({
  input: {
    marginTop: '10px'
  }
}));

export default function Fields({invoiceDate, supplyOfService, disableInputs}) {
  const [t] = useTranslation();
  const classes = useStyles();
  const moment = new MomentAdapter();
  const invoiceDateValue = invoiceDate ? moment.date(invoiceDate).format('DD.MM.YYYY') : moment.format(moment.date(), 'DD.MM.YYYY')
  const {getInputProps, values} = useContext(ValidationContext);
  const supplyOfServiceValue = values.supplyOfService ? moment.date(values.supplyOfService).format('DD.MM.YYYY') : ''
  return (
    <>
      <Typography variant="h3">{t("invoice.invoice")}</Typography>
      <Typography variant="subtitle1" color="primary">{t("invoice.invoiceDate")}</Typography>
      <Typography variant="body1">{invoiceDateValue}</Typography>
      <Box displayPrint="block" className={classes.input}>
        <Typography variant="subtitle1" color="primary">{t("invoice.supplyOfService")}</Typography>
        <Typography variant="body1" style={{ minHeight: '24px'}}>{supplyOfServiceValue}</Typography>
      </Box>
      {(values?.invoiceNumber && disableInputs) &&
      <Box className={classes.input}>
        <Typography variant="subtitle1" color="primary">{t("invoice.invoiceNumber")}</Typography>
        <Typography variant="body1">{values.invoiceNumber}</Typography>
      </Box>
      }
      {!disableInputs && <div>
        <SmallTextField
          className={[classes.input, 'no-print'].join(' ')}
          label={t("invoice.invoiceNumber")}
          inputProps={{maxLength: 19}}
          disabled={disableInputs}
          onInput={(e) => e.target.value = e.target.value.slice(0, 19)}
          {...getInputProps("invoiceNumber")}
        />
        <br />
        <Box display="block" displayPrint="none">
          <Typography variant="caption">{t("invoice.generated")}</Typography>
        </Box>
      </div>}
    </>
  );
}
