import {useEffect, useCallback} from 'react';
import {Route, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useKeycloak} from '@react-keycloak/web';
import {useSetUser, useUser} from '@postinumero/authorization';
import useSnackBar from 'shared/SnackBar/useSnackBar';

export default function RouteWrapper(props) {
  const [t] = useTranslation('error');
  const {keycloak} = useKeycloak();
  const setUser = useSetUser();
  const user = useUser();
  const history = useHistory();
  const {openSnackBar} = useSnackBar();
  const idle = new URLSearchParams(window.location.search).get('idle') === '1'
  const showInvalidSessionWarning = useCallback(
    () => openSnackBar({message: t("invalidSession"), severity: 'warning', time: null}),
    [openSnackBar, t]
  )

  useEffect(() => {
    if (user && !keycloak.authenticated) {
      localStorage.clear();
      setUser(null);
      showInvalidSessionWarning()
    }
  }, [keycloak.authenticated, user, setUser, showInvalidSessionWarning])

  useEffect(() => {
    if (idle) {
      showInvalidSessionWarning()
      setTimeout(() => {
        history.push('/')
      }, 2000)
    }
  }, [idle, showInvalidSessionWarning, history])

  return (<Route {...props}/>)
}
