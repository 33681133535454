import Alert from '@material-ui/lab/Alert';

export default function NoRoles() {

  return (
    <Alert severity="error">
      Administrator has not defined any user roles for you to fees. You can't use the system. Contact your fees
      Administrator to get help.
    </Alert>
  );
}
