import {TableRow, TableFooter, TableCell} from "@material-ui/core";
import {formatDecimal} from 'shared/utility';

export default function CustomFooter({invoices, vatRates}) {

  const getTotal = (field, field2) => {
    return Object.keys(invoices).reduce((a, b) => {
      let val = invoices[b][field]
      if (typeof val === 'object') {
        val = val[field2] || 0
      }
      return a + val
    }, 0)
  }

  return (
    <TableFooter>
      <TableRow>
        <TableCell align={"right"}/>
        {vatRates.map(rate => {
          return (
            <TableCell align={"right"} key={rate}>
              {invoices && formatDecimal(getTotal('vatExcludedTotals', rate))}
            </TableCell>
          )
        })}
        <TableCell align={"right"}>
          {invoices && formatDecimal(getTotal('totalVatAmount'))}
        </TableCell>
        <TableCell align="right">
          {invoices && formatDecimal(getTotal('totalAmount'))}
        </TableCell>
      </TableRow>
    </TableFooter>);
}
