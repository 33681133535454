import {TableCell, TableRow} from '@material-ui/core';
import {formatDecimal} from 'shared/utility';

export default function CustomTableRow({data, vatRates}) {
  const getActualValue = (value, index) => {
    if (typeof value === 'object') {
      const key = vatRates[index - 1]
      return value[key]
    }
    return value
  }

  return (
    <>
      <TableRow style={{borderBottom: 5}}>
        {data.map((value, index) => {
          const val = getActualValue(value, index)
          return <TableCell key={index}>{index === 0 ? value : typeof val === 'number' ? formatDecimal(val) : val }</TableCell>
      })}
      </TableRow>
      <TableRow style={{height: '2px', background: 'white'}}/>
    </>
  )
}
