import PayerData from '../PayerData';
import {Grid, Button} from '@material-ui/core';
import CorporateActionDetails from '../../Invoice/CorporateActionDetails';
import InvoicingPeriodDetails from '../../Invoice/InvoicingPeriodDetails';
import PaymentInfo from '../../Invoice/PaymentInfo';
import BeneficiaryInfo from '../../Invoice/BeneficiaryInfo';
import {useHistory} from 'react-router-dom';
import {Alert} from '@material-ui/lab';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';


export default function Add({setInitialLayout, corporateAction, invoicingPeriod, classes, invoiceState, updateState}) {
  const [error, setError] = useState(false);
  const [t] = useTranslation();
  const history = useHistory();
  return (
    <>
      {error &&
      <Grid item xs={12}>
        <Alert severity="error" style={{marginBottom: '15px'}}>
          {t("consolidatedInvoice.fillData")}
        </Alert>
      </Grid>
      }
      <Grid className={classes.row} container item xs={12} justify="space-between">
        <Grid item sm={12} md={6}>
          <PayerData payer={invoiceState?.payerData} setInvoice={updateState}/>
        </Grid>
        <Grid item lg={4} md={6} sm={12}>
          <InvoicingPeriodDetails invoicingPeriod={invoicingPeriod}/>
        </Grid>
      </Grid>
      <Grid className={classes.row} container item xs={12}>
        <Grid item sm={12} md={6}>
          <CorporateActionDetails corporateAction={corporateAction}/>
        </Grid>
      </Grid>
      <Grid className={classes.row} container item xs={12}>
        <Grid item lg={4} md={6} sm={12}>
          <PaymentInfo paymentDetails={invoiceState?.paymentDetails} setInvoice={updateState}/>
        </Grid>
      </Grid>
      <Grid className={classes.row} container item xs={12}>
        <Grid item md={6} xs={12}>
          <BeneficiaryInfo beneficiary={invoiceState?.beneficiaryData} setInvoice={updateState}/>
        </Grid>
      </Grid>
      <Grid item xs={12} container justify="flex-end" spacing={3}>
        <Grid item>
          <Button
            onClick={history.goBack}
            variant="outlined" size="large" color="primary">{t("form.cancel")}</Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            onClick={() => {
              (invoiceState?.paymentDetails && invoiceState?.beneficiaryData && invoiceState?.payerData) ? setInitialLayout(false) : setError(true)
            }}>
            {t("form.add")}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
