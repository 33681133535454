import {useContext} from 'react';
import {Alert, AlertTitle} from '@material-ui/lab';
import {ValidationContext} from 'shared/useValidation';

export default function ValidationErrors() {
  const {errors} = useContext(ValidationContext);
  return (
    <>
      {errors && Object.keys(errors).length > 0 ?
        <Alert severity="error" style={{marginBottom: '15px'}}>
          <AlertTitle>The form was filled incorrectly, please correct following errors:</AlertTitle>
          <ul>
            {Object.keys(errors).map((err, i) => {
              return (<li key={i}>{errors[err]}</li>)
            })}
          </ul>
        </Alert>
        : null}
    </>

  );
}
