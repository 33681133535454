import {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Link, useLocation, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Authorize} from '@postinumero/authorization';
import {useCorporateActions} from 'api';
import List from './List';
import {newCA} from 'App/Pages';
import Details from './Details';
import Filters from './Filters';
import {useDeepCompareEffect} from 'shared/useDeepCompareEffect';
import {useUser} from '@postinumero/authorization';
import {DRAFT, READYTOPUBLISH, PUBLISHED, INVALID} from 'shared/Statuses';

const defaultStatuses = {
  'IA': [DRAFT, READYTOPUBLISH, PUBLISHED, INVALID],
  'AO': [PUBLISHED, INVALID],
  'other': []
}

export default function Layout() {
  const [t] = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const user = useUser();
  const currentRole = user.roles.includes('Issuer agent') ? 'IA' : user.roles.includes('Account operator') ? 'AO' : 'other';
  const [corporateActions] = useState(useCorporateActions()?.sort((a, b) => {
    const distancea = Math.abs(new Date() - new Date(a.recordDate));
    const distanceb = Math.abs(new Date() - new Date(b.recordDate));
    return distancea - distanceb;
  }));

  const statuses = [...new Set(corporateActions.map(item => item.currentStatus))];
  const issuerAgents = [...new Set(corporateActions.map(item => item.organisation))];
  const params = new URLSearchParams(location.search);
  const openCA = parseInt(params.get('ca'));
  const initialFilter = {
    statuses: params.get('statuses')?.split(',') ?? defaultStatuses[currentRole].filter(status => statuses.includes(status)),
    issuerAgents: params.get('issuerAgents')?.split(',') ?? currentRole === 'IA' ? issuerAgents.filter(issuerAgent => issuerAgent === user.groups) : [],
    search: params.get('search') ?? '',
    startDate: params.get('startDate') ?? null,
    endDate: params.get('endDate') ?? null
  };
  const [activeFilters, setActiveFilters] = useState(initialFilter);
  const [visibleCorporatActions, setVisibleCorporateActions] = useState(corporateActions);
  const initialCA = openCA ? corporateActions.find(item => item.id === openCA) : corporateActions.find(ca => ca.organisation === user.groups);
  const [expanded, setExpanded] = useState(openCA ? openCA : initialCA?.id);
  const [expandedCA, setExpandedCA] = useState(initialCA);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setExpandedCA(corporateActions.find(item => item.id === panel));
  };
  const resetFilter = () => {
    history.push({pathname: '/corporate-actions'});
    setActiveFilters({statuses: [], issuerAgents: [], search: '', startDate: null, endDate: null});
    closeCA();
  };
  const closeCA = () => {
    setExpanded(false);
    setExpandedCA(null);
  };
  const updateParams = (open) => {
    const esc = encodeURIComponent;
    const query = Object.keys(activeFilters)
      .filter(key => (activeFilters[key] && (activeFilters[key]?.length > 0 || Object.keys(activeFilters[key]).length > 0)))
      .map(key => esc(key) + '=' + (Array.isArray(activeFilters[key]) ? esc(activeFilters[key]?.join(',')) : activeFilters[key] instanceof Object && activeFilters[key]?.isValid() ? activeFilters[key].format('YYYY-MM-DD') : esc(activeFilters[key])));
    if (open) {
      query.push('ca=' + open);
    }
    if (query) {
      history.push({pathname: '/corporate-actions', search: query.join('&')});
    }
  };

  useDeepCompareEffect(() => {
    updateParams(expanded);
    const filtered = corporateActions.filter(item => {
      const {id, invoicingPeriods, statuses, ...strippedItem} = item;
      const recordDate = new Date(item.recordDate).setHours(0, 0, 0, 0);
      const paymentDate = new Date(item.paymentDate).setHours(0, 0, 0, 0);
      const start = activeFilters.startDate ? new Date(activeFilters.startDate).setHours(0, 0, 0, 0) : null;
      const end = activeFilters.endDate ? new Date(activeFilters.endDate).setHours(0, 0, 0, 0) : null;
      return (
        (activeFilters.statuses.indexOf(item.currentStatus) > -1 || activeFilters.statuses.length === 0 || (openCA && item.id === openCA))
        && (activeFilters.issuerAgents.indexOf(item.organisation) > -1 || activeFilters.issuerAgents.length === 0)
        && (Object.values(strippedItem).find(val => val.toString().toLowerCase().includes(activeFilters.search.toLowerCase())) ||
          (strippedItem.actionEventType.type.toLowerCase().includes(activeFilters.search.toLowerCase())  ||
            strippedItem.actionEventType.description.toLowerCase().includes(activeFilters.search.toLowerCase()) ||
            strippedItem.actionEventType.eventClassification.toLowerCase().includes(activeFilters.search.toLowerCase())))
        && (((start <= recordDate || !start)
          && (end >= recordDate || !end))
          || ((start <= paymentDate || !start)
            && (end >= paymentDate || !end)))
      );
    });
    setVisibleCorporateActions(filtered);

  }, [activeFilters, corporateActions, expanded]);
  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Typography variant="h3" paragraph>{t("filter.title")}</Typography>
        <Filters
          activeFilters={activeFilters}
          setFilters={setActiveFilters}
          statuses={statuses}
          issuerAgents={issuerAgents}
          reset={resetFilter}
          close={closeCA}
        />
      </Grid>
      <Grid item xs={12} md={7} lg={8}>
        {visibleCorporatActions &&
        <List corporateActions={visibleCorporatActions} handleChange={handleChange} expanded={expanded}/>
        }
      </Grid>
      <Grid item xs={12} md={5} lg={4}>
        <Details corporateAction={expandedCA} show={expanded}/>
      </Grid>
      <Authorize allow="Issuer agent">
        <Grid item sm={12}>
          <Button variant="contained" color="secondary" size="large" component={Link}
                  to={newCA}>{t("events.addNew")}</Button>
        </Grid>
      </Authorize>
    </Grid>
  );
}
