import IBAN from 'iban';
import BIC from 'bic';
import {validate} from 'iso-11649';
import {isValidFinnishRefNumber} from 'finnish-bank-utils';

export const isLaterThanToday = (config) => (value) => {
  const varDate = new Date(value); //dd-mm-YYYY
  varDate.setHours(0, 0, 0, 0);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return varDate <= today ? config.message : null;
};
export const isTodayOrLater = (config) => (value) => {
  const varDate = new Date(value); //dd-mm-YYYY
  varDate.setHours(0, 0, 0, 0);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return varDate < today ? config.message : null;
};
export const isBefore = (config) => (value) => {
  const endDate = new Date(config.value); //dd-mm-YYYY
  endDate.setHours(0, 0, 0, 0);
  const startDate = new Date(value);
  startDate.setHours(0, 0, 0, 0);
  return startDate > endDate ? config.message : null
}
export const isRequired = (config) => (value) => {
  return (!value || value === '') && value !== 0 ? config.message : null;
};
export const isRequiredConditionalForFee = (config) => (value) => {
  const containsValue = (value, message) => {
    return (!value || value === '') && value !== 0 ? message : null;
  };

  const verifyOptions = (options, message) => {
    options.forEach(option => {
      if (containsValue(option.description, message) === null ||
        containsValue(option.amount, message) === null ||
        containsValue(option.vat, message) === null) {
        return message;
      }
    })
  };

  switch (config.type) {
    case 'SELECTION_BASED':
      switch (config.field) {
        case 'description':
          return containsValue(value, config.message);
        case 'options':
          return verifyOptions(value, config.message);
        default:
          return null;
      }
    case 'QUANTITY_BASED':
      switch (config.field) {
        case 'description':
          return containsValue(value, config.message);
        case 'pricePerQuantity':
          return containsValue(value, config.message);
        case 'minimumPrice':
          return containsValue(value, config.message);
        case 'vat':
          return containsValue(value, config.message);
        default:
          return null;
      }
    case 'COMMISSION_BASED':
      switch (config.field) {
        case 'description':
          return containsValue(value, config.message);
        case 'amount':
          return containsValue(value, config.message);
        case 'vat':
          return containsValue(value, config.message);
        default:
          return null;
      }
    default:
      return null;
  }
};


export const isValidDate = (config) => (value) => {
  return ((new Date(value) === "Invalid Date") || isNaN(new Date(value))) && value !== '' ? config.message : null;
};
export const isEmail = (config) => (value) => {
  const regexp = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return !regexp.test(value) ? config.message : null;
};
export const isNotInPast = (config) => (value) => {
  return new Date(value) > new Date() ? config.message : null;
};
export const isRequiredIf = (config) => (value) => {
  if (config.isEqualTo === config.value) {
    return !value || value === '' ? config.message : null;
  }
  return null;
};
export const isRequiredObject = (config) => (obj) => {
  return !obj || typeof obj !== 'object' || Object.keys(obj).length === 0 ? config.message : null;
};
export const isValidIBAN = (config) => (value) => {
  const regexp = /^[0-9a-zA-Z]+$/;
  const val = value.replaceAll(' ', '')
  //TODO: remove specialcharacter check if library is updated
  return IBAN.isValid(val) && regexp.test(val) ? null : config.message;
};
export const isValidBIC = (config) => (value) => {
  return BIC.isValid(value) ? null : config.message;
};
export const isValidFinnishPostalCode = (config) => (value) => {
  const regexp = /^\d{5}$/;
  const country = ['finland', 'suomi'];
  if (country.includes(config.value.toLowerCase()) || !config.value) {
    return !regexp.test(value) ? config.message : null;
  } else return null;
};
export const isNotBeforeDate = (config) => (value) => {
  return (new Date(config.value) > new Date(value) && value) ? config.message : null;
};
export const isMaxLength = (config) => (value) => {
  return config.maxLength < value.length ? config.message : null;
};
export const isNotValidReferenceNumber = (config) => (value) => {
  try {
    if (!value) {
      return false;
    }

    if (value.startsWith('RF')) {
      return validate(value) ? false : config.message;
    } else {
      return isValidFinnishRefNumber(value) ? false : config.message;
    }
  } catch (error) {
    return config.message;
  }
};
export const isTwoDecimal = (config) => (value) => {
  if (config.checkIf) {
    const regexp = /^[0-9]+(\.[0-9][0-9]?)?$/;
    return regexp.test(value) ? null : config.message;
  } else return null;
};
export const isOnlyDigits = (config) => (value) => {
  if (!value) return null
  return !(/^\d+$/.test(value)) ? config.message : null;
}
export const isNotDuplicate = (config) => value => {
  if (!value) return null
  if (config.existingValues) {
    return config.existingValues.includes(value) ? config.message : null
  } else {
    return null
  }
}
