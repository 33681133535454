import {useState, useEffect} from 'react';
import {Typography, Button} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {postReusablePayerDetails, deleteReusablePayerDetail} from 'api';
import useModal from 'shared/useModal';
import PayerDialog from './PayerDialog';
import {ValidationProvider} from 'shared/useValidation';
import useSnackBar from 'shared/SnackBar/useSnackBar';
import getInputs from './getInputs';
import {DeletionConfirmationDialog} from 'shared/FormComponents';

export default function Add({type, payersData, selected}) {
  const [t] = useTranslation();

  const {open, handleOpen, handleClose} = useModal();
  const {open: show, handleOpen: handleShow, handleClose: handleHide} = useModal();
  const {showError} = useSnackBar();
  const [validationError, setValidationError] = useState(null);

  useEffect(() => {
    setValidationError(null)
  }, [validationError, setValidationError])

  const config = (fields) => ({
    extraErrors: validationError,
    inputs: getInputs({type: 'add', fields, payers: payersData}),
    onClick: (state) => {
      if (!state.errors || Object.keys(state.errors).length === 0) {

        const payload = {
            dataName: state.values.dataName,
            type: type,
            payerDetails: { ...state.values }
        }
        postReusablePayerDetails(payload).then(res => {
          window.location.search = `?id=${res.data.id}`
        }).catch(err => {
          const res = err.response
          if (res.status === 400) {
            setValidationError({'dataName': res.data.message})
          } else {
            showError(res)
          }
        })
      }
    },
    deleteFunction: () => {
      deleteReusablePayerDetail(selected.id).then(() => {
        window.location.reload()
      }).catch(err => {
        showError(err.response)
      })
      handleHide()
    }
  });

  return (
    <ValidationProvider config={config}>
      <Button component="div" onClick={handleOpen}>
        <Typography color="primary" variant="overline">
          {t("reusables.add")}
        </Typography>
      </Button>
      {selected && <>
        |
        <Button component="div" onClick={handleShow}>
            <Typography color="primary" variant="overline">
              {t("reusables.remove")}
            </Typography>
        </Button>
      </>}

      <PayerDialog onClose={handleClose} open={open} />

      <DeletionConfirmationDialog onClose={handleHide} open={show} text={t('reusables.deletionConfirmText', {name: selected?.dataName})} />
    </ValidationProvider>
  );
}
