import {useContext, useState, useEffect} from 'react';
import {Grid, makeStyles} from '@material-ui/core';
import {useDeepCompareEffect} from 'shared/useDeepCompareEffect';
import {ValidationContext} from 'shared/useValidation';
import {ValidationErrors} from 'shared/FormComponents';
import Add from './Add';
import Edit from './Edit';
import {useReusableBeneficiaries, useReusablePaymentDetails} from 'api';

const useStyles = makeStyles((theme) => ({
  row: {
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1)
    },
    '& h3': {
      marginBottom: theme.spacing(2)
    }
  },
  topPad: {
    paddingTop: '1.5em'
  },
  tableCell: {
    verticalAlign: 'top',
    padding: theme.spacing(1)
  }
}));

export default function Layouts({invoicingPeriod, corporateAction, invoice, validateLines, showLineErrors, setShowLineErrors, lineErrors}) {
  const classes = useStyles();
  const {setValue} = useContext(ValidationContext);
  const [invoiceState, setInvoiceState] = useState(invoice);
  const [useInitialLayout, setInitialLayout] = useState(!invoice);

  const updateState = (object, key) => {
    setInvoiceState({...invoiceState, [key]: object});
  };
  useDeepCompareEffect(() => {
    setValue('beneficiaryData', invoiceState?.beneficiaryData);
    setValue('paymentDetails', invoiceState?.paymentDetails);
    setValue('invoiceLines', invoiceState?.invoiceLines);
  }, [invoiceState, setValue]);

  const reusableBeneficiaries = useReusableBeneficiaries()
  const firstBeneficiary = reusableBeneficiaries.length ? reusableBeneficiaries[0] : null
  const reusablePaymentDetails = useReusablePaymentDetails()
  const firstPaymentDetail = reusablePaymentDetails.length ? reusablePaymentDetails[0] : null

  useEffect(() => {
    if (!invoice) {
      setInvoiceState({
        beneficiaryData: firstBeneficiary?.beneficiaryData,
        paymentDetails: firstPaymentDetail?.paymentDetails
      })
    }
  }, [invoice, firstBeneficiary, firstPaymentDetail, setInvoiceState])

  return (
    <Grid container>
      <Grid item xs={12}><ValidationErrors/></Grid>
      {useInitialLayout ?
        <Add
          setInitialLayout={setInitialLayout}
          classes={classes}
          invoicingPeriod={invoicingPeriod}
          corporateAction={corporateAction}
          invoiceState={invoiceState}
          updateState={updateState}
        />
        :
        <Edit
          classes={classes}
          invoicingPeriod={invoicingPeriod}
          corporateAction={corporateAction}
          invoiceState={invoiceState}
          updateState={updateState}
          status={invoice ? invoice.currentStatus : 'DRAFT'}
          validateLines={validateLines}
          showLineErrors={showLineErrors}
          setShowLineErrors={setShowLineErrors}
          lineErrors={lineErrors}
        />
      }
    </Grid>
  );
}
