import {useUser} from '@postinumero/authorization';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '& p': {
      margin: 0
    },
    '& span': {
      fontSize: '0.7rem',
      display: 'block'
    }
  }
}));
export default function UserInfo() {
  const classes = useStyles();
  const user = useUser();
  return (
    <div className={classes.root}>
      <p>
        {user?.name} ({user?.groups})
        <span>{user?.roles}</span>
      </p>

    </div>
  );
}
