import {Select, MenuItem, FormControl, InputLabel, Box} from '@material-ui/core';

export default function List({beneficiaries, selected, setSelected, label}) {

  return (
    <div>
      <Box my={2}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>
            {label}
          </InputLabel>
          <Select
            labelId="label-selected"
            onChange={(event) => setSelected(event.target.value)}
            value={selected}
            label={label}
            fullWidth
          >
            {beneficiaries.map(beneficiary =>
              <MenuItem key={beneficiary.id} value={beneficiary}>
                {beneficiary.dataName}
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
}
