import {Box, Typography, makeStyles} from '@material-ui/core';
import PaymentInfo from '../PaymentInfo';
import BeneficiaryInfo from '../BeneficiaryInfo';
import Fields from '../Fields';
import VatExclusion from '../VatExclusion';
import ReferenceNumber from '../ReferenceNumber';
import CommissionFees from '../CommissionFees';
import AdditionalInformation from '../AdditionalInformation';
import DueDate from '../DueDate';
import PayerDetails from 'shared/Components/PayerDetails';
import CorporateActionDetails from '../CorporateActionDetails';
import InvoicingPeriodDetails from '../InvoicingPeriodDetails';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  tableCell: {
    verticalAlign: 'top',
    padding: theme.spacing(1)
  }
}));


export default function Print({corporateAction, invoicingPeriod, invoiceState, updateState, validateLines, showLineErrors, setShowLineErrors, disableInputs}) {
  const [t] = useTranslation();
  const classes = useStyles()
  return (
    <Box display="none" displayPrint="inline">
      <table className={classes.printTable} style={{width: '100%'}}>
        <tbody>
          <tr>
            <td width="40%" className={classes.tableCell}>
              <CorporateActionDetails corporateAction={corporateAction}/>
            </td>
            <td width="25%" className={classes.tableCell}>
              <Fields
                invoiceDate={invoiceState?.invoiceDate}
                supplyOfService={corporateAction?.recordDate}
                disableInputs={disableInputs}/>
            </td>
            <td width="35%" className={classes.tableCell}>
              <PaymentInfo
                paymentDetails={invoiceState?.paymentDetails}
                setInvoice={updateState}
                disableInputs={disableInputs}
              />
              <ReferenceNumber disableInputs={disableInputs}/>
              <DueDate disableInputs={disableInputs}/>
              <VatExclusion disableInputs={disableInputs}/>
              <AdditionalInformation disableInputs={disableInputs}/>
            </td>
          </tr>
          <tr>
            <td className={classes.tableCell}>
              <Typography variant="h3">{t("invoice.payer")}</Typography>
              <PayerDetails payerDetails={invoicingPeriod}/>
            </td>
            <td className={classes.tableCell}>
              <BeneficiaryInfo
                beneficiary={invoiceState?.beneficiaryData}
                setInvoice={updateState}
                disableInputs={disableInputs}
              />
            </td>
            <td className={classes.tableCell}>
              <InvoicingPeriodDetails invoicingPeriod={invoicingPeriod}/>
            </td>
          </tr>
          <tr>
            <td colSpan="3" className={classes.tableCell}>
              <CommissionFees
                updateState={updateState}
                lines={invoiceState?.invoiceLines}
                caType={corporateAction ? corporateAction?.actionEventType.type : ''}
                disableInputs={disableInputs}
                invoiceId={invoiceState?.id}
                validateLines={validateLines}
                showLineErrors={showLineErrors}
                setShowLineErrors={setShowLineErrors}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </Box>
  )
}
