import {Select, InputLabel, MenuItem, FormControl} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {formatDate} from 'shared/utility'
import {DRAFT} from "shared/Statuses";

const findActiveStatus = (tariff) => {
  if (Array.isArray(tariff.statuses)) {
    if (tariff.statuses.length > 0) {
      return tariff.statuses[0].status;
    }
  }

  return "NOT SAVED";
};

const makeValidRange = (tariff) => {
  if ((findActiveStatus(tariff) === DRAFT && !tariff.validFrom) || findActiveStatus(tariff) === 'NOT SAVED') {
    return ''
  }

  if (tariff.validFrom) {
    if (tariff.validUntil) {
      return ' ' + formatDate(tariff.validFrom) + ' - ' + formatDate(tariff.validUntil) + ' ';
    } else {
      return ' ' + formatDate(tariff.validFrom) + ' onwards ';
    }
  }
};

export default function Selector({tariffs, setSelected, selected}) {
  const [t] = useTranslation();
  const sortedTariffs = tariffs.sort((a, b) => (a.validFrom < b.validFrom));
  return (
    <div>
      <FormControl fullWidth variant="outlined">
        <InputLabel id="label-selected">{t('tariffs.item')}</InputLabel>
        <Select
          labelId="label-selected"
          onChange={(event) => setSelected(event.target.value)}
          label={t('tariffs.item')}
          fullWidth={true}
          value={selected}
        >
          {sortedTariffs.map(((tariff, index) =>
            <MenuItem key={tariff.id}
                      value={tariff.id}>{tariff.organisation + makeValidRange(tariff)} ({findActiveStatus(tariff)})</MenuItem>))}
        </Select>
      </FormControl>
    </div>
  );
}
