import {Grid, Button, Typography} from '@material-ui/core';
import CorporateActionDetails from '../CorporateActionDetails';
import InvoicingPeriodDetails from '../InvoicingPeriodDetails';
import PaymentInfo from '../PaymentInfo';
import BeneficiaryInfo from '../BeneficiaryInfo';
import {Link, useHistory} from 'react-router-dom';
import {Alert} from '@material-ui/lab';
import {useState} from 'react';
import PayerDetails from 'shared/Components/PayerDetails';
import {useTranslation} from 'react-i18next';


export default function Add({setInitialLayout, corporateAction, invoicingPeriod, classes, invoiceState, updateState}) {
  const [error, setError] = useState(false);
  const history = useHistory();
  const [t] = useTranslation();
  return (
    <>
      {error &&
      <Grid item xs={12}><Alert severity="Error" style={{marginBottom: '15px'}}>Please make sure you have set payment
        details and beneficiary information.</Alert></Grid>}
      <Grid className={classes.row} container item xs={12}>
        <Grid item sm={12} md={6}>
          <Typography variant="h3">{t("invoice.payer")}</Typography>
          <PayerDetails payerDetails={invoicingPeriod} />
        </Grid>
      </Grid>
      <Grid className={classes.row} container item xs={12} justify="space-between">
        <Grid item sm={12} md={6}>
          <CorporateActionDetails corporateAction={corporateAction}/>
        </Grid>
        <Grid item lg={4} md={6} sm={12}>
          <InvoicingPeriodDetails invoicingPeriod={invoicingPeriod}/>
        </Grid>
      </Grid>
      <Grid className={classes.row} container item xs={12}>
        <Grid item lg={4} md={6} sm={12}>
          <PaymentInfo paymentDetails={invoiceState?.paymentDetails} setInvoice={updateState}/>
        </Grid>
      </Grid>
      <Grid className={classes.row} container item xs={12}>
        <Grid item md={6} xs={12}>
          <BeneficiaryInfo beneficiary={invoiceState?.beneficiaryData} setInvoice={updateState}/>
        </Grid>
      </Grid>
      <Grid item xs={12} container justify="flex-end" spacing={3}>
        <Grid item>
          <Button
            component={Link}
            onClick={history.goBack}
            variant="outlined" size="large" color="primary">Cancel</Button>
        </Grid>
        <Grid item>
          <Button variant="contained" size="large" color="secondary" onClick={() => {
            (invoiceState?.paymentDetails && invoiceState?.beneficiaryData) ? setInitialLayout(false) : setError(true)
          }}>Add to invoice</Button>

        </Grid>
      </Grid>
    </>
  )
}
