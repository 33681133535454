import {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Typography} from '@material-ui/core';
import {DateInput} from "shared/FormComponents";
import {formatDate} from 'shared/utility';
import {ValidationContext} from 'shared/useValidation';
import {useUser} from '@postinumero/authorization';
import {DRAFT, PUBLISHED} from "shared/Statuses";
import useModal from 'shared/useModal';
import {InfoDialog} from 'shared/FormComponents';

export default function ValidFrom({externalValue, status, organisationValidFroms}) {
  const [t] = useTranslation();
  const {getInputProps, setValue, values} = useContext(ValidationContext);
  const {open, handleOpen, handleClose} = useModal();
  const [lastExternalValue, setLastExternalValue] = useState(externalValue);
  useEffect(() => {
    if (externalValue !== lastExternalValue) {
      setValue('validFrom', formatDate(externalValue, 'YYYY-MM-DD'));
      setLastExternalValue(externalValue)
    }
  }, [externalValue, lastExternalValue, setValue]);

  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 1);

  const close = () => {
    setValue('validFrom', null);
    handleClose();
  };

  const user = useUser();

  const resolveView = () => {
    if (user.roles.includes('Administrator') && (status === DRAFT || status === 'NOT_SAVED' || status === PUBLISHED)) {
      return <>
        <InfoDialog onClose={close}
                    open={open}
                    title={t("tariffs.modalTitle")}
                    body={t("tariffs.noDuplicateValidFroms")}
                    btnLabel={t("tariffs.modalOk")}/>
        <DateInput
          label={t('tariffs.validFrom')}
          required
          error={false}
          helperText={null}
          minDate={minDate}
          {...getInputProps('validFrom', {
            onChange: (event) => {
              if (event && organisationValidFroms.includes(formatDate(event, 'YYYY-MM-DD'))) {
                if (values.validFrom) {
                  handleOpen();
                }
              } else {
                setValue('validFrom', formatDate(event, 'YYYY-MM-DD'));
              }
            }
          })}
        />
      </>
    } else {
      return <div>
        <Typography variant="h3">{t("tariffs.validFrom")}</Typography>
        <Typography>{formatDate(externalValue)}</Typography>
      </div>
    }
  };

  return (
    <>
      {resolveView()}
    </>
  );
}
