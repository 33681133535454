import {Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import MomentAdapter from "@date-io/moment";

export default function InvoicingPeriodDetails({invoicingPeriod}) {
  const [t] = useTranslation();
  const moment = new MomentAdapter();
  const startDate = moment.format(moment.date(invoicingPeriod.startDate), "DD.MM.YYYY");
  const endDate = moment.format(moment.date(invoicingPeriod.endDate), "DD.MM.YYYY");
  return (
    <>
      <Typography variant="h3">{t("invoicingPeriod.period", {start: startDate, end: endDate})}</Typography>
      {invoicingPeriod?.instructionsForAO &&
      <>
        <Typography variant="subtitle1" color="primary">{t("invoicingPeriod.instructionsFromIA")}</Typography>
        <Typography variant="body1">{invoicingPeriod.instructionsForAO}</Typography>
      </>
      }
    </>
  );
}
