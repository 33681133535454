import {useState} from 'react';
import {Typography, Box} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useReusablePaymentDetails} from 'api';
import DetailsList from './DetailsList';
import Detail from './Detail';
import Actions from './Actions';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CallMergeIcon from '@material-ui/icons/CallMerge';
import {useUser} from '@postinumero/authorization';

export default function Payment({savedId}) {
  const [t] = useTranslation();

  const details = useReusablePaymentDetails()

  const saved = savedId ? details.find(obj => obj.id === parseInt(savedId)) : null
  const active = saved ? saved.id : details.length ? details[0].id : ''

  const [selected, setSelect] = useState(active)

  const selectedDetail = details.find(detail => detail.id === selected)

  const user = useUser();
  const isIA = user.roles.includes('Issuer agent')

  return (
    <div>
      <Typography variant={'h2'}>
        {isIA ?
          <CallMergeIcon color="primary" style={{marginRight: '10px'}}/> :
          <CallMadeIcon color="primary" style={{marginRight: '10px'}}/>
        }
        {t("reusables.paymentTitle", {type: isIA ? "consolidated" : "commission fee"})}
      </Typography>

      <DetailsList
        details={details}
        selected={selected}
        setSelected={setSelect}
        label={t('reusables.paymentDetailsLabel', {role: isIA ? "IA" : "AO"})}
      />

      <Box my={2}>
        <Actions details={details} selected={selectedDetail} />
      </Box>

      <Detail detail={selectedDetail} />
    </div>
  );
}
