import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {postInvoice, putInvoice, removeInvoice, exportInvoice} from 'api';
import {ValidationProvider} from 'shared/useValidation';
import {DeletionConfirmationDialog} from 'shared/FormComponents';
import MomentAdapter from '@date-io/moment';
import Layouts from './Layouts';
import {corporateActions, invoice as invoicePath} from '..';
import useModal from 'shared/useModal';
import useSnackBar from 'shared/SnackBar/useSnackBar';
import {electronicFormat} from 'iban';

export default function Form({invoice, invoicingPeriod, corporateAction}) {
  const [showLineErrors, setShowLineErrors] = useState(false);
  const [lineErrors, setLineErrors] = useState([]);
  const saveInvoice = invoice ? putInvoice : postInvoice;
  const [t] = useTranslation();
  const moment = new MomentAdapter();
  const invoiceDate = moment.format(moment.date(), 'YYYY-MM-DD');
  const history = useHistory();

  const {open, handleOpen, handleClose} = useModal();
  const {showError} = useSnackBar();

  const validateLines = (lines) => {
    const errors = [];

    if (validateQuantityBased(lines)) {
      errors.push(t("validation.feeLines.quantity"));
    }

    if (validateNegotiated(lines)) {
      errors.push(t("validation.feeLines.negotiated"));
    }

    if (validateSelectionBased(lines)) {
      errors.push(t("validation.feeLines.selection"));
    }

    setLineErrors(errors);
    return errors.length < 1;
  };

  const validateQuantityBased = (lines) => {
    return lines
      .filter(line => line.commissionFee?.type === "QUANTITY_BASED")
      .some(line => !line.quantity && line.quantity !== 0);
  };

  const validateNegotiated = (lines) => {
    return lines
      .filter(line => line.commissionFee?.type === "NEGOTIATED" || !line.commissionFee)
      .some(line => {
        return !line.description || (!line.vat && line.vat !== 0) || (!line.amount && line.amount !== 0);
      });
  };

  const validateSelectionBased = (lines) => {
    return lines
      .filter(line => line.commissionFee?.type === "SELECTION_BASED")
      .some(line => !line.selectionId);
  };

  const getPaymentDetails = (paymentDetails) => {
    return {
      ...paymentDetails,
      bankAccount: electronicFormat(paymentDetails.bankAccount)
    }
  }

  const config = {
    inputs: {
      dueDate: {
        isRequired: {message: t('validation.isRequired', {name: t('invoice.dueDate')})},
        isValidDate: {message: t('validation.isValidDate', {name: t('invoice.dueDate')})},
        initialValue: invoice?.dueDate ?? invoicingPeriod.dueDate
      },
      invoiceNumber: {
        initialValue: invoice?.invoiceNumber ?? '',
        isMaxLength: {
          maxLength: 19,
          message: t('validation.isMaxLength', {name: t('invoice.invoiceNumber'), max: 20})
        },
        isOnlyDigits: {
          message: t('validation.isOnlyDigits', {name: t('invoice.invoiceNumber')})
        }
      },
      referenceNumber: {
        initialValue: invoice?.referenceNumber ?? '',
        isMaxLength: {
          maxLength: 19,
          message: t('validation.isMaxLength', {name: t('invoice.referenceNumber'), max: 19})
        },
        isNotValidReferenceNumber: {message: t('validation.isNotValidReferenceNumber', {name: t('invoice.referenceNumber')})}
      },
      vatExclusionReason: {
        initialValue: invoice?.vatExclusionReason ?? '',
        isMaxLength: {
          maxLength: 60,
          message: t('validation.isMaxLength', {name: t('invoice.vatExclusionReason'), max: 60})
        }
      },
      additionalInformation: {
        initialValue: invoice?.additionalInformation ?? '',
        isMaxLength: {
          maxLength: 200,
          message: t('validation.isMaxLength', {name: t('invoice.additionalInformation'), max: 200})
        }
      },
      beneficiaryData: {
        isRequiredObject: {message: t('validation.isRequiredObject', {name: t('invoice.beneficiaryData')})},
      },
      paymentDetails: {
        isRequiredObject: {message: t('validation.isRequiredObject', {name: t('invoice.paymentDetails')})}
      },
      supplyOfService: {
        initialValue: invoice?.supplyOfService ?? corporateAction?.recordDate
      },
      invoiceLines: {
        initialValue: invoice?.invoiceLines ?? []
      }
    },
    onClick: async (state, status, stay) => {
      if (!state.errors || Object.keys(state.errors).length === 0) {

        if (!validateLines(state.values.invoiceLines)) {
          setShowLineErrors(true);
          return;
        }

        if (lineErrors.length > 0) {
          setShowLineErrors(true);
          return;
        }

        const invoiceToSave = invoice ?
        {
          ...state.values,
          currentStatus: status,
          invoicingPeriodId: invoicingPeriod.id,
          id: invoice?.id,
          invoiceDate: invoiceDate,
          paymentDetails: getPaymentDetails(state.values.paymentDetails)
        } :
        {
          ...state.values,
          currentStatus: status,
          invoicingPeriodId: invoicingPeriod.id,
          invoiceDate: invoiceDate,
          paymentDetails: getPaymentDetails(state.values.paymentDetails)
        };

        // Remove white spaces from IBAN
        invoiceToSave.paymentDetails.bankAccount = invoiceToSave.paymentDetails.bankAccount.replaceAll(/\s/g,'');

        await saveInvoice(invoiceToSave).then(response => {
          if (stay) {
            history.push({pathname: invoicePath.replace(":id?", response.data.id)});
          } else {
            history.push({pathname: corporateActions, search: '?ca=' + corporateAction?.id});
          }
        }).catch((err) => {
          showError(err.response)
        });
      }
    },
    deletionConfirmationFunction: handleOpen,
    deleteFunction: () => {
      removeInvoice(invoice.id).then(() => {
        history.push({pathname: corporateActions, search: '?ca=' + corporateAction?.id});
      }).catch((err) => {
        showError(err.response)
      });
    },
    exportXml: () => {
      exportInvoice(invoice.id).then((res) => {
        const xmlString = res.data
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:attachment/text,' + encodeURI(xmlString);
        hiddenElement.target = '_blank';
        hiddenElement.download = `invoice${invoice.id}.xml`;
        hiddenElement.click();
      }).catch((err) => {
        showError(err.response)
      })
    }
  };
  return (
    <ValidationProvider config={config}>
      <DeletionConfirmationDialog onClose={handleClose} open={open} itemName={t('invoice.name')}/>
      <Layouts invoice={invoice}
               invoicingPeriod={invoicingPeriod}
               corporateAction={corporateAction}
               validateLines={validateLines}
               showLineErrors={showLineErrors}
               setShowLineErrors={setShowLineErrors}
               lineErrors={lineErrors}
      />
    </ValidationProvider>
  );
}
