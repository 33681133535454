import {Typography, makeStyles} from "@material-ui/core";
import {useEffect} from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(8),
    marginTop: theme.spacing(4),
  },
  h1: {
    fontWeight: 300,
    fontSize: '2.57rem'
  }
}));
const documentTitle = document.title;

export default function TitleBlock({title, printTitle}) {
  const classes = useStyles();
  useEffect(() => {
    document.title = title ? `${title} – ${documentTitle}` : documentTitle;
  });
  return (
    <div className={printTitle ? [classes.root] : [classes.root, 'no-print'].join(' ')}>
      <Typography className={classes.h1} variant="h1">
        {title}
      </Typography>
    </div>
  );
}
