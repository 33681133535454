import {useContext} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import {ValidationContext} from 'shared/useValidation';
import {useTranslation} from 'react-i18next';
import {INVALID} from 'shared/Statuses';


export default function InvalidateConfirmationDialog({open, onClose}) {
  const [t] = useTranslation();
  const {getActionProps} = useContext(ValidationContext);
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle disableTypography>
        <Typography variant="h1">{t('modal.removeItem.title')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>{t('consolidatedInvoice.invalidateConfirmation')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          onClick={onClose}
        >
          {t('modal.removeItem.no')}
        </Button>
        <Button
          color="secondary"
          variant="contained"
          {...getActionProps(INVALID)}
        >
          {t('modal.removeItem.yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
