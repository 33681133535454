import {Box, Grid, Paper, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import PayerDetails from 'shared/Components/PayerDetails';

export default function Selected({payer}) {
  const [t] = useTranslation();
  return (
    <div>
      { payer &&
      <Paper elevation={2}>
        <Box p={4}>
          <Grid container justify="center">
            <Grid item sm={12}>
              <Box mb={1}>
                <Typography variant="h3">{t('reusables.payer')}</Typography>
              </Box>
            </Grid>
            <Grid item sm={12}>
              <PayerDetails payerDetails={payer.payerDetails} />
            </Grid>
          </Grid>
        </Box>
      </Paper>}
    </div>
  );
}
