import {useState} from 'react';
import {useTranslation} from "react-i18next";
import AddDetailsDialog from "./AddDetailsDialog";
import Details from "./Details";
import useModal from 'shared/useModal';
import {ValidationProvider} from 'shared/useValidation';
import {useReusableBeneficiaries} from 'api';

export default function BeneficiaryInfo({beneficiary, setInvoice, disableInputs}) {
  const [t] = useTranslation();
  const {open, handleOpen, handleClose} = useModal();

  const reusableBeneficiaries = useReusableBeneficiaries()
  const sorteBeneficiaries = reusableBeneficiaries.sort((a, b) => a.dataName.localeCompare(b.dataName));
  const [selected, setSelected] = useState(sorteBeneficiaries.length ? sorteBeneficiaries[0].id : -1)
  const beneficiaries = [{ id: -1, dataName: t("beneficiary.manually")}].concat(sorteBeneficiaries)

  const config = (fields) => ({
    inputs: {
      beneficiaryName: {
        isRequired: {message: t("validation.isRequired", {name: t("beneficiary.beneficiaryName")})},
        initialValue: beneficiary?.beneficiaryName ?? ''
      },
      beneficiaryNameSupplement: {
        initialValue: beneficiary?.beneficiaryNameSupplement ?? ''
      },
      spareLine: {
        initialValue: beneficiary?.spareLine ?? ''
      },
      deliveryAddress: {
        isRequired: {message: t("validation.isRequired", {name: t("deliveryDetails.deliveryAddress")})},
        initialValue: beneficiary?.deliveryAddress ?? ''
      },
      deliveryAddressSecondLine: {
        initialValue: beneficiary?.deliveryAddressSecondLine ?? ''
      },
      postalCode: {
        isRequired: {message: t("validation.isRequired", {name: t("deliveryDetails.postalCode")})},
        isValidFinnishPostalCode: {
          value: fields.country,
          message: t("validation.isValidFinnishPostalCode", {name: t("deliveryDetails.postalCode")})
        },
        initialValue: beneficiary?.postalCode ?? ''
      },
      city: {
        isRequired: {message: t("validation.isRequired", {name: t("deliveryDetails.city")})},
        initialValue: beneficiary?.city ?? ''
      },
      country: {
        initialValue: beneficiary?.country ?? ''
      },
      email: {
        isRequired: {message: t("validation.isRequired", {name: t("deliveryDetails.email")})},
        isEmail: {message: t("validation.isEmail", {name: t("deliveryDetails.email")})},
        initialValue: beneficiary?.email ?? ''
      },
    },
    onClick: (state) => {
      if (selected > -1) {
        const selectedBeneficiary = reusableBeneficiaries.find(beneficiary => beneficiary.id === selected)
        setInvoice(selectedBeneficiary.beneficiaryData, "beneficiaryData")
        handleClose();
      } else if (!state.errors || Object.keys(state.errors).length === 0) {
        setInvoice(state.values, "beneficiaryData");
        handleClose();
      }
    }
  });
  return (
    <ValidationProvider config={config}>
      {!disableInputs && <AddDetailsDialog
        onClose={handleClose}
        open={open}
        beneficiaries={beneficiaries}
        selected={selected}
        setSelected={setSelected} />}
      <Details beneficiary={beneficiary} openDialog={handleOpen} disableInputs={disableInputs}/>
    </ValidationProvider>
  );
}
