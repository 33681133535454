import {useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Button, Grid} from '@material-ui/core';
import {ValidationContext} from 'shared/useValidation';
import {PUBLISHED, DRAFT, READYTOPUBLISH, DONE, INVALID} from 'shared/Statuses';
import useModal from 'shared/useModal';
import InvalidateConfirmationDialog from './InvalidateConfirmationDialog';

export default function Actions({status}) {
  const [t] = useTranslation();
  const {open, handleOpen, handleClose} = useModal();
  const {getActionProps, getDeletionConfirmationProp} = useContext(ValidationContext);
  const history = useHistory();
  return (
    <>
      <InvalidateConfirmationDialog
        open={open}
        onClose={handleClose}
      />
    <Grid item container justify="flex-end" lg={8} spacing={5}>
      <Grid item>
        <Button
          color="primary"
          size="large"
          variant="outlined"
          onClick={() => {
            history.goBack();
          }}>{t('form.cancel')}</Button>
      </Grid>
      {status && (status === DONE || status === PUBLISHED) && <Grid item>
            <Button
              color="primary"
              size="large"
              variant="outlined"
              onClick={handleOpen}
            >
              {t('form.invalidate')}
            </Button>
          </Grid>}
      {status && (status === DRAFT || status === READYTOPUBLISH) &&
        <Grid item>
          <Button
            color="primary"
            size="large"
            variant="outlined"
            {...getDeletionConfirmationProp()}
          >
            {t('form.remove')}
          </Button>
        </Grid>
      }
      {status && status === PUBLISHED &&
      <Grid item>
        <Button
          color="primary"
          size="large"
          variant="outlined"
          {...getActionProps(DONE)}
        >
          {t('form.done')}
        </Button>
      </Grid>}
      {!status || (status !== PUBLISHED && status !== INVALID && status !== DONE) ?
        <>
          <Grid item>
            <Button
              color="primary"
              size="large"
              variant="outlined"
              {...getActionProps(DRAFT)}
            >
              {t('form.saveDraft')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              size="large"
              variant="contained"
              {...getActionProps(READYTOPUBLISH)}
            >
              {t('event.readyToPublish')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              size="large"
              variant="contained"
              {...getActionProps(PUBLISHED)}
            >
              {t('form.savePublish')}
            </Button>
          </Grid>
        </> : null}
    </Grid>
    </>
  );
}
