import {createMuiTheme} from '@material-ui/core';

export const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        fontFamily: 'Open Sans,Arial,sans-serif',
        // '&:nth-child(1)': {
        //   borderBottom: 'none',
        //   width: '10px'
        // }
      },
      head: {
        fontWeight: 'bold',
        borderBottom: '1px solid #3F51B5',
        borderTop: '1px solid #3F51B5',
        boxShadow: '0px 2px 0px rgba(63, 81, 181, 0.49)',
        // '&:nth-child(1)': {
        //   borderBottom: 'none',
        //   width: '10px'
        // }
      }
    },
    MUIDataTableBodyRow: {
      root: {
        cursor: 'pointer'
      }
    }
  },
});
