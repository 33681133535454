import {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {ValidationContext} from 'shared/useValidation';

export default function PaymentDetails({ reusable }) {
  const [t] = useTranslation();
  const {getInputProps, setValue} = useContext(ValidationContext);
  return (
    <>
      {reusable &&
      <Grid item sm={12}>
        <TextField
          label={t("paymentDetails.paymentDetailsName")}
          required
          variant="outlined"
          fullWidth
          {...getInputProps('dataName')}
        />
      </Grid>}
      <Grid item sm={12}>
        <TextField
          label={t("paymentDetails.bankAccount")}
          required
          variant="outlined"
          fullWidth
          {...getInputProps('bankAccount', {onChange: (event) => setValue('bankAccount', event.target.value)})}
        />
      </Grid>
      <Grid item sm={12}>
        <TextField
          label={t("paymentDetails.swift")}
          required
          variant="outlined"
          fullWidth
          {...getInputProps('swift')}
        />
      </Grid>
      <Grid item sm={12}>
        <TextField
          label={t("paymentDetails.businessId")}
          required
          variant="outlined"
          fullWidth
          {...getInputProps('businessId')}
        />
      </Grid>
      <Grid item sm={12}>
        <TextField
          label={t("paymentDetails.vatId")}
          required
          variant="outlined"
          fullWidth
          {...getInputProps('vatId')}
        />
      </Grid>
    </>
  );
}
