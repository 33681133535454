import {Box, Grid, Typography} from '@material-ui/core';
import CorporateActionDetails from '../CorporateActionDetails';
import InvoicingPeriodDetails from '../InvoicingPeriodDetails';
import PaymentInfo from '../PaymentInfo';
import BeneficiaryInfo from '../BeneficiaryInfo';
import Actions from "./Actions";
import Fields from '../Fields';
import Status from 'App/Pages/CorporateAction/Status';
import VatExclusion from '../VatExclusion';
import ReferenceNumber from '../ReferenceNumber';
import CommissionFees from '../CommissionFees';
import AdditionalInformation from '../AdditionalInformation';
import DueDate from '../DueDate';
import PayerDetails from 'shared/Components/PayerDetails';
import {useTranslation} from 'react-i18next';
import {useUser} from '@postinumero/authorization';
import {Alert, AlertTitle} from '@material-ui/lab';
import {DRAFT, READYTOSEND} from 'shared/Statuses';
import PrintLayout from './Print';

export default function Edit({corporateAction, invoicingPeriod, invoiceState, updateState, classes, status, validateLines, showLineErrors, setShowLineErrors, lineErrors}) {
  const user = useUser();
  const disableActions = user.roles.includes('Inspector') || user.roles.includes('Issuer agent')
  const disableInputs = ![DRAFT, READYTOSEND].includes(status) || disableActions
  const [t] = useTranslation();
  return (
    <>
      <Box display="block" displayPrint="none">
        <Status statuses={invoiceState?.statuses} allowHistory={["Inspector", "Account operator"]}/>
        <Grid className={classes.row} container item xs={12} justify={"space-between"}>
          <Grid container item className={classes.row} sm={12} md={8}>
            <Grid container item sm={12}>
              <Grid item sm={12} md={8} className={classes.row}>
                <CorporateActionDetails corporateAction={corporateAction}/>
              </Grid>
              <Grid item sm={12} md={4} className={classes.row}>
                <Fields
                  invoiceDate={invoiceState?.invoiceDate}
                  supplyOfService={corporateAction?.recordDate}
                  disableInputs={disableInputs}/>
              </Grid>
            </Grid>
            <Grid container item sm={12}>
              <Grid item xs={12} sm={8}>
                <Typography variant="h3">{t("invoice.payer")}</Typography>
                <PayerDetails payerDetails={invoicingPeriod}/>
              </Grid>
              <Grid item xs={12} sm={4}>
                <BeneficiaryInfo
                  beneficiary={invoiceState?.beneficiaryData}
                  setInvoice={updateState}
                  disableInputs={disableInputs}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item sm={12} md={4}>
            <Grid item sm={12} className={classes.row}>
              <Grid item sm={12} className={classes.row}>
                <PaymentInfo
                  paymentDetails={invoiceState?.paymentDetails}
                  setInvoice={updateState}
                  disableInputs={disableInputs}
                />
              </Grid>
              <Grid item container sm={12} className={classes.row}>
                <Grid item sm={12} md={6}>
                  <ReferenceNumber disableInputs={disableInputs}/>
                </Grid>
                <Grid item sm={12} md={6} style={{paddingLeft: '4px'}}>
                  <DueDate disableInputs={disableInputs}/>
                </Grid>
              </Grid>
              <Grid item sm={12} className={classes.row}>
                <VatExclusion disableInputs={disableInputs}/>
              </Grid>
              <Grid item sm={12} className={classes.row}>
                <AdditionalInformation disableInputs={disableInputs}/>
              </Grid>
            </Grid>
            <Grid item sm={12} className={classes.row}>
              <InvoicingPeriodDetails invoicingPeriod={invoicingPeriod}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.row} container item xs={12}>
          {showLineErrors &&
          <Grid item xs={12}>
            <Alert severity="error" style={{marginBottom: '15px'}}>
              <AlertTitle>{t("validation.feeLines.title")}</AlertTitle>
              <ul>
                {lineErrors.map((err, i) => {
                  return (<li key={i}>{err}</li>)
                })}
              </ul>
            </Alert>
          </Grid>}
          <CommissionFees
            updateState={updateState}
            lines={invoiceState?.invoiceLines}
            caType={corporateAction ? corporateAction?.actionEventType.type : ''}
            disableInputs={disableInputs}
            invoiceId={invoiceState?.id}
            validateLines={validateLines}
            showLineErrors={showLineErrors}
            setShowLineErrors={setShowLineErrors}
          />
        </Grid>
        <Grid className={"no-print"} item xs={12} container justify="flex-end" spacing={3}>
          <Actions status={status} invoiceExist={!!invoiceState.id} disableActions={disableActions}/>
        </Grid>
      </Box>
      <PrintLayout
        corporateAction={corporateAction}
        invoicingPeriod={invoicingPeriod}
        invoiceState={invoiceState}
        updateState={updateState}
        validateLines={validateLines}
        showLineErrors={showLineErrors}
        setShowLineErrors={setShowLineErrors}
        disableInputs={disableInputs}/>
    </>
  );
}
