import {Grid, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import {corporateActions} from 'App/Pages';

export default function CorporateActionDetails({corporateAction}) {
  const [t] = useTranslation();
  return (
    <>
      <Typography variant="h3">
        {t("event.title")}
        <Link to={{pathname: corporateActions, search: 'ca=' + corporateAction.id}}
              style={{marginLeft: '10px'}}><Typography variant="overline" color="primary">{t("event.view")}</Typography></Link>
      </Typography>
      <Grid container spacing={1}>
        {corporateAction.issuer &&
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1" color="primary">{t("event.issuer")}</Typography>
          <Typography variant="body1">{corporateAction.issuer}</Typography>
        </Grid>
        }
        {corporateAction.eventId &&
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1" color="primary">{t("event.eventId")}</Typography>
          <Typography variant="body1">{corporateAction.eventId}</Typography>
        </Grid>
        }
        {corporateAction.actionEventType &&
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1" color="primary">{t("event.actionEventType")}</Typography>
          <Typography
            variant="body1">{corporateAction.actionEventType.type + " (" + corporateAction.actionEventType.description + ")"}</Typography>
        </Grid>
        }
        {corporateAction.issuerBusinessId &&
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1" color="primary">{t("event.issuerBusinessId")}</Typography>
          <Typography variant="body1">{corporateAction.issuerBusinessId}</Typography>
        </Grid>
        }
        {corporateAction.issuerISIN &&
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1" color="primary">{t("event.issuerISIN")}</Typography>
          <Typography variant="body1">{corporateAction.issuerISIN}</Typography>
        </Grid>
        }
        {corporateAction.eventClassification &&
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1" color="primary">{t("event.eventClassification")}</Typography>
          <Typography variant="body1">{corporateAction.eventClassification}</Typography>
        </Grid>
        }
      </Grid>
    </>
  );
}
