import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  Divider,
  Button
} from '@material-ui/core';
import {formatDate} from 'shared/utility';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CallMergeIcon from '@material-ui/icons/CallMerge';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import {corporateActions, invoice, consolidatedInvoice} from 'App/Pages';
import {Link} from 'react-router-dom';
import {formatDecimal} from 'shared/utility'

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    marginTop: 0,
    width: '100%',
    background: 'transparent',
    paddingLeft: '15px',
    '&:before': {
      height: 0
    },
    '&:first-of-type': {
      marginTop: theme.spacing(3)
    },
    '&.Mui-expanded': {
      borderLeft: 'solid 2px ' + theme.palette.primary.light
    }
  },
  summary: {
    justifyContent: 'flex-start',
    minHeight: '0',
    padding: 0,
    '&.Mui-expanded': {
      minHeight: '0'
    },
    '& .MuiAccordionSummary-content': {
      flexGrow: 0,
      margin: '0',
      alignItems: 'center'
    }
  },
  content: {
    flexWrap: 'wrap'
  },
  padded: {
    paddingBottom: '1em'
  }
}));

export default function NotificationBox({notifications, title, subString, removeNotification, icon}) {
  const [t] = useTranslation();
  const classes = useStyles();

  const buildLink = (notification, subString) => {
    if (subString === 'invoicingPeriod') {
      return <Link to={{pathname: corporateActions, search: '?ca=' + notification.corporateAction.id}}>
        {t("view")}
      </Link>
    } else {
      if (notification.invoice) {
        return <Link to={{pathname: invoice.replace(":id?", notification.invoice.id)}}>
          {t("view")}
        </Link>
      }

      if (notification.consolidatedInvoice) {
        return <Link to={{pathname: consolidatedInvoice.replace(":id?", notification.consolidatedInvoice.id)}}>
          {t("view")}
        </Link>
      }
    }
  };

  const buildCorporateActionString = (corporateAction) => {
    return corporateAction.actionEventType.description +
      " (" + corporateAction.actionEventType.type + "): " +
      corporateAction.issuer + ", " +
      corporateAction.issuerBusinessId;
  };

  const buildSubString = (notification, subString) => {
    switch (subString) {
      case 'invoicingPeriod':
        return buildInvoicingPeriodString(notification.corporateAction, notification.invoicingPeriod);
      case 'invoice':
        return buildInvoiceString(notification);
      case 'invoiceTo':
        return buildInvoiceStringTo(notification);
      case 'invoiceFrom':
        return buildInvoiceStringFrom(notification);
      case 'consolidated':
        return buildConsolidatedString(notification);
      default:
        return null;
    }
  };

  const buildInvoicingPeriodString = (corporateAction, invoicingPeriod) => {
    return "Invoicing period: " +
      formatDate(invoicingPeriod.startDate) + " - " + formatDate(invoicingPeriod.endDate) +
      " (" + corporateAction.organisation + ") "
  };

  const buildInvoiceString = (notification) => {
    const invoice = notification.invoice ? notification.invoice : notification.consolidatedInvoice;
    return (notification.invoice ? "Commission fee invoice: " : "Consolidated invoice: ") +
      formatDecimal(invoice.totalAmount) + " EUR (" + invoice.organisation + ") ";
  };

  const buildInvoiceStringTo = (notification) => {
    const invoice = notification.invoice ? notification.invoice : notification.consolidatedInvoice;
    return (notification.invoice ? "Commission fee invoice: " : "Consolidated invoice: ") +
      formatDecimal(invoice.totalAmount) + " EUR to " + invoice.beneficiaryData.beneficiaryName + " ";
  };

  const buildInvoiceStringFrom = (notification) => {
    const invoice = notification.invoice ? notification.invoice : notification.consolidatedInvoice;
    return (notification.invoice ? "Commission fee invoice: " : "Consolidated invoice: ") +
      formatDecimal(invoice.totalAmount) + " EUR from " + invoice.organisation + " ";
  };

  const buildConsolidatedString = (notification) => {
    return "Consolidated invoice: " + formatDecimal(notification.consolidatedInvoice.totalAmount) + " EUR ";
  };

  const resolveNotifications = () => {
    if (Array.isArray(notifications) && notifications.length > 0) {
      return <Grid container item xs={12}>
        {notifications.map(notification => {
          return <Grid item container
                       key={notification.notification.id}
                       xs={12}
                       spacing={2}
                       direction="row"
                       alignItems={"center"}
                       className={classes.padded}
          >
            <Grid item xs={1}>
              {icon === "assignment" && <AssignmentOutlinedIcon
                fontSize={"large"}
                style={{marginRight: '10px', fill: '#0062A3'}}
              />}
              {icon === "callMade" && <CallMadeIcon
                fontSize={"large"}
                style={{marginRight: '10px', fill: '#0062A3'}}
              />}
              {icon === "callReceived" && <CallMadeIcon
                fontSize={"large"}
                style={{marginRight: '10px', fill: '#0062A3', transform: 'rotate(90deg)'}}
              />}
              {icon === "callMerge" && <CallMergeIcon
                fontSize={"large"}
                style={{marginRight: '10px', fill: '#0062A3'}}
              />}
            </Grid>
            <Grid item xs={10}>
              <Typography variant={"h3"}>
                {buildCorporateActionString(notification.corporateAction)}
              </Typography>
              <Typography style={{fontSize: '80%', color: 'gray'}}>
                {buildSubString(notification, subString)}
                {buildLink(notification, subString)}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Button onClick={() => removeNotification(notification.notification.id)}>
                <CancelOutlined
                  style={{fill: '#EB5757'}}
                />
              </Button>
            </Grid>
            <Divider style={{width: '100%'}}/>
          </Grid>
        })}
      </Grid>
    } else {
      return <Grid item xs={12}>
        {t("notifications.noNew")}
      </Grid>
    }
  };

  return (
    <Grid container item xs={12}>
      <Grid container item xs={12}>
        <Accordion
          className={classes.root}
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary"/>}
            className={classes.summary}
          >
            <Typography variant={"h2"}>
              {t(title)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.content}>
            {resolveNotifications()}
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}
