import {useState, useCallback} from 'react';

export default function useModal() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  return {
    data,
    open,
    handleOpen: useCallback(data => {
      setData(data);
      setOpen(true);
    }, []),
    handleClose: useCallback(() => setOpen(false), [])
  };
}
