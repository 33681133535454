import React, {useContext, useState, useEffect} from 'react';
import {Button, Dialog, Grid, Select, MenuItem, InputLabel, Container, TextField, Link} from '@material-ui/core';
import {makeStyles} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import {ValidationContext} from 'shared/useValidation';
import {useTranslation} from 'react-i18next';
import {ValidationErrors} from 'shared/FormComponents';
import {Alert, AlertTitle} from '@material-ui/lab';
import {inputValidatonToValidDecimal, ceiling} from 'shared/utility';
import {useDeepCompareEffect} from 'shared/useDeepCompareEffect';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialogContent-root': {
      padding: '20px 24px'
    },
    '& .MuiDialogActions-root': {
      paddingLeft: '24px',
      paddingRight: '24px'
    }
  },
  container: {
    minWidth: 530,
    maxHeight: '100%'
  },
  bottomPad: {
    paddingBottom: '1em'
  },
  lesserBottomPad: {
    paddingBottom: '0.5em'
  },
  topMargin: {
    marginTop: '1em'
  },
  infoContainer: {
    maxWidth: '100%',
    padding: 15,
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)'
  }
}));

const feeTypes = [
  {type: 'SELECTION_BASED', label: "Selection based fee"},
  {type: "QUANTITY_BASED", label: "Quantity based fee"},
  {type: "COMMISSION_BASED", label: "Commission based fee"},
  {type: "NEGOTIATED", label: "Negotiated fee"}
];

export default function FeeDialog({fee, open, onClose, selectOptionsErrors, validateSelectOptions, showOptionsErrors, setShowOptionsErrors}) {
  const [t] = useTranslation();
  const classes = useStyles();
  const {values, getActionProps, setValue, getInputProps} = useContext(ValidationContext);
  const [lastFeeId, setLastFeeId] = useState(null);

  useEffect(() => {
    const deepCopySimpleObject = (obj) => obj ? JSON.parse(JSON.stringify(obj)) : '';
    if (fee && fee.id !== lastFeeId) {
      setValue('type', fee.type ? fee.type : 'NEGOTIATED');
      setValue('description', fee.description ?? '');
      setValue('amount', fee.amount);
      setValue('pricePerQuantity', fee.pricePerQuantity);
      setValue('minimumPrice', fee.minimumPrice);
      setValue('vat', fee.vat);
      setValue('options', fee.options ? deepCopySimpleObject(fee.options) : []);
      setLastFeeId(fee.id);
    }
  }, [fee, lastFeeId, setValue]);

  const modifySelection = (index, field, value) => {
    const modifiedCollection = values.options;
    const itemToModify = modifiedCollection[index];

    if (field === 'description') {
      itemToModify.description = value;
    }

    if (field === 'amount') {
      itemToModify.amount = value;
    }

    if (field === 'vat') {
      itemToModify.vat = value;
    }

    modifiedCollection[index] = itemToModify;
    if (validateSelectOptions(modifiedCollection) && showOptionsErrors) {
      setShowOptionsErrors(false);
    }

    setValue('options', modifiedCollection);
  };

  const addNewSelection = () => {
    const options = values.options;
    options.push({
      id: null,
      description: '',
      amount: '',
      vat: ''
    });

    setValue('options', options);
  };

  const removeSelection = (index) => {
    const options = values.options;
    options.splice(index, 1);

    setValue('options', options);
  };

  // Clear form when type changes. Causes weird behaviour if not cleared.
  useDeepCompareEffect(() => {
    if (values.type === fee?.type) {
      setValue('description', fee.description ?? '');
      setValue('amount', fee.amount ?? '');
      setValue('pricePerQuantity', fee.pricePerQuantity ?? '');
      setValue('minimumPrice', fee.minimumPrice ?? '');
      setValue('vat', fee.vat ?? '');
    } else {
      setValue('description', '');
      setValue('amount', '');
      setValue('pricePerQuantity', '');
      setValue('minimumPrice', '');
      setValue('vat', '');
    }
  }, [values.type, setValue])

  const resolveForm = () => {
    switch (values.type) {
      case 'SELECTION_BASED':
        return (
          <Grid item container xs={12} spacing={2} alignContent={'flex-start'}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                required
                label={t("tariffs.feeDetails.description")}
                helperText={t("tariffs.feeDetails.descriptionHelper")}
                onInput={(e) => e.target.value = e.target.value.slice(0, 120)}
                {...getInputProps("description")}
              />
            </Grid>
            {showOptionsErrors &&
            <Alert severity="error" style={{marginBottom: '15px'}}>
              <AlertTitle>{t("validation.selection.title")}</AlertTitle>
              <ul>
                {selectOptionsErrors.map((err, i) => {
                  return (<li key={i}>{err}</li>)
                })}
              </ul>
            </Alert>}
            {values.options.map((option, index) => {
              return (
                <Grid key={index} container item xs={12}>
                  <Grid item xs={12}>
                    <Typography variant={"h3"} style={{display: 'inline'}}>
                      {t("tariffs.feeDetails.feeSelection") + (index + 1) + " "}
                    </Typography>
                    {values.options.length > 1 &&
                    <Link component="button" type="button" onClick={() => removeSelection(index)}>
                      <Typography variant="overline">{t("tariffs.feeDetails.removeSelection")}</Typography>
                    </Link>}
                  </Grid>
                  <Grid item xs={12} className={classes.lesserBottomPad}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      label={t("tariffs.feeDetails.description")}
                      helperText={t("tariffs.feeDetails.descriptionHelper")}
                      value={values.options[index].description}
                      onInput={(e) => e.target.value = e.target.value.slice(0, 120)}
                      onChange={(event) => modifySelection(index, 'description', event.target.value)}
                    />
                  </Grid>
                  <Grid container item xs={7} spacing={2}>
                    <Grid item xs={9}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        required
                        label={t("tariffs.feeDetails.price")}
                        helperText={t("tariffs.feeDetails.priceHelper")}
                        value={values.options[index].amount}
                        onInput={(event) => event.target.value = inputValidatonToValidDecimal(event.target.value)}
                        onChange={(event) => modifySelection(index, 'amount', event.target.value)}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.topMargin}>
                      <Typography variant={"body1"}>{t("tariffs.feeDetails.priceSuffix")}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={5} spacing={2}>
                    <Grid item xs={8}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        required
                        label={t("tariffs.feeDetails.vat")}
                        value={values.options[index].vat}
                        onInput={(event) => event.target.value = ceiling(inputValidatonToValidDecimal(event.target.value), 100)}
                        onChange={(event) => modifySelection(index, 'vat', event.target.value)}
                      />
                    </Grid>
                    <Grid item xs={4} className={classes.topMargin}>
                      <Typography variant={"body1"}>%</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
            <Grid item xs={9}/>
            <Grid item xs={3}>
              <Link component="button" type="button" onClick={addNewSelection}>
                <Typography variant="overline">{t("tariffs.feeDetails.addSelection")}</Typography>
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Container className={classes.infoContainer}>
                <Typography variant={"body1"}>{t("tariffs.feeType.selectionInfo")}</Typography>
              </Container>
            </Grid>
          </Grid>
        );
      case 'QUANTITY_BASED':
        return (
          <Grid item container xs={12} spacing={2} alignContent={'flex-start'}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                required
                label={t("tariffs.feeDetails.description")}
                helperText={t("tariffs.feeDetails.descriptionHelper")}
                onInput={(e) => e.target.value = e.target.value.slice(0, 120)}
                {...getInputProps("description")}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                variant="outlined"
                fullWidth
                required
                label={t("tariffs.feeDetails.pricePerQuantity")}
                helperText={t("tariffs.feeDetails.priceHelper")}
                onInput={(event) => event.target.value = inputValidatonToValidDecimal(event.target.value)}
                {...getInputProps("pricePerQuantity")}
              />
            </Grid>
            <Grid item xs={7} className={classes.topMargin}>
              <Typography variant={"body1"}>{t("tariffs.feeDetails.pricePerQuantitySuffix")}</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                variant="outlined"
                fullWidth
                required
                label={t("tariffs.feeDetails.minimumPrice")}
                helperText={t("tariffs.feeDetails.priceHelper")}
                onInput={(event) => event.target.value = inputValidatonToValidDecimal(event.target.value)}
                {...getInputProps("minimumPrice")}
              />
            </Grid>
            <Grid item xs={7} className={classes.topMargin}>
              <Typography variant={"body1"}>{t("tariffs.feeDetails.priceSuffix")}</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                variant="outlined"
                fullWidth
                required
                label={t("tariffs.feeDetails.vat")}
                onInput={(event) => event.target.value = ceiling(inputValidatonToValidDecimal(event.target.value), 100)}
                {...getInputProps("vat")}
              />
            </Grid>
            <Grid item xs={7} className={classes.topMargin}>
              <Typography variant={"body1"}>%</Typography>
            </Grid>
            <Grid item xs={12}>
              <Container className={classes.infoContainer}>
                <Typography variant={"body1"}>{t("tariffs.feeType.quantityInfo1")}</Typography>
                <br/>
                <Typography variant={"body1"}>{t("tariffs.feeType.quantityInfo2")}</Typography>
              </Container>
            </Grid>
          </Grid>
        );
      case 'COMMISSION_BASED':
        return (
          <Grid item container xs={12} spacing={2} alignContent={'flex-start'}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                required
                label={t("tariffs.feeDetails.description")}
                helperText={t("tariffs.feeDetails.descriptionHelper")}
                onInput={(e) => e.target.value = e.target.value.slice(0, 120)}
                {...getInputProps("description")}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                variant="outlined"
                fullWidth
                required
                label={t("tariffs.feeDetails.price")}
                helperText={t("tariffs.feeDetails.priceHelper")}
                onInput={(event) => event.target.value = inputValidatonToValidDecimal(event.target.value)}
                {...getInputProps("amount")}
              />
            </Grid>
            <Grid item xs={7} className={classes.topMargin}>
              <Typography variant={"body1"}>{t("tariffs.feeDetails.priceSuffix")}</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                variant="outlined"
                fullWidth
                required
                label={t("tariffs.feeDetails.vat")}
                onInput={(event) => event.target.value = ceiling(inputValidatonToValidDecimal(event.target.value), 100)}
                {...getInputProps("vat")}
              />
            </Grid>
            <Grid item xs={7} className={classes.topMargin}>
              <Typography variant={"body1"}>%</Typography>
            </Grid>
            <Grid item xs={12}>
              <Container className={classes.infoContainer}>
                <Typography variant={"body1"}>{t("tariffs.feeType.commissionInfo")}</Typography>
              </Container>
            </Grid>
          </Grid>
        );
      case 'NEGOTIATED':
        return <Grid item xs={12}>
          <Container className={classes.infoContainer}>
            <Typography variant={"body1"}>{t("tariffs.feeType.negotiatedInfo1")}</Typography>
            <Typography variant={"body1"}>{t("tariffs.feeType.negotiatedInfo2")}</Typography>
            <Typography variant={"body1"}>{t("tariffs.feeType.negotiatedInfo3")}</Typography>
            <Typography variant={"body1"}>{t("tariffs.feeType.negotiatedInfo4")}</Typography>
          </Container>
        </Grid>;
      default:
        return <div>type not found</div>;
    }
  };

  return (
    <Dialog className={classes.root} onClose={onClose} open={open}>
      <Container className={classes.container}>
        <DialogTitle disableTypography>
          <Typography variant="h1">{t('tariffs.feeDetails.addFee')}</Typography>
        </DialogTitle>
        <Grid item xs={12}>
          <ValidationErrors/>
        </Grid>
        <DialogContent>
          <Grid item xs={12}>
            <Typography variant={'h2'} className={classes.bottomPad}>
              {fee?.corporateActionEventType.description + " (" + fee?.corporateActionEventType.type + ")"}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.bottomPad}>
            <InputLabel id="label-feeType">{t('event.actionEventType')}</InputLabel>
            <Select
              labelId="label-feeType"
              fullWidth={true}
              required
              onChange={(event) => {
                setValue('type', event.target.value);
              }}
              label={t('event.actionEventType')}
              {...getInputProps('type')}
              variant={"outlined"}
            >
              {feeTypes.map(type => <MenuItem key={type.type} value={type.type}>{type.label}</MenuItem>)}
            </Select>
          </Grid>
          <Grid item xs={12} style={{paddingRight: 0}}>
            {resolveForm()}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={onClose}
          >
            {t('modal.publishTariff.no')}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            {...getActionProps()}
          >
            {t('tariffs.feeDetails.saveButton')}
          </Button>
        </DialogActions>
      </Container>
    </Dialog>
  );
}
