import {consolidatedInvoice, invoice} from 'App/Pages';
import {useTranslation} from 'react-i18next';
import {CallMade, CallMerge} from '@material-ui/icons';
import {generatePath, Link as ReactRouterLink} from 'react-router-dom';
import {ListItemText, ListItem, Link, Typography} from '@material-ui/core';
import {formatDate, removeUnderscores, formatDecimal} from 'shared/utility';
import {INVALID} from 'shared/Statuses';

export default function InvoiceIA({invoiceData, consolidated, rotateIcon}) {
  const [t] = useTranslation();
  const status = invoiceData?.statuses[0];

  const RouterLink = (props) => {
    return <Link component={ReactRouterLink} {...props}/>
  };

  return (
    <>
      <ListItem dense component={RouterLink}
                to={generatePath(consolidated ? consolidatedInvoice : invoice, {id: invoiceData?.id})}>
        {consolidated ?
          <CallMerge color="primary" style={{marginRight: '10px'}}/> :
          <CallMade color="primary" style={rotateIcon ? {marginRight: '10px', transform: 'rotate(90deg)'} :
            {marginRight: '10px'}}/>
        }
        <ListItemText primary={
          <Typography variant="subtitle2">
            {consolidated ?
              t('consolidatedInvoice.summaryIA', {
                  status: removeUnderscores(status?.status),
                  total: formatDecimal(invoiceData?.totalAmount),
                  date: formatDate(status?.timestamp)
                }
              )
              :
              t('invoice.summaryIA', {
                  organizationName: invoiceData.organisation,
                  total: formatDecimal(invoiceData?.totalAmount),
                  date: formatDate(status?.timestamp),
                  invalidStatus: status?.status === INVALID ? '(INVALID)' : ''
                }
              )}
          </Typography>
        }/>
      </ListItem>
    </>
  );
}
