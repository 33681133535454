import {useRef, useEffect} from 'react';

export const deepCompareEquals = (current, ref) => {
  return JSON.stringify(current) === JSON.stringify(ref);
};

const useDeepCompareMemoize = (value) => {
  const ref = useRef();
  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value;
  }
  return ref.current
};

export const useDeepCompareEffect = (callback, dependencies) => {
  useEffect(callback, useDeepCompareMemoize(dependencies))
};
