import {Box, Grid, Paper, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {formatIBAN} from 'shared/utility';

export default function Payment({detail}) {
  const [t] = useTranslation();
  return (
    <div>
      { detail &&
      <Paper elevation={2}>
        <Box p={4}>
          <Grid container justify="center">
            <Grid item sm={12}>
              <Typography variant="h3">{t("invoice.paymentDetails")}</Typography>
            </Grid>
            <Grid item sm={6}>
              <Box my={1}>
                <Typography variant="subtitle1" color="primary">{t("paymentDetails.bankAccount")}</Typography>
                <Typography variant="body1">{formatIBAN(detail.paymentDetails.bankAccount)}</Typography>
              </Box>
            </Grid>
            <Grid item sm={6}>
              <Box my={1}>
                <Typography variant="subtitle1" color="primary">{t("paymentDetails.businessId")}</Typography>
                <Typography variant="body1">{detail.paymentDetails.businessId}</Typography>
              </Box>
            </Grid>
            <Grid item sm={6}>
              <Box my={1}>
                <Typography variant="subtitle1" color="primary">{t("paymentDetails.swift")}</Typography>
                <Typography variant="body1">{detail.paymentDetails.swift}</Typography>
              </Box>
            </Grid>
            <Grid item sm={6}>
              <Box my={1}>
                <Typography variant="subtitle1" color="primary">{t("paymentDetails.vatId")}</Typography>
                <Typography variant="body1">{detail.paymentDetails.vatId}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>}
    </div>
  );
}
