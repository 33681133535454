import {useContext} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {makeStyles, Paper, Box} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import {useTranslation} from 'react-i18next';
import {ValidationContext} from 'shared/useValidation';
import {PaymentDetails, ValidationErrors} from 'shared/FormComponents';
import Select from './Select';
import SelectedDetail from './SelectedDetail';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialogContent-root': {
      padding: '20px 8px 0px 8px',
      '& > *': {
        marginBottom: theme.spacing(3)
      }
    }
  },

}));
export default function AddDetailsDialog({open, onClose, selected, setSelected, reusablePaymentDetails}) {
  const [t] = useTranslation();
  const classes = useStyles();
  const {getActionProps} = useContext(ValidationContext);

  return (
    <Dialog className={classes.root} onClose={onClose} open={open}>
      <DialogTitle disableTypography>
        <Typography variant="h1">{t("invoice.addPaymentDetails")}</Typography>
      </DialogTitle>
      <DialogContent>
        <Select details={reusablePaymentDetails} selected={selected} setSelected={setSelected} />
        {selected === -1 ? <>
          <ValidationErrors/>
          <PaymentDetails/>
        </> : <Paper elevation={2}>
          <Box p={4}>
            <SelectedDetail paymentDetails={reusablePaymentDetails.find(detail => detail.id === selected).paymentDetails} />
          </Box>
        </Paper>
        }
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={() => {
          onClose();
        }}>
          {t('form.cancel')}
        </Button>
        <Button
          color="primary"
          variant="outlined"
          {...getActionProps()}
        >
          {t('paymentDetails.addToInvoice')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
