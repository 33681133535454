import {Button, Grid} from "@material-ui/core";
import {useContext} from "react";
import {useTranslation} from 'react-i18next';
import {ValidationContext} from "shared/useValidation";
import {DRAFT, PUBLISHED} from "shared/Statuses";
import useModal from 'shared/useModal';
import PublishConfirmationDialog from "./PublishConfirmationDialog";

export default function Actions({status, newTariff, validOnlyLater, activeTariff}) {
  const [t] = useTranslation();
  const {open, handleOpen, handleClose} = useModal();
  const {getActionProps, getDeletionConfirmationProp} = useContext(ValidationContext);
  return (
    <>
      <PublishConfirmationDialog onClose={handleClose} open={open}/>
      <Grid item>
        <Button
          color="primary"
          size="large"
          variant="outlined"
          onClick={newTariff}
        >
          {t("tariffs.add")}
        </Button>
      </Grid>
      {activeTariff && ((status === DRAFT) || status === 'NOT_SAVED' ||
        (status === PUBLISHED && validOnlyLater)) &&
      <Grid item>
        <Button
          color="primary"
          size="large"
          variant="outlined"
          {...getDeletionConfirmationProp()}
        >
          {t('tariffs.delete')}
        </Button>
      </Grid>}
      {activeTariff &&
      <Grid item>
        <Button
          color="secondary"
          size="large"
          variant="contained"
          {...getActionProps(status === 'NOT_SAVED' ? DRAFT : status)}
        >
          {t("tariffs.save")}
        </Button>
      </Grid>}
      {activeTariff && status === DRAFT &&
      <Grid item>
        <Button
          color="secondary"
          size="large"
          variant="contained"
          onClick={handleOpen}
        >
          {t("form.savePublish")}
        </Button>
      </Grid>}
    </>
  );
}
