import MomentUtils from "@date-io/moment";
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';

export default function SmallDateInput(props) {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        inputVariant="outlined"
        size="small"
        autoOk
        variant="inline"
        format="DD.MM.YYYY"
        InputLabelProps={{
          shrink: true,
          style: {
            position: 'relative',
            transform: 'unset',
            fontSize: '0.9rem',
            fontWeight: '600',
            letterSpacing: '0.2px',
            lineHeight: '1.3em',
            color: '#0062A3'
          }
        }}
        InputProps={{notched: false}}
        inputProps={{style: {paddingTop: '6px', paddingBottom: '6px'}}}
        {...props}
      />
    </MuiPickersUtilsProvider>

  );
}
