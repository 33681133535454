import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {Authorize} from '@postinumero/authorization';
import {Alert} from '@material-ui/lab';
import Page from '../../Page';
import HasId from './HasId';
import BackButton from 'App/BackButton';
import {useInvoice} from 'api';
import useSnackBar from 'shared/SnackBar/useSnackBar';
import {DRAFT} from "shared/Statuses";

export default function Invoice() {
  const [t] = useTranslation();
  const {id} = useParams();

  const invoice = useInvoice(id);
  const {showError} = useSnackBar();
  if (!invoice) {
    const data = {statusText: "Invoice not found", status: 404}
    showError(data)
  }

  return (
    <Page title={invoice.currentStatus === DRAFT ? t("invoice.editTitle") : t("invoice.title")}>
      <Authorize allow={['Issuer agent', 'Account operator', 'Inspector']}>
        {id ?
          <HasId invoice={invoice}/>
          :
          <>
            <Alert severity="warning">
              {t('invoice.missingInvoiceParam', {id: id})}
            </Alert>
            <BackButton/>
          </>
        }
      </Authorize>
    </Page>
  );
}
