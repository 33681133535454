import {useTranslation} from 'react-i18next';
import {Grid, TextField, Button} from '@material-ui/core';
import MultiSelect from "shared/FormComponents/MultiSelect";
import {DateInput} from 'shared/FormComponents';
import {
  DRAFT, READYTOSEND, SENDINGREQUESTED, SENDINGFAILED, INVOICESENT,
  MARKEDPAID, PAID, DONE, INVALID
} from 'shared/Statuses';
import {Authorize, useAuthorize} from '@postinumero/authorization';

export default function Filters({activeFilters, setFilters, reset}) {
  const [t] = useTranslation();
  const handleValueChange = (value, key) => {
    setFilters({...activeFilters, [key]: value});
  }
  const statuses = [DRAFT, READYTOSEND, SENDINGREQUESTED, SENDINGFAILED, INVOICESENT, MARKEDPAID, PAID, INVALID, DONE]
  if (useAuthorize({allow: ['Account operator']})) {
    statuses.splice(statuses.length - 1, 1)
  }
  const types = ["Own_commission_fee_invoices", "Received_commission_fee_invoices", "Own_consolidated_invoices"]
  if (useAuthorize({allow: ['Issuer agent']})) {
    types.splice(0, 1)
  }

  return (
    <Grid container spacing={3} alignItems="flex-end">
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <MultiSelect
          valueContainer={activeFilters.statuses}
          name={"statuses"}
          setValue={handleValueChange}
          options={statuses}
          label={t("filter.status")}
        />
      </Grid>
      <Authorize allow={['Issuer agent', 'Inspector']}>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <MultiSelect
            valueContainer={activeFilters.types}
            name={"types"}
            setValue={handleValueChange}
            options={types}
            label={t("filter.type")}
          />
        </Grid>
      </Authorize>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <TextField
          value={activeFilters.search}
          variant="outlined"
          fullWidth
          onChange={(event) => handleValueChange(event.target.value, 'search')}
          label={t("filter.search")}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <DateInput
          value={activeFilters.startDate}
          maxDate={activeFilters.endDate ?? new Date('2100-01-01')}
          onChange={(event) => handleValueChange(event, 'startDate')}
          label={t("filter.startDate")}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <DateInput
          value={activeFilters.endDate}
          minDate={activeFilters.startDate ?? new Date('1900-01-01')}
          onChange={(event) => handleValueChange(event, 'endDate')}
          label={t("filter.endDate")}
        />
      </Grid>
      <Grid item>
        <Button
          size="large"
          variant="outlined"
          color="primary"
          onClick={reset}>
          {t("filter.reset")}
        </Button>
      </Grid>
    </Grid>
  );
}
