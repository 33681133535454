import {useState} from 'react';
import {useTranslation} from "react-i18next";
import DetailsDialog from "./DetailsDialog";
import Details from "./Details";
import useModal from 'shared/useModal';
import {ValidationProvider} from 'shared/useValidation';
import {useReusablePayerDetails} from 'api';

export default function PayerData({payer, setInvoice, disableInputs}) {
  const [t] = useTranslation();
  const {open, handleOpen, handleClose} = useModal();

  const reusablePayerDetails = useReusablePayerDetails("Consolidated")
  const sortedPayerDetails = reusablePayerDetails.sort((a, b) => a.dataName.localeCompare(b.dataName));
  const [selected, setSelected] = useState(sortedPayerDetails.length ? sortedPayerDetails[0].id : -1)
  const details = [{ id: -1, dataName: t("invoice.manually")}].concat(sortedPayerDetails)

  const config = (fields) => ({
    inputs: {
      payerName: {
        isRequired: {message: t("validation.isRequired", {name: t("payer.payerName")})},
        initialValue: payer?.payerName ?? ''
      },
      payerNameSupplement: {
        initialValue: payer?.payerNameSupplement ?? ''
      },
      spareLine: {
        initialValue: payer?.spareLine ?? ''
      },
      deliveryAddress: {
        isRequired: {message: t("validation.isRequired", {name: t("deliveryDetails.deliveryAddress")})},
        initialValue: payer?.deliveryAddress ?? ''
      },
      deliveryAddressSecondLine: {
        initialValue: payer?.deliveryAddressSecondLine ?? ''
      },
      postalCode: {
        isRequired: {message: t("validation.isRequired", {name: t("deliveryDetails.postalCode")})},
        isValidFinnishPostalCode: {
          value: fields.country,
          message: t("validation.isValidFinnishPostalCode", {name: t("deliveryDetails.postalCode")})
        },
        initialValue: payer?.postalCode ?? ''
      },
      city: {
        isRequired: {message: t("validation.isRequired", {name: t("deliveryDetails.city")})},
        initialValue: payer?.city ?? ''
      },
      country: {
        initialValue: payer?.country ?? ''
      },
      email: {
        isRequired: {message: t("validation.isRequired", {name: t("deliveryDetails.email")})},
        isEmail: {message: t("validation.isEmail", {name: t("deliveryDetails.email")})},
        initialValue: payer?.email ?? ''
      },
      electronicInvoiceAddress: {
        initialValue: payer?.electronicInvoiceAddress ?? ''
      },
      electronicInvoiceIntermediator: {
        initialValue: payer?.electronicInvoiceIntermediator ?? ''
      }
    },
    onClick: (state) => {
      if (selected > -1) {
        const detail = reusablePayerDetails.find(detail => detail.id === selected)
        setInvoice(detail.payerDetails, "payerData")
        handleClose();
      } else if (!state.errors || Object.keys(state.errors).length === 0) {
        setInvoice(state.values, "payerData");
        handleClose();
      }
    }
  });
  return (
    <ValidationProvider config={config}>
      {!disableInputs && <DetailsDialog
        onClose={handleClose}
        open={open}
        reusablePayerDetails={details}
        selected={selected}
        setSelected={setSelected}
      />}
      <Details payer={payer} openDialog={handleOpen} disableInputs={disableInputs}/>
    </ValidationProvider>
  );
}
