import {useContext} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {makeStyles, Box, Paper} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import {ValidationContext} from 'shared/useValidation';
import {useTranslation} from 'react-i18next';
import Form from './Form';
import {ValidationErrors} from 'shared/FormComponents';
import Select from './Select';
import Beneficiary from './Beneficiary';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialogContent-root': {
      padding: '20px 24px',

    },
    '& .MuiDialogActions-root': {
      paddingLeft: '24px',
      paddingRight: '24px'
    }
  },

}));

export default function AddDetailsDialog({open, onClose, selected, setSelected, beneficiaries}) {
  const [t] = useTranslation();
  const classes = useStyles();
  const {getActionProps} = useContext(ValidationContext);

  return (
    <Dialog className={classes.root} onClose={onClose} open={open}>
      <DialogTitle disableTypography>
        <Typography variant="h1">{t("invoice.addBeneficiary")}</Typography>
      </DialogTitle>
      <DialogContent>
        <Select reusableBeneficiaries={beneficiaries} selected={selected} setSelected={setSelected} />
        {selected === -1 ? <>
          <ValidationErrors/>
          <Form/>
        </> : <Paper elevation={2}>
          <Box p={4}>
            <Typography variant="h6">{t("reusables.beneficiaryName")}</Typography>
            <Beneficiary beneficiary={beneficiaries.find(beneficiary => beneficiary.id === selected).beneficiaryData} />
          </Box>
        </Paper>
        }
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={() => {
          onClose();
        }}>
          {t('form.cancel')}
        </Button>
        <Button
          color="primary"
          variant="outlined"
          {...getActionProps()}
        >
          {t('paymentDetails.addToInvoice')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
