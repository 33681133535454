import {useCorporateActions} from 'api';
import Layout from './Layout';
import {useAuthorize, useUser} from '@postinumero/authorization';

const getInvoicesFromInvoicingPeriod = (invoicingPeriod, corporateAction) => {
  const {invoices, consolidatedInvoices} = invoicingPeriod;
  return {
    invoices: invoices.map(invoice => {
      return {...invoice, corporateAction, invoicingPeriod}
    }),
    consolidatedInvoices: consolidatedInvoices.map(invoice => {
      return {...invoice, corporateAction, invoicingPeriod}
    })
  }
};

const getinvoicesFromCorporateAction = (corporateAction) => {
  const {invoicingPeriods, statuses, ...strippedCorporateAction} = corporateAction;
  return invoicingPeriods.reduce((obj, invoicingPeriod) => {
    const {invoices, consolidatedInvoices} = getInvoicesFromInvoicingPeriod(invoicingPeriod, strippedCorporateAction);
    return {
      invoices: obj.invoices ? obj.invoices.concat(invoices) : invoices,
      consolidatedInvoices: obj.consolidatedInvoices ? obj.consolidatedInvoices.concat(consolidatedInvoices) : consolidatedInvoices
    }
  }, {})
};

export default function HasInvoices() {
  const corporateActions = useCorporateActions();
  const {invoices, consolidatedInvoices} = corporateActions.reduce((accumulator, currentValue) => {
    const {invoices, consolidatedInvoices} = getinvoicesFromCorporateAction(currentValue);
    return {
      invoices: accumulator.invoices ? accumulator.invoices.concat(invoices) : invoices,
      consolidatedInvoices: accumulator.consolidatedInvoices ? accumulator.consolidatedInvoices.concat(consolidatedInvoices) : consolidatedInvoices
    }
  }, {});

  const user = useUser();
  const isIA = useAuthorize({allow: ['Issuer agent']});

  const filteredInvoices = isIA && invoices?.length ? invoices.filter(invoice => invoice && (invoice.organisation !== user.groups || invoice.corporateAction.organisation === user.groups)) : invoices;

  return (
    <>
      <Layout invoices={filteredInvoices?.filter(Boolean) || []}
              consolidatedInvoices={consolidatedInvoices?.filter(Boolean) || []}/>
    </>
  );
}
