import {Typography} from '@material-ui/core';

export default function PayerDetails({payerDetails}) {
  return (
    <>
      <Typography variant="body1">{payerDetails.payerName}</Typography>
      <Typography variant="body1">{payerDetails.payerNameSupplement}</Typography>
      <Typography variant="body1">{payerDetails.spareLine}</Typography>
      <Typography variant="body1">{payerDetails.deliveryAddress}</Typography>
      <Typography variant="body1">{payerDetails.deliveryAddressSecondLine}</Typography>
      <Typography variant="body1">{payerDetails.postalCode} {payerDetails.city}</Typography>
      <Typography variant="body1">{payerDetails.country}</Typography>
      <Typography variant="body1">{payerDetails.email}</Typography>
      {payerDetails.electronicInvoiceIntermediator && <>
        <Typography color="primary" variant="subtitle1" style={{marginTop: '10px'}}>E-invoice intermediator</Typography>
        <Typography variant="body1">{payerDetails.electronicInvoiceIntermediator}</Typography>
      </>}
      {payerDetails.electronicInvoiceAddress && <>
        <Typography color="primary" variant="subtitle1" style={{marginTop: '10px'}}>E-invoice address</Typography>
        <Typography variant="body1">{payerDetails.electronicInvoiceAddress}</Typography>
      </>}
    </>
  )
}
