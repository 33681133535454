import React from 'react';
import {Grid} from '@material-ui/core';
import {useCA, useInvoicingPeriod} from 'api';
import CorporateActionDetails from './CorporateActionDetails';
import ConsolidatedInvoiceDetails from './ConsolidatedInvoiceDetails';
import PaymentTable from './PaymentTable';
import Actions from "./Actions";

export default function Layout({consolidatedInvoice}) {

  const invoicingPeriod = useInvoicingPeriod(consolidatedInvoice.invoicingPeriodId);
  const corporateAction = useCA(invoicingPeriod.corporateActionEventId);

  return (
    <Grid container item spacing={8} xs={12}>
      <Grid container item xs={12}>
        <Grid container item xs={4}>
          <ConsolidatedInvoiceDetails
            consolidatedInvoice={consolidatedInvoice}
          />
        </Grid>
        <Grid container item xs={8}>
          <CorporateActionDetails
            corporateAction={corporateAction}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <PaymentTable
          invoices={consolidatedInvoice.invoices}
        />
      </Grid>
      <Grid className={"no-print"} item xs={12} container justify="flex-end" spacing={2}>
        <Actions
          id={consolidatedInvoice.id}
        />
      </Grid>
    </Grid>
  );
}
