import {useState, useCallback} from 'react';

export default function useMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  return {
    anchorEl,
    handleOpen: useCallback(
      ({currentTarget}) => setAnchorEl(currentTarget),
      []
    ),
    handleClose: useCallback(() => setAnchorEl(null), [])
  };
}
