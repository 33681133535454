import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Element from './Element';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiButton-root': {
      margin: theme.spacing(2),
    },
  },
}));
export default function History({open, onClose, data}) {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <Dialog className={classes.root} onClose={onClose} open={open}>
      <DialogTitle>
        {t('status.historyTitle')}
      </DialogTitle>
      <DialogContent>
        {Array.isArray(data) && data?.map((stat) => {
          return <Element key={stat.id} status={stat}/>
        })}
      </DialogContent>
      <DialogActions>

        <Button
          variant="outlined"
          size="large"
          color="primary"
          onClick={onClose}
        >
          {t('status.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
