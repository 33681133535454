import {invoice} from 'App/Pages';
import {useTranslation} from 'react-i18next';
import CallMadeIcon from '@material-ui/icons/CallMade';
import {generatePath, Link as ReactRouterLink} from 'react-router-dom';
import {ListItemText, ListItem, Link, Typography} from '@material-ui/core';
import {formatDate, removeUnderscores, formatDecimal} from 'shared/utility';

export default function InvoiceAO({invoiceData}) {
  const [t] = useTranslation();
  const status = invoiceData?.statuses[0];

  const RouterLink = (props) => {
    return <Link component={ReactRouterLink} {...props}/>
  }

  return (
    <>
      <ListItem dense component={RouterLink} to={generatePath(invoice, {id: invoiceData?.id})}>
        <CallMadeIcon color="primary" style={{marginRight: '10px'}}/>
        <ListItemText primary={
          <Typography variant="subtitle2">
            {t('invoice.summaryAO', {
                status: removeUnderscores(status?.status),
                total: formatDecimal(invoiceData?.totalAmount),
                date: formatDate(status?.timestamp)
              }
            )}
          </Typography>
        }/>
      </ListItem>
    </>
  );
}
