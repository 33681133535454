import {useEffect} from 'react';
import {useSetUser} from '@postinumero/authorization';
import {useKeycloak} from '@react-keycloak/web';
import localStorageJson from '@postinumero/storage/localStorageJson';
import useLocalStorageJsonValue from "@postinumero/storage/useLocalStorageJsonValue";


export default () => {
  const {keycloak} = useKeycloak();
  const setUser = useSetUser();
  const roleInfo = useLocalStorageJsonValue('roleInfo') ?? null;

  useEffect(() => {
    if (keycloak.authenticated) {
      const allowedRoles = keycloak.tokenParsed.resource_access[process.env.KEYCLOAK_CLIENT_ID]?.roles;
      const selectedRole = roleInfo?.selected ? allowedRoles?.filter(item => roleInfo.selected === item) : allowedRoles?.length === 1 ? allowedRoles : [];
      const user = {
        name:
          keycloak.tokenParsed.name ?? keycloak.tokenParsed.preferred_username,
        roles:
        selectedRole,
        groups:
        keycloak.tokenParsed?.organisation
      };
      localStorageJson.setItem('roleInfo', {...roleInfo, options: allowedRoles});
      setUser(user);
    } else {
      localStorage.clear();
      setUser(null);
    }

  }, [setUser, keycloak, roleInfo]);
};
