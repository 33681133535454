import Keycloak from 'keycloak-js';
import {KeycloakProvider} from '@react-keycloak/web';
import Spinner from 'App/Spinner';
import HandleRefresh from './HandleRefresh';

//TODO: better fallback, possibly dynamic mapping

export const tempRealmMapping = [
  {realm: "nordnet-alfa-test", domain: "alfa-nordnet-test.visma-dev.fi"},
  {realm: "nordnet-beta-test", domain: "beta-nordnet-test.visma-dev.fi"},
  {realm: "nordnet-gamma-test", domain: "gamma-nordnet-test.visma-dev.fi"},

  {realm: "fees-evli-qa", domain: "evli.qa.fees.fi"},
  {realm: "fees-nordnet-qa", domain: "nordnet.qa.fees.fi"},
  {realm: "fees-handelsbanken-qa", domain: "handelsbanken.qa.fees.fi"},
  {realm: "fees-saastopankki-qa", domain: "saastopankki.qa.fees.fi"},
  {realm: "fees-op-qa", domain: "op.qa.fees.fi"},
  {realm: "fees-seb-qa", domain: "seb.qa.fees.fi"},
  {realm: "fees-danskebank-qa", domain: "danskebank.qa.fees.fi"},
  {realm: "fees-nordea-qa", domain: "nordea.qa.fees.fi"},
  {realm: "fees-aktia-qa", domain: "aktia.qa.fees.fi"},
  {realm: "fees-alandsbanken-qa", domain: "alandsbanken.qa.fees.fi"},

  {realm: "fees-evli", domain: "evli.fees.fi"},
  {realm: "fees-nordnet", domain: "nordnet.fees.fi"},
  {realm: "fees-handelsbanken", domain: "handelsbanken.fees.fi"},
  {realm: "fees-saastopankki", domain: "saastopankki.fees.fi"},
  {realm: "fees-op", domain: "op.fees.fi"},
  {realm: "fees-seb", domain: "seb.fees.fi"},
  {realm: "fees-danskebank", domain: "danskebank.fees.fi"},
  {realm: "fees-nordea", domain: "nordea.fees.fi"},
  {realm: "fees-aktia", domain: "aktia.fees.fi"},
  {realm: "fees-alandsbanken", domain: "alandsbanken.fees.fi"},
];
const keycloak = new Keycloak({
  clientId: process.env.KEYCLOAK_CLIENT_ID,
  realm: tempRealmMapping.find(item => item.domain === window.location.hostname)?.realm ?? process.env.KEYCLOAK_REALM,
  url: process.env.KEYCLOAK_URL
});

export default ({children}) => (
  <KeycloakProvider keycloak={keycloak} LoadingComponent={<Spinner/>}>
    <>
      <HandleRefresh/>
      {children}
    </>
  </KeycloakProvider>
);
