import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Grid} from '@material-ui/core';
import {useParams} from 'react-router-dom';
import {useTariffs, removeTariff, postTariff} from 'api';
import Selector from './Selector';
import {useUser} from '@postinumero/authorization';
import Tariff from "./Tariff";
import TariffSearch from "./TariffSearch";
import {PUBLISHED} from 'shared/Statuses';
import {tariffs as tariffPath} from "../index";
import {formatDate, dateIsTodayOrEarlier} from 'shared/utility';
import useSnackBar from 'shared/SnackBar/useSnackBar';

const findActiveTariff = (tariffs, id) => {
  return id ? tariffs.find(tariff => tariff.id === id) : null;
};

const findLatestPublishedTariff = (tariffs, organisation) => {
  const publishedTariffsOfOrganisation = tariffs.filter(tariff =>
    tariff.currentStatus === PUBLISHED && tariff.organisation === organisation);
  return publishedTariffsOfOrganisation.sort((a, b) => (a.validFrom < b.validFrom) ? 1 : ((b.validFrom < a.validFrom) ? -1 : 0))[0];
};

const findNextFreeValidFromDate = (tariffs, organisation) => {
  const dateToCheck = new Date();
  const dateFormat = 'YYYY-MM-DD';
  dateToCheck.setHours(0, 0, 0, 0);
  dateToCheck.setDate(dateToCheck.getDate() + 1);

  const datesInUse = tariffs
    .filter(t => t.organisation === organisation)
    .map(t => formatDate(new Date(t.validFrom), dateFormat));

  if (!Array.isArray(tariffs) || tariffs.length < 1) {
    return formatDate(dateToCheck, dateFormat);
  } else {
    while (true) {
      if (datesInUse.includes(formatDate(dateToCheck, dateFormat))) {
        dateToCheck.setDate(dateToCheck.getDate() + 1)
      } else {
        return formatDate(dateToCheck, dateFormat);
      }
    }
  }
};

const deepCopySimpleObject = (obj) => obj ? JSON.parse(JSON.stringify(obj)) : '';

const getOrganisations = (tariffs) => {
  if (!tariffs) {
    return [];
  }

  const organisations = Array.from(
    tariffs.map(tariff => tariff.organisation)
  );
  return [...new Set(organisations)];
};

const tariffIsValid = (tariff) =>
  tariff.currentStatus === PUBLISHED &&
  !tariff.validUntil &&
  dateIsTodayOrEarlier(tariff.validFrom);

const tariffOrganisationIs = (tariff, org) => tariff.organisation === org;

const filterTariffs = (allTariffs, validityFilter, iaFilter, setFilteredTariffs) => {
  let filtered = [...allTariffs];
  if (validityFilter !== 'all') {
    filtered = filtered.filter(tariff => tariffIsValid(tariff));
  }
  if (iaFilter !== 'all') {
    filtered = filtered.filter(tariff => tariffOrganisationIs(tariff, iaFilter));
  }
  setFilteredTariffs(filtered);
};

const getValidFromList = (tariffs, organisation, activeTariff) => {
  const list = [];
  tariffs.forEach(tariff => {
    if (tariff.validFrom && tariff.organisation === organisation && !list.includes(tariff.validFrom)) {
      list.push(tariff.validFrom);
    }
  });

  const activeValidFromIndex = list.indexOf(activeTariff.validFrom);
  if (activeValidFromIndex !== -1) {
    list.splice(activeValidFromIndex, 1);
  }

  return list;
};

export default function Layout() {
  const history = useHistory();
  const {tariffId} = useParams();
  const organisation = useUser()?.groups;
  const retrievedTariffs = useTariffs();

  const [allTariffs] = useState(retrievedTariffs);
  const [validityFilter, setValidityFilter] = useState('all');
  const [iaFilter, setIAFilter] = useState('all');
  const {showError} = useSnackBar();

  useEffect(() => {
    filterTariffs(allTariffs, validityFilter, iaFilter, setFilteredTariffs)
  }, [validityFilter, iaFilter, allTariffs]);

  const [filteredTariffs, setFilteredTariffs] = useState(allTariffs);

  const setTariff = (id) => {
    setActiveTariffWithDateCheck(findActiveTariff(filteredTariffs, id));
  };

  const [activeTariff, setActiveTariff] = useState(
    tariffId ? allTariffs.find(tariff => tariff.id.toString() === tariffId) ?? '' :
      findLatestPublishedTariff(allTariffs) ?? '');

  const newTariff = () => {
    createNewDraft(allTariffs, organisation);
  };

  const setActiveTariffWithDateCheck = (tariff) => {
    if (tariff.currentStatus === 'DRAFT' && dateIsTodayOrEarlier(tariff.validFrom)) {
      const date = new Date();
      date.setDate(date.getDate() + 1);
      tariff.validFrom = formatDate(date, 'YYYY-MM-DD');
    }

    setActiveTariff(tariff);
  };

  const removeDraft = (id) => {
    if (id === -1) {
      history.push({pathname: tariffPath.replace(":tariffId?", "")});
      window.location.reload();
    } else {
      removeTariff(id).then(() => {
        history.push({pathname: tariffPath.replace(":tariffId?", "")});
        window.location.reload();
      }).catch((err) => {
        showError(err.response)
      });
    }
  };

  const createNewDraft = async (tariffs, organisation) => {
    let newDraft;
    if (tariffs.length < 1 || !findLatestPublishedTariff(tariffs, organisation)) {
      newDraft = {
        id: null,
        organisation: organisation,
        validFrom: findNextFreeValidFromDate(tariffs, organisation),
        validUntil: null,
        commissionFees: [],
        statuses: [],
        currentStatus: "DRAFT"
      };
    } else {
      const draftToCopy = findLatestPublishedTariff(tariffs, organisation);
      newDraft = deepCopySimpleObject(draftToCopy);
    }

    newDraft.id = null;
    newDraft.validFrom = findNextFreeValidFromDate(tariffs, organisation);
    newDraft.validUntil = null;
    newDraft.commissionFees.forEach(fee => {
      fee.id = null;
      if (Array.isArray(fee.options)) {
        fee.options.forEach(opt => opt.id = null)
      }
    });
    newDraft.statuses = [];
    newDraft.currentStatus = "DRAFT";
    await postTariff(newDraft).then((response) => {
      const redirectId = response?.data?.id;
      history.push({pathname: tariffPath.replace(":tariffId?", redirectId)});
      window.location.reload();
    }).catch((err) => {
      showError(err.response)
    });
  };

  return (
    <>
      <TariffSearch
        issuerAgents={getOrganisations(allTariffs)}
        iaFilter={iaFilter}
        validityFilter={validityFilter}
        setValidityFilter={setValidityFilter}
        setIAFilter={setIAFilter}
      />
      <Grid container spacing={5}>
        <Grid item xs={12} md={4} lg={4}>
          <Selector
            tariffs={filteredTariffs}
            setSelected={setTariff}
            selected={activeTariff ? activeTariff.id : ''}
          />
        </Grid>
        <Tariff
          activeTariff={activeTariff}
          newTariff={newTariff}
          removeDraft={removeDraft}
          organisationValidFroms={getValidFromList(allTariffs, organisation, activeTariff)}
        />
      </Grid>
    </>
  );
}
