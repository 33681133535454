import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {Authorize} from '@postinumero/authorization';
import Page from '../../Page';
import Forbidden from '../Forbidden';
import BackButton from 'App/BackButton';
import {Alert} from '@material-ui/lab';
import {useConsolidatedInvoice} from 'api';
import Layout from './Layout';

export default function Payments() {
  const [t] = useTranslation();
  const {id} = useParams();

  const invoice = useConsolidatedInvoice(id);

  return (
    <Page title={t("payments.title")} printTitle={true}>
      <Authorize allow={['Issuer agent', 'Inspector']} fallback={<Forbidden/>}>
        {invoice ?
          <Layout
            consolidatedInvoice={invoice}
          />
          :
          <>
            <Alert severity="warning">
              {t('invoice.missingInvoiceParam', {id: id})}
            </Alert>
            <BackButton/>
          </>
        }
      </Authorize>
    </Page>
  );
}
