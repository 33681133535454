import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

export default function Detail({label, data, cols}) {
  return (
    <Grid item xs={cols}>
      {data &&
      <>
        <Typography variant="subtitle1" color="primary">{label}</Typography>
        <Typography variant="body1">{data}</Typography>
      </>
      }
    </Grid>
  );
}
