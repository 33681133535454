import React, {useState} from 'react';
import {Grid} from '@material-ui/core';
import {useNotifications, useCorporateActions, deleteNotification} from 'api';
import {
  INVOICINGENABLED,
  READYTOSEND,
  MARKEDPAID,
  SENDINGFAILED,
  LOCKED,
  READYTOPUBLISH,
  INVOICESENT
} from 'shared/Statuses';
import useSnackBar from 'shared/SnackBar/useSnackBar';
import NotificationBox from "./NotificationBox";
import {Authorize} from '@postinumero/authorization';

const processNotifications = (notifications, corporateActions) => {
  const processed = [];
  notifications.forEach(notification => {
    const digest = createDigest(notification, corporateActions);
    processed.push({
      ...digest,
      notification: notification
    });
  });
  return processed;
};

const createDigest = (notification, corporateActions) => {
  if (notification.invoiceId || notification.consolidatedInvoiceId) {
    return notification.invoiceId ?
      findCorporateActionOfInvoice(corporateActions, notification.invoiceId) :
      findCorporateActionOfInvoice(corporateActions, notification.consolidatedInvoiceId, true);
  } else if (notification.invoicingPeriodId) {
    return findInvoicingPeriod(corporateActions, notification.invoicingPeriodId)
  } else if (notification.corporateActionEventId) {
    return findCorporateAction(corporateActions, notification.corporateActionEventId)
  }
};

const findCorporateAction = (corporateActions, id) => {
  for (const corporateAction of corporateActions) {
    if (corporateAction.id === id) {
      return {
        corporateAction: corporateAction
      };
    }
  }
};

const findInvoicingPeriod = (corporateActions, id) => {
  for (const corporateAction of corporateActions) {
    for (const invoicingPeriod of corporateAction.invoicingPeriods) {
      if (invoicingPeriod.id === id) {
        return {
          corporateAction: corporateAction,
          invoicingPeriod: invoicingPeriod
        };
      }
    }
  }
};

const findCorporateActionOfInvoice = (corporateActions, id, isConsolidated = false) => {
  for (const corporateAction of corporateActions) {
    for (const invoicingPeriod of corporateAction.invoicingPeriods) {
      for (const invoice of isConsolidated ? invoicingPeriod.consolidatedInvoices : invoicingPeriod.invoices) {
        if (invoice.id === id) {
          return {
            corporateAction: corporateAction,
            invoicingPeriod: invoicingPeriod,
            invoice: isConsolidated ? null : invoice,
            consolidatedInvoice: isConsolidated ? invoice : null
          };
        }
      }
    }
  }
};

export default function Layout() {
  const [notifications, setNotifications] = useState(useNotifications());
  const [corporateActions] = useState(useCorporateActions());
  const [processedNotifications, setProcessedNotifications] = useState(processNotifications(notifications, corporateActions));

  const {showError} = useSnackBar();

  const removeNotification = (id) => {
    deleteNotification(id).then(() => {
      const newNotifications = notifications;
      const index = newNotifications.indexOf(newNotifications.filter(n => n.id === id)[0]);
      if (index > -1) {
        newNotifications.splice(index, 1);
        setNotifications(newNotifications);
        setProcessedNotifications(processNotifications(notifications, corporateActions));
      }
    }).catch((err) => {
      showError(err.response)
    });
  };

  const filterOutErroneousNotifications = (notifications) => {
    return notifications.filter(n => n.corporateAction && n.invoicingPeriod);
  };

  const readyForInvoicing = filterOutErroneousNotifications(processedNotifications.filter(p =>
    p.notification.type === 'StatusChanged' && p.notification.statusOption === INVOICINGENABLED));
  const lockedDateChanged = filterOutErroneousNotifications(processedNotifications
    .filter(p => p.notification.type === 'LockedDateChanged'));
  const readyToSend = filterOutErroneousNotifications(processedNotifications.filter(p =>
    p.notification.type === 'StatusChanged' && p.notification.statusOption === READYTOSEND && p.notification.invoiceId));
  const markedPaid = filterOutErroneousNotifications(processedNotifications.filter(p =>
    p.notification.type === 'StatusChanged' && p.notification.statusOption === MARKEDPAID));
  const overDue = filterOutErroneousNotifications(processedNotifications
    .filter(p => p.notification.type === 'PaymentOverDue' && p.notification.invoiceId));
  const sendingFailed = filterOutErroneousNotifications(processedNotifications.filter(p =>
    p.notification.type === 'StatusChanged' && p.notification.statusOption === SENDINGFAILED && p.notification.invoiceId));
  const reSending = filterOutErroneousNotifications(processedNotifications
    .filter(p => p.notification.type === 'MaventaSendFailed' && p.notification.invoiceId))

  const readyToPublish = filterOutErroneousNotifications(processedNotifications.filter(p =>
    p.notification.type === 'StatusChanged' && p.notification.statusOption === READYTOPUBLISH &&
    p.notification.invoicingPeriodId));
  const locked = filterOutErroneousNotifications(processedNotifications.filter(p =>
    p.notification.type === 'StatusChanged' && p.notification.statusOption === LOCKED));
  const invoiceSent = filterOutErroneousNotifications(processedNotifications.filter(p =>
    p.notification.type === 'StatusChanged' && p.notification.statusOption === INVOICESENT));
  const readyToSendConsolidated = filterOutErroneousNotifications(processedNotifications.filter(p =>
    p.notification.type === 'StatusChanged' && p.notification.statusOption === READYTOSEND &&
    p.notification.consolidatedInvoiceId));
  const overDueConsolidated = filterOutErroneousNotifications(processedNotifications
    .filter(p => p.notification.type === 'PaymentOverDue' &&
    p.notification.consolidatedInvoiceId));
  const sendingFailedConsolidated = filterOutErroneousNotifications(processedNotifications.filter(p =>
    p.notification.type === 'StatusChanged' && p.notification.statusOption === SENDINGFAILED &&
    p.notification.consolidatedInvoiceId));
  const reSendingConsolidated = filterOutErroneousNotifications(processedNotifications
    .filter(p => p.notification.type === 'MaventaSendFailed' && p.notification.consolidatedInvoiceId))

  return (
    <Grid container item xs={12} alignItems="flex-start">
      <Authorize allow={['Account operator']} fallback={null}>
        <Grid container item xs={6} spacing={2}>
          <Grid item xs={12}>
            <NotificationBox
              title={'notifications.readyForInvoicing'}
              notifications={readyForInvoicing}
              subString={'invoicingPeriod'}
              removeNotification={removeNotification}
              icon={'assignment'}
            />
          </Grid>
          <Grid item xs={12}>
            <NotificationBox
              title={'notifications.lockedDateChanged'}
              notifications={lockedDateChanged}
              subString={'invoicingPeriod'}
              removeNotification={removeNotification}
              icon={'assignment'}
            />
          </Grid>
        </Grid>
        <Grid container item xs={6} spacing={2}>
          <Grid item xs={12}>
            <NotificationBox
              title={'notifications.readyToSend'}
              notifications={readyToSend}
              subString={'invoiceTo'}
              removeNotification={removeNotification}
              icon={'callMade'}
            />
          </Grid>
          <Grid item xs={12}>
            <NotificationBox
              title={'notifications.markedPaid'}
              notifications={markedPaid}
              subString={'invoiceTo'}
              removeNotification={removeNotification}
              icon={'callMade'}
            />
          </Grid>
          <Grid item xs={12}>
            <NotificationBox
              title={'notifications.overDue'}
              notifications={overDue}
              subString={'invoice'}
              removeNotification={removeNotification}
              icon={'callMade'}
            />
          </Grid>
          <Grid item xs={12}>
            <NotificationBox
              title={'notifications.sendingFailed'}
              notifications={sendingFailed}
              subString={'invoiceTo'}
              removeNotification={removeNotification}
              icon={'callMade'}
            />
          </Grid>
          <Grid item xs={12}>
            <NotificationBox
              title={'notifications.reSending'}
              notifications={reSending}
              subString={'invoiceTo'}
              removeNotification={removeNotification}
              icon={'callMade'}
            />
          </Grid>
        </Grid>
      </Authorize>

      <Authorize allow={['Issuer agent']} fallback={null}>
        <Grid container item xs={6} spacing={2}>
          <Grid item xs={12}>
            <NotificationBox
              title={'notifications.readyToPublish'}
              notifications={readyToPublish}
              subString={'invoicingPeriod'}
              removeNotification={removeNotification}
              icon={'assignment'}
            />
          </Grid>
          {Array.isArray(locked) && locked.length > 0 &&
          <Grid item xs={12}>
            <NotificationBox
              title={'notifications.locked'}
              notifications={locked}
              subString={'invoicingPeriod'}
              removeNotification={removeNotification}
              icon={'assignment'}
            />
          </Grid>}
        </Grid>
        <Grid container item xs={6} spacing={2}>
          <Grid item xs={12}>
            <NotificationBox
              title={'notifications.invoiceSent'}
              notifications={invoiceSent}
              subString={'invoiceFrom'}
              removeNotification={removeNotification}
              icon={'callReceived'}
            />
          </Grid>
          <Grid item xs={12}>
            <NotificationBox
              title={'notifications.readyToSendConsolidated'}
              notifications={readyToSendConsolidated}
              subString={'consolidated'}
              removeNotification={removeNotification}
              icon={'callMerge'}
            />
          </Grid>
          <Grid item xs={12}>
            <NotificationBox
              title={'notifications.overDueConsolidated'}
              notifications={overDueConsolidated}
              subString={'consolidated'}
              removeNotification={removeNotification}
              icon={'callMerge'}
            />
          </Grid>
          <Grid item xs={12}>
            <NotificationBox
              title={'notifications.sendingFailed'}
              notifications={sendingFailedConsolidated}
              subString={'consolidated'}
              removeNotification={removeNotification}
              icon={'callMerge'}
            />
          </Grid>
          <Grid item xs={12}>
            <NotificationBox
              title={'notifications.reSending'}
              notifications={reSendingConsolidated}
              subString={'consolidated'}
              removeNotification={removeNotification}
              icon={'callMerge'}
            />
          </Grid>
        </Grid>
      </Authorize>
    </Grid>
  );
}
