const validationReducer = (state, action) => {
  switch (action.type) {
    case 'change':
      const values = {...state.values, ...action.payload};
      return {
        ...state,
        values,
      };
    case 'submit':
      return {...state, submitted: true};
    case 'validate':
      return {...state, errors: action.payload};
    default:
      throw new Error('Unknown action type');
  }
}
export default validationReducer;
