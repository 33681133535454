import {useCA, useInvoicingPeriod} from 'api';
import Form from './Form';

export default function HasRelatedData({invoicingPeriodId, invoice}) {
  const invoicingPeriod = useInvoicingPeriod(invoicingPeriodId);
  const corporateAction = useCA(invoicingPeriod.corporateActionEventId);
  return (
    <Form invoice={invoice} invoicingPeriod={invoicingPeriod} corporateAction={corporateAction}/>
  );
}
