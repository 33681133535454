import {Typography, Button} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import PayerDetails from "shared/Components/PayerDetails";

export default function Details({openDialog, payer, disableInputs}) {
  const [t] = useTranslation();
  return (
    <>
      <Typography variant="h3">
        {t("consolidatedInvoice.payer")}
        {!disableInputs &&
        <Button component="div" onClick={openDialog}>
          <Typography color="primary" variant="overline">
            {!payer ?
              t("consolidatedInvoice.addPayer") :
              t("consolidatedInvoice.editPayer")
            }
          </Typography>
        </Button>}
      </Typography>
      {payer && <PayerDetails payerDetails={payer} />}
    </>
  );
}
