import {Grid} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import useModal from 'shared/useModal';
import {postTariff, putTariff} from 'api';
import Status from "../../CorporateAction/Status";
import FeeTable from "./FeeTable";
import Actions from "./Actions";
import ValidFrom from "./ValidFrom";
import {ValidationProvider} from 'shared/useValidation';
import {ValidationErrors, DeletionConfirmationDialog} from 'shared/FormComponents';
import React from "react";
import {tariffs} from "../../index";
import {dateIsLaterThanToday} from "../../../../shared/utility";
import {Alert} from '@material-ui/lab';
import {Authorize} from '@postinumero/authorization';
import useSnackBar from 'shared/SnackBar/useSnackBar';

const resolveStatus = (tariff) => {
  return tariff?.statuses?.length > 0 ? tariff?.statuses[0].status : 'NOT_SAVED';
};

export default function Tariff({activeTariff, newTariff, removeDraft, organisationValidFroms}) {
  const [t] = useTranslation();
  const history = useHistory();
  const {open, handleOpen, handleClose} = useModal();
  const saveData = activeTariff?.id === -1 ? postTariff : putTariff;
  const {showError} = useSnackBar();

  const config = () => ({
    inputs: {
      validFrom: {
        isRequired: {message: t('validation.isRequired', {name: t('tariffs.fromDate')})},
        isValidDate: {message: t('validation.isValidDate', {name: t('tariffs.fromDate')})},
        isLaterThanToday: {message: t('validation.isLaterThanToday', {name: t('tariffs.fromDate')})},
        initialValue: activeTariff ? activeTariff.validFrom : null
      }
    },
    onClick: async (state, status) => {
      if (!state.errors || Object.keys(state.errors).length === 0) {
        const tariffToSave = {
          ...state.values,
          id: activeTariff.id === -1 ? null : activeTariff.id,
          organisation: activeTariff.organisation,
          validUntil: activeTariff.validUntil,
          commissionFees: activeTariff.commissionFees,
          statuses: activeTariff.statuses,
          currentStatus: status
        };

        await saveData(tariffToSave).then((response) => {
          const redirectId = response?.data?.id;
          history.push({pathname: tariffs.replace(":tariffId?", redirectId)});
          window.location.reload();
        }).catch((err) => {
          showError(err.response)
        });
      }
    },
    deletionConfirmationFunction: handleOpen,
    deleteFunction: () => {
      removeDraft(activeTariff.id);
    }
  });

  return (
    <>
      <ValidationProvider config={config}>
        <DeletionConfirmationDialog onClose={handleClose} open={open} itemName={t('tariffs.item')}/>
        <Grid item xs={12} md={2} lg={2}>
          <ValidFrom
            externalValue={activeTariff?.validFrom}
            status={resolveStatus(activeTariff)}
            organisationValidFroms={organisationValidFroms}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Status
            statuses={activeTariff && activeTariff.statuses}
            relativePositioning={true}
          />
        </Grid>
        <Grid item lg={12} md={12}>
          <ValidationErrors/>
        </Grid>
        <Grid item xs={12}>
          {activeTariff ?
            <FeeTable
              activeTariff={activeTariff}
            /> :
            <Alert severity="warning">
              {t("tariffs.noSelected")}
            </Alert>
          }
        </Grid>
        <Authorize allow={'Administrator'}>
          <Grid className={"no-print"} item xs={12} container justify="flex-end" spacing={3}>
            <Actions status={resolveStatus(activeTariff)}
                     newTariff={newTariff}
                     validOnlyLater={dateIsLaterThanToday(activeTariff?.validFrom)}
                     activeTariff={activeTariff}
            />
          </Grid>
        </Authorize>
      </ValidationProvider>
    </>
  );
}
