import Grid from '@material-ui/core/Grid';
import {useTranslation} from 'react-i18next';
import Status from '../Status';
import Actions from './Actions';
import {postCorporateAction, putCorporateAction, useCorporateActionEventTypes, removeCorporateAction} from 'api';
import {useHistory} from 'react-router-dom';
import {corporateActions} from 'App/Pages';
import Fields from './Fields';
import {ValidationProvider} from 'shared/useValidation';
import {ValidationErrors, DeletionConfirmationDialog} from 'shared/FormComponents';
import {formatDate} from 'shared/utility';
import useModal from 'shared/useModal';
import useSnackBar from 'shared/SnackBar/useSnackBar';
import {DRAFT, READYTOPUBLISH} from "../../../../shared/Statuses";

export default function Form({corporateAction}) {
  const [t] = useTranslation();
  const history = useHistory();
  const {open, handleOpen, handleClose} = useModal();
  const corporateActionEventTypes = useCorporateActionEventTypes();
  const saveFormData = corporateAction ? putCorporateAction : postCorporateAction;
  const {showError} = useSnackBar();

  const config = fields => ({
    inputs: {
      issuer: {
        isRequired: {message: t('validation.isRequired', {name: t('event.issuer')})},
        initialValue: corporateAction ? corporateAction.issuer : ''
      },
      issuerBusinessId: {
        isRequired: {message: t('validation.isRequired', {name: t('event.issuerBusinessId')})},
        initialValue: corporateAction ? corporateAction.issuerBusinessId : ''
      },
      issuerISIN: {
        initialValue: corporateAction ? corporateAction.issuerISIN : ''
      },
      instructionsForAO: {
        initialValue: corporateAction ? corporateAction.instructionsForAO : ''
      },
      eventClassification: {
        isRequired: {message: t('validation.isRequired', {name: t('event.eventClassification')})},
        initialValue: corporateAction ? corporateAction.eventClassification : ''
      },
      actionEventType: {
        isRequired: {message: t('validation.isRequired', {name: t('event.actionEventType')})},
        initialValue: corporateAction ? corporateAction.actionEventType.id : ''
      },
      eventId: {
        initialValue: corporateAction?.eventId || '',
      },
      recordDate: {
        isRequired: {message: t('validation.isRequired', {name: t('event.recordDate')})},
        isValidDate: {message: t('validation.isValidDate', {name: t('event.recordDate')})},
        initialValue: corporateAction ? corporateAction.recordDate : null
      },
      paymentDate: {
        isRequiredIf: {
          isEqualTo: 'MANDATORY',
          value: fields.eventClassification,
          message: t('validation.isRequired', {name: t('event.paymentDate')})
        },
        isValidDate: {message: t('validation.isValidDate', {name: t('event.paymentDate')})},
        isNotBeforeDate: {
          value: fields.recordDate,
          message: t('validation.isNotBeforeDate', {
            name: t('event.paymentDate'),
            compareVal: formatDate(fields.recordDate)
          })
        },
        initialValue: corporateAction?.paymentDate || null
      }
    },
    onClick: async (state, status) => {
      if (!state.errors || Object.keys(state.errors).length === 0) {
        const finalValues = {
          ...state.values,
          actionEventType: corporateActionEventTypes.find(cae => cae.id === state.values.actionEventType)
        };

        const CAEvent = corporateAction ? {
          ...finalValues,
          currentStatus: status,
          id: corporateAction.id
        } : {...finalValues, currentStatus: status};
        await saveFormData(CAEvent).then((response) => {
          const redr = response?.data?.id;
          history.push({pathname: corporateActions, search: '?ca=' + redr});
        }).catch((err) => {
          showError(err.response)
        });
      }
    },
    deletionConfirmationFunction: handleOpen,
    deleteFunction: () => {
      removeCorporateAction(corporateAction.id).then(() => {
        history.push({pathname: corporateActions});
      }).catch((err) => {
        showError(err.response)
      });
    }
  });
  return (
    <>
      <Status statuses={corporateAction && corporateAction.statuses}/>
      <ValidationProvider config={config}>
        <DeletionConfirmationDialog onClose={handleClose} open={open} itemName={t('event.name')}/>
        <Grid container spacing={5} justify="center">
          <Grid item lg={9} md={12}>
            <ValidationErrors/>
          </Grid>
          <Fields
            caeTypes={corporateActionEventTypes}
            disableInputs={corporateAction && corporateAction.currentStatus !== DRAFT && corporateAction.currentStatus !== READYTOPUBLISH}
          />
          <Actions status={corporateAction?.currentStatus}/>
        </Grid>
      </ValidationProvider>
    </>
  );
}
