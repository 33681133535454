import {useTranslation} from 'react-i18next';
import {formatIBAN} from 'shared/utility';

export default function ({type, selected, details}) {
    const [t] = useTranslation();
    const edit = type === 'edit'
    const existing = edit ? details.filter(detail => detail.id !== selected.id) : details
    const inputs = {
        dataName: {
          isRequired: {message: t('validation.isRequired', {name: t('paymentDetails.paymentDetailsName')})},
          isNotDuplicate: {
            message: t('validation.isNotDuplicate', {
              name: t('paymentDetails.paymentDetailsName')
            }),
            existingValues: existing.map(detail => detail.dataName)
          },
          initialValue: edit ? selected?.dataName : ''
        },
        bankAccount: {
          isRequired: {message: t('validation.isRequired', {name: t('paymentDetails.bankAccount')})},
          isValidIBAN: {message: t('validation.isValidIBAN', {name: t('paymentDetails.bankAccount')})},
          initialValue: edit ? selected?.paymentDetails.bankAccount : '',
          mask: formatIBAN
        },
        swift: {
          isRequired: {message: t('validation.isRequired', {name: t('paymentDetails.swift')})},
          isValidBIC: {message: t('validation.isValidBIC', {name: t('paymentDetails.swift')})},
          initialValue: edit ? selected?.paymentDetails.swift : ''
        },
        businessId: {
          isRequired: {message: t('validation.isRequired', {name: t('paymentDetails.businessId')})},
          initialValue: edit ? selected?.paymentDetails.businessId : ''
        },
        vatId: {
          isRequired: {message: t('validation.isRequired', {name: t('paymentDetails.vatId')})},
          initialValue: edit ? selected?.paymentDetails.vatId : ''
        }
      }
      return inputs
}
