import {createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#007ACA',
      main: '#0062A3',
    },
    secondary: {
      main: '#4D8400'
    },
    tertiary: {
      main: '#646464'
    },
    background: {
      default: 'white'
    },
    text: {
      primary: '#282828'
    }

  },
  typography: {
    fontFamily: ['Open Sans', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontSize: '2.6rem',
      fontWeight: '300'
    },
    h2: {
      fontSize: '1.7rem',
      fontWeight: '400'
    },
    h3: {
      fontSize: '1.1rem',
      fontWeight: '600'
    },
    subtitle1: {
      fontSize: '0.9rem',
      fontWeight: '600',
      letterSpacing: '0.2px',
      lineHeight: '1.3em'
    },
    subtitle2: {
      fontSize: '1.1rem',
      fontWeight: '600',
      letterSpacing: '0%'
    },
    caption: {
      fontSize: '0.7em',
      fontWeight: '600',
      letterSpacing: '0.4px',
    },
    body1: {
      letterSpacing: '0.02em'
    },
    body2: {
      fontSize: '1.1rem',
      fontWeight: '400',
      lineHeight: '1.5em'
    },
    button: {
      fontSize: '1.1rem',
      fontWeight: '700'
    },
    overline: {
      textDecoration: 'underline',
      textTransform: 'none',
      fontSize: '0.86rem',
      lineHeight: '1.33em'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        fontSize: '1.1rem',
        textTransform: 'none',
      }
    },
    MuiDialog: {
      paper: {
        padding: '30px 50px'
      }
    },
    MuiDialogTitle: {
      root: {
        textAlign: 'center',
        '& h2': {
          fontSize: '2.6rem',
          fontWeight: '300'
        }
      }
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: '8px'
      }
    },
    MuiRadio: {
      colorSecondary: {
        color: '#64',
        '&$checked': {
          color: '#64',
        },
      }
    },
    MuiFormLabel: {
      root: {
        color: '#0062A3',
        fontSize: '0.9rem',
        fontWeight: '600',
        letterSpacing: '0.2px',
        lineHeight: '1.3em'
      }
    },
    MuiInputLabel: {
      root: {
        color: '#0062A3',
        fontSize: '0.9rem',
        fontWeight: '600',
        letterSpacing: '0.2px',
        lineHeight: '1.3em'
      }
    },
    MuiTableRow: {
      root: {
        "&:last-child td": {
          border: 0,
          borderBottom: 0,
        },
      }
    },
    MuiTableCell: {
      root: {
        paddingLeft: 3,
        paddingRight: 3,
        paddingTop: 6,
        paddingBottom: 6,
      }
    }
  }
});

export default theme;
