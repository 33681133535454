import React from 'react';
import {Grid} from '@material-ui/core';
import Beneficiary from "./Beneficiary";
import Payer from "./Payer";
import Payment from "./Payment";
import {Authorize} from '@postinumero/authorization';

export default function Layout() {
  const savedId = new URLSearchParams(window.location.search).get('id');

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={6}>
          <Beneficiary data={[]} savedId={savedId} />
        </Grid>
        <Grid item xs={6}>
          <Payment data={[]} savedId={savedId} />
        </Grid>
      </Grid>
      <Authorize allow={['Issuer agent']}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <Payer
              data={[]}
              savedId={savedId}
              type={"Commission"}
            />
          </Grid>
          <Grid item xs={6}>
            <Payer
              data={[]}
              savedId={savedId}
              type={"Consolidated"}
            />
          </Grid>
        </Grid>
      </Authorize>
    </Grid>
  );
}
