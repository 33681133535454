import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {Authorize} from '@postinumero/authorization';
import Page from '../../../Page';
import Forbidden from '../../Forbidden';
import BackButton from 'App/BackButton';
import {Alert} from '@material-ui/lab';
import HasRelatedData from '../HasRelatedData';

export default function NewInvoice() {
  const [t] = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const id = params.get('ip');
  return (
    <Page title={t("invoice.addTitle")}>
      <Authorize allow="Account operator" fallback={<Forbidden/>}>
        {id ?
          <HasRelatedData invoicingPeriodId={id}/>
          :
          <>
            <Alert severity="warning">
              {t('invoice.missingInvoicingPeriod', {id: id})}
            </Alert>
            <BackButton/>
          </>
        }
      </Authorize>
    </Page>
  );
}
