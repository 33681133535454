import {forwardRef} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import MUILink from '@material-ui/core/Link';

const AdapterLink = forwardRef((props, ref) => (
  <RouterLink innerRef={ref} {...props} />
));

function Link(props, ref) {
  return <MUILink ref={ref} component={AdapterLink} {...props} />;
}

export default Link |> forwardRef;
