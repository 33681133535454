import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {Authorize} from '@postinumero/authorization';
import Page from '../../../Page';
import Forbidden from 'App/Pages/Forbidden';
import HasId from './HasId';


export default function EditInvoicingPeriod() {
  const [t] = useTranslation();
  const {id} = useParams();

  return (
    <Page title={t("invoicingPeriod.editTitle")}>
      <Authorize allow="Issuer agent" fallback={<Forbidden/>}>
        <HasId id={id}/>
      </Authorize>
    </Page>
  );
}
