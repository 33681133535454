import {Button, Grid} from "@material-ui/core";
import {useTranslation} from 'react-i18next';
import {consolidatedInvoice as consolidatedInvoicePath} from "../index";
import {useHistory} from 'react-router-dom';

export default function Actions({id}) {
  const [t] = useTranslation();
  const history = useHistory();
  return (
    <>
      <Grid item>
        <Button
          onClick={window.print}
          variant="outlined"
          size="large"
          color="primary">
          {t("invoice.print")}
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={() => history.push({pathname: consolidatedInvoicePath.replace(":id?", id)})}
          variant="contained"
          size="large"
          color="secondary">
          {t("form.close")}
        </Button>
      </Grid>
    </>
  );
}
