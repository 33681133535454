import {Box, Grid, Paper, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

export default function Selected({selected}) {
  const [t] = useTranslation();
  return (
    <div>
      { selected &&
      <Paper elevation={2}>
        <Box p={4}>
          <Grid container justify="center">
            <Grid item sm={12}>
              <Box mb={1}>
                <Typography variant="h3">{t("invoice.beneficiary")}</Typography>
              </Box>
            </Grid>
            <Grid item sm={12}>
              <Typography variant="body1">{selected.beneficiaryData.beneficiaryName}</Typography>
              <Typography variant="body1">{selected.beneficiaryData.beneficiaryNameSupplement}</Typography>
              <Typography variant="body1">{selected.beneficiaryData.spareLine}</Typography>
              <Typography variant="body1">{selected.beneficiaryData.deliveryAddress}</Typography>
              <Typography variant="body1">{selected.beneficiaryData.deliveryAddressSecondLine}</Typography>
              <Typography variant="body1">{selected.beneficiaryData.postalCode} {selected.beneficiaryData.city}</Typography>
              <Typography variant="body1">{selected.beneficiaryData.country}</Typography>
              <Typography variant="body1">{selected.beneficiaryData.email}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>}
    </div>
  );
}
