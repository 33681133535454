import {useTranslation} from 'react-i18next';
import {Authorize} from '@postinumero/authorization';
import Page from '../../../Page';
import Form from '../Form';
import Forbidden from '../../Forbidden';

export default function New() {
  const [t] = useTranslation();
  return (
    <Page title={t("event.addTitle")}>
      <Authorize allow="Issuer agent" fallback={<Forbidden/>}>
        <Form/>
      </Authorize>
    </Page>
  );
}
