import {useTranslation} from 'react-i18next';
import {List, Typography, makeStyles} from "@material-ui/core";
import {useUser, useAuthorize} from '@postinumero/authorization';
import InvoiceAO from './InvoiceAO';
import InvoiceIA from './InvoiceIA';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0'
  }
}))

export default function CommissionFeeInvoiceList({invoices, CAOrg}) {
  const [t] = useTranslation();
  const classes = useStyles();
  const userOrganisation = useUser()?.groups;
  const isAllowed = useAuthorize({allow: ['Account operator']});
  const isIA = useAuthorize({allow: ['Issuer agent']});
  const isInspector = useAuthorize({allow: ['Inspector']});
  return (
    <List className={classes.root}>
      {invoices && invoices.length > 0 ?
        invoices?.map((item) => {
          return (
            <li key={item.id}>
              {item?.organisation === userOrganisation && isAllowed ?
                <InvoiceAO key={item.id} invoiceData={item}/> :
                (CAOrg === userOrganisation && isIA) ?
                <InvoiceIA key={item.id} invoiceData={item} rotateIcon={true}/> :
                  isInspector &&
                  <InvoiceIA key={item.id} invoiceData={item} rotateIcon={CAOrg === userOrganisation}/>
              }
            </li>
          );
        })
        :
        <Typography variant="body1">{t('invoice.noinvoices')}</Typography>
      }
    </List>

  );
}
