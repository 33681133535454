import {Typography, Grid, Card, CardContent, CardHeader} from "@material-ui/core";
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import {formatDate, removeUnderscores, formatClassification} from 'shared/utility';
import Detail from "./Detail";

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px 30px'
  }
}));

export default function Details({corporateAction, show}) {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <>
      {(corporateAction && show) &&
      <Card raised elevation={3} className={classes.root}>
        <CardHeader
          avatar={<WorkOutlineOutlinedIcon fontSize="large" color="primary"/>}
          title={<Typography
            variant="h3">{corporateAction.actionEventType.type + " (" + corporateAction.actionEventType.description + "): " + corporateAction.issuer}</Typography>}
        />
        <CardContent>
          <Grid container justify="flex-start" spacing={2}>
            {corporateAction.organisation &&
            <Detail
              label={t("event.issuerAgent")}
              data={corporateAction.organisation}
              cols={6}
            />
            }
            {corporateAction.statuses &&
            <Detail
              label={t("status.title")}
              data={removeUnderscores(corporateAction.statuses[0].status) + ' ' + formatDate(corporateAction.statuses[0].timestamp)}
              cols={6}
            />
            }
            {corporateAction.issuer &&
            <Detail
              label={t("event.issuer")}
              data={corporateAction.issuer}
              cols={6}
            />
            }
            {corporateAction.issuerBusinessId &&
            <Detail
              label={t("event.issuerBusinessId")}
              data={corporateAction.issuerBusinessId}
              cols={6}
            />
            }
            <Detail
              label={t("event.issuerISIN")}
              data={corporateAction?.issuerISIN}
              cols={6}
            />
            <Detail
              label={t("event.eventId")}
              data={corporateAction?.eventId}
              cols={6}
            />
            {corporateAction.actionEventType &&
            <Detail
              label={t("event.actionEventType")}
              data={corporateAction.actionEventType.type + " (" + corporateAction.actionEventType.description + ")"}
              cols={6}
            />
            }
            {corporateAction.eventClassification &&
            <Detail
              label={t("event.eventClassification")}
              data={formatClassification(corporateAction.eventClassification)}
              cols={6}
            />
            }
            {corporateAction.recordDate &&
            <Detail
              label={t("event.recordDate")}
              data={formatDate(corporateAction.recordDate)}
              cols={6}
            />
            }
            {corporateAction.paymentDate &&
            <Detail
              label={t("event.paymentDate")}
              data={formatDate(corporateAction.paymentDate)}
              cols={6}
            />
            }
            {corporateAction.instructionsForAO &&
            <Detail
              label={t("event.instructionsForAO")}
              data={corporateAction.instructionsForAO}
              cols={12}
            />
            }
          </Grid>
        </CardContent>
      </Card>
      }
    </>
  );
}
