import {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {TextField, Grid, Button, Typography} from "@material-ui/core";
import {ValidationContext} from 'shared/useValidation';

export default function Form() {
  const [t] = useTranslation();
  const {getInputProps, values, setValue} = useContext(ValidationContext);

  const [toggles, setToggles] = useState({
    spareLine: values.spareLine !== '',
    deliveryAddressSecondLine: values.deliveryAddressSecondLine !== '',
    country: values.country !== ''
  });
  const onToggle = (input) => {
    setToggles({...toggles, [input]: !toggles[input]});
    setValue(input, '');
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          label={t("paymentDetails.dataName")}
          required
          {...getInputProps("dataName")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          label={t("beneficiary.beneficiaryName")}
          required
          {...getInputProps("beneficiaryName")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          label={t("beneficiary.beneficiaryNameSupplement")}
          helperText={t("beneficiary.nameSupplementHelper")}
          {...getInputProps("beneficiaryNameSupplement")}
        />
      </Grid>
      <Grid item xs={12}>
        <Button onClick={() => onToggle('spareLine')}>
          <Typography variant="overline" color="primary">
            {t("deliveryDetails.sparelineToggle", {action: toggles.spareLine ? 'Remove' : 'Add'})}
          </Typography>
        </Button>
        {toggles.spareLine ?

          <TextField
            variant="outlined"
            fullWidth
            label={t("deliveryDetails.spareline")}
            {...getInputProps('spareLine')}
            helperText={t("deliveryDetails.spareLineHelper")}
          />

          : null}
      </Grid>
      <Grid item sm={12}>
        <TextField
          variant="outlined"
          fullWidth
          required
          label={t("deliveryDetails.deliveryAddress")}
          helperText={t("deliveryDetails.deliveryAddressHelper")}
          {...getInputProps('deliveryAddress')}
        />
      </Grid>
      <Grid item sm={12}>
        <Button onClick={() => onToggle('deliveryAddressSecondLine')}>
          <Typography variant="overline" color="primary">
            {t("deliveryDetails.deliveryAddressSecondLineToggle", {action: toggles.deliveryAddressSecondLine ? 'Remove' : 'Add'})}
          </Typography>
        </Button>
        {toggles.deliveryAddressSecondLine ?
          <TextField
            variant="outlined"
            fullWidth
            label={t("deliveryDetails.deliveryAddressSecondLine")}
            {...getInputProps('deliveryAddressSecondLine')}
          /> : null}
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            fullWidth
            required
            label={t("deliveryDetails.postalCode")}
            {...getInputProps('postalCode')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            fullWidth
            required
            label={t("deliveryDetails.city")}
            {...getInputProps('city')}
          />
        </Grid>
      </Grid>

      <Grid item sm={12}>
        <Button
          onClick={() => onToggle('country')}
        >
          <Typography variant="overline" color="primary">
            {t("deliveryDetails.countryToggle", {action: toggles.country ? 'Remove' : 'Add'})}
          </Typography>
        </Button>
        {toggles.country ?
          <TextField
            variant="outlined"
            fullWidth
            label={t("deliveryDetails.country")}
            {...getInputProps('country')}
          />
          : null}
      </Grid>
      <Grid item sm={12}>
        <TextField
          variant="outlined"
          fullWidth
          required
          label={t("deliveryDetails.email")}
          {...getInputProps('email')}
        />
      </Grid>
    </Grid>
  );
}
