import React, {useState} from 'react';
import {Box, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useReusableBeneficiaries} from 'api';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CallMergeIcon from '@material-ui/icons/CallMerge';
import List from './List';
import Selected from './Selected';
import Actions from './Actions';
import {useUser} from '@postinumero/authorization';

export default function Beneficiary({savedId}) {
  const [t] = useTranslation();
  const [beneficiaries] = useState(useReusableBeneficiaries());
  const saved = savedId ? beneficiaries.find(obj => obj.id === parseInt(savedId)) : null
  const active = saved ? saved : beneficiaries.length ? beneficiaries[0] : ''
  const [activeBeneficiary, setActiveBeneficiary] = useState(active);

  const user = useUser();
  const isIA = user.roles.includes('Issuer agent')

  return (
    <div>
      <Typography variant={'h2'}>
        {isIA ?
          <CallMergeIcon color="primary" style={{marginRight: '10px'}}/> :
          <CallMadeIcon color="primary" style={{marginRight: '10px'}}/>
        }
        {t("reusables.beneficiaryTitle", {type: isIA ? "consolidated" : "commission fee"})}
      </Typography>

      <List
        beneficiaries={beneficiaries}
        selected={activeBeneficiary}
        setSelected={setActiveBeneficiary}
        label={t("reusables.beneficiaryLabel", {role: isIA ? "IA" : 'AO'})}
      />

      <Box my={2}>
        <Actions beneficiaries={beneficiaries} selected={activeBeneficiary} />
      </Box>

      <Selected selected={activeBeneficiary} />
    </div>
  );
}
