import {Switch} from 'react-router-dom';
import NotFound from 'App/Pages/NotFound';
import Notifications from './Notifications';
import AddCorporateAction from './CorporateAction/New';
import EditCorporateAction from './CorporateAction/Edit';
import CorporateActions from './CorporateActions';
import NewInvoicingPeriod from './InvoicingPeriod/New';
import EditInvoicingPeriod from './InvoicingPeriod/Edit';
import NewInvoice from './Invoice/New';
import NewConsolidatedInvoice from './ConsolidatedInvoice/New';
import EditConsolidatedInvoice from './ConsolidatedInvoice';
import Invoice from './Invoice';
import Tariffs from './Tariffs';
import Reusables from './Reusables';
import Invoices from './Invoices'
import Payments from './Payments'
import Route from './Route';

export const notifications = '/';
export const newCA = '/corporate-actions/new';
export const corporateActions = '/corporate-actions';
export const editCA = '/corporate-actions/edit/:caId?';
export const newInvoicingPeriod = '/invoicing-period/new';
export const editInvoicingPeriod = '/invoicing-period/edit/:id?';
export const newInvoice = '/invoice/new';
export const invoice = '/invoice/:id?';
export const newConsolidatedInvoice = '/consolidated-invoice/new';
export const consolidatedInvoice = '/consolidated-invoice/:id?';
export const payments = '/payments/:id?';
export const tariffs = '/tariffs/:tariffId?';
export const reusables = '/reusables';
export const invoices = '/invoices';

export default function Pages() {
  return (
    <Switch>
      <Route exact path={notifications} component={Notifications}/>
      <Route path={corporateActions} exact component={CorporateActions}/>
      <Route path={newCA} component={AddCorporateAction}/>
      <Route path={editCA} component={EditCorporateAction}/>
      <Route path={newInvoicingPeriod} component={NewInvoicingPeriod}/>
      <Route path={editInvoicingPeriod} component={EditInvoicingPeriod}/>
      <Route path={newInvoice} component={NewInvoice}/>
      <Route path={invoice} component={Invoice}/>
      <Route path={newConsolidatedInvoice} component={NewConsolidatedInvoice}/>
      <Route path={consolidatedInvoice} component={EditConsolidatedInvoice}/>
      <Route path={tariffs} component={Tariffs}/>
      <Route path={reusables} component={Reusables}/>
      <Route path={invoices} component={Invoices}/>
      <Route path={payments} component={Payments}/>
      <Route component={NotFound}/>
    </Switch>
  );
}
