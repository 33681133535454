import {useTranslation} from 'react-i18next';
import {Grid, TextField, Button} from '@material-ui/core';
import MultiSelect from "shared/FormComponents/MultiSelect";
import {DateInput} from 'shared/FormComponents';

export default function Filters({activeFilters, setFilters, statuses, issuerAgents, reset, close}) {
  const [t] = useTranslation();
  const handleValueChange = (value, key) => {
    setFilters({...activeFilters, [key]: value});
    close();
  }
  return (
    <Grid container spacing={3} alignItems="flex-end">
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <MultiSelect
          valueContainer={activeFilters.statuses}
          name={"statuses"}
          setValue={handleValueChange}
          options={statuses}
          label={t("filter.status")}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <MultiSelect
          valueContainer={activeFilters.issuerAgents}
          name={"issuerAgents"}
          setValue={handleValueChange}
          options={issuerAgents}
          label={t("filter.issuerAgent")}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <TextField
          value={activeFilters.search}
          variant="outlined"
          fullWidth
          onChange={(event) => handleValueChange(event.target.value, 'search')}
          label={t("filter.search")}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <DateInput
          value={activeFilters.startDate}
          maxDate={activeFilters.endDate ?? new Date('2100-01-01')}
          onChange={(event) => handleValueChange(event, 'startDate')}
          label={t("filter.startDate")}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <DateInput
          value={activeFilters.endDate}
          minDate={activeFilters.startDate ?? new Date('1900-01-01')}
          onChange={(event) => handleValueChange(event, 'endDate')}
          label={t("filter.endDate")}
        />
      </Grid>
      <Grid item>
        <Button
          size="large"
          variant="outlined"
          color="primary"
          onClick={reset}>
          {t("filter.reset")}
        </Button>
      </Grid>
    </Grid>
  );
}
