import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {Authorize} from '@postinumero/authorization';
import Page from '../../Page';
import Forbidden from '../Forbidden';
import BackButton from 'App/BackButton';
import {Alert} from '@material-ui/lab';
import HasId from './HasId';
import {useConsolidatedInvoice} from 'api';
import {DRAFT} from "shared/Statuses";

export default function EditConsolidatedInvoice() {
  const [t] = useTranslation();
  const {id} = useParams();

  const invoice = useConsolidatedInvoice(id);

  return (
    <Page title={invoice.currentStatus === DRAFT ? t("consolidatedInvoice.editTitle") :
      t("consolidatedInvoice.defaultTitle")}>
      <Authorize allow={['Issuer agent', 'Inspector']} fallback={<Forbidden/>}>
        {id ?
          <HasId invoice={invoice}/>
          :
          <>
            <Alert severity="warning">
              {t('invoice.missingInvoiceParam', {id: id})}
            </Alert>
            <BackButton/>
          </>
        }
      </Authorize>
    </Page>
  );
}
