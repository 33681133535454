import {createContext, useState} from "react";
import {Snackbar, IconButton} from '@material-ui/core';
import {Alert} from "@material-ui/lab";
import CloseIcon from '@material-ui/icons/Close';
import {useTranslation} from 'react-i18next';

export const SnackBarContext = createContext();

const SnackBarProvider = ({ children }) => {
  const [t] = useTranslation("error");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const openSnackBar = ({message, severity, time=20}) => {
    setMessage(message)
    setSeverity(severity)
    setOpen(true)

    if (time) {
      setTimeout(() => {
        setOpen(false)
      }, time * 1000)
    }
  };

  const showSucces = (message) => {
    return openSnackBar({message, severity: "success"})
  }
  const showError = (errorResponse) => {
    const statusMessage = errorResponse.data?.message || errorResponse.statusText
    const message = t('snackbar', {code: errorResponse.status, message: statusMessage})
    return openSnackBar({message, severity: "error"})
  }
  const showWarning = (message) => {
    return openSnackBar({message, severity: "warning"})
  }
  const showInfo = (message) => {
    return openSnackBar({message, severity: "info"})
  }

  const value = {
    showError, showInfo, showSucces, showWarning,
    openSnackBar
  }

  return (
    <SnackBarContext.Provider value={value}>
      {children}
      <Snackbar open={open}>
        <Alert severity={severity} action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={() => setOpen(false)}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }>
          {message}
        </Alert>
      </Snackbar>
    </SnackBarContext.Provider>
  );
};

export default SnackBarProvider;
