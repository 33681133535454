import {useTranslation} from 'react-i18next';

export default function ({type, fields, selected, beneficiaries}) {
    const [t] = useTranslation();
    const edit = type === 'edit'
    const existing = edit ? beneficiaries.filter(detail => detail.id !== selected.id) : beneficiaries
    const inputs = {
        dataName: {
          isRequired: {message: t("validation.isRequired", {name: t("paymentDetails.dataName")})},
          initialValue: edit ? selected.dataName : '',
          isNotDuplicate: {
            message: t('validation.isNotDuplicate', {
              name: t('paymentDetails.dataName')
            }),
            existingValues: existing.map(obj => obj.dataName)
          },
        },
        beneficiaryName: {
          isRequired: {message: t("validation.isRequired", {name: t("beneficiary.beneficiaryName")})},
          initialValue: edit ? selected.beneficiaryData.beneficiaryName : ''
        },
        beneficiaryNameSupplement: {
          initialValue: edit ? selected.beneficiaryData.beneficiaryNameSupplement : ''
        },
        spareLine: {
          initialValue: edit ? selected.beneficiaryData.spareLine : ''
        },
        deliveryAddress: {
          isRequired: {message: t("validation.isRequired", {name: t("deliveryDetails.deliveryAddress")})},
          initialValue: edit ? selected.beneficiaryData.deliveryAddress : ''
        },
        deliveryAddressSecondLine: {
          initialValue: edit ? selected.beneficiaryData.deliveryAddressSecondLine : ''
        },
        postalCode: {
          isRequired: {message: t("validation.isRequired", {name: t("deliveryDetails.postalCode")})},
          isValidFinnishPostalCode: {
            value: fields.country,
            message: t("validation.isValidFinnishPostalCode", {name: t("deliveryDetails.postalCode")})
          },
          initialValue: edit ? selected.beneficiaryData.postalCode : ''
        },
        city: {
          isRequired: {message: t("validation.isRequired", {name: t("deliveryDetails.city")})},
          initialValue: edit ? selected.beneficiaryData.city : ''
        },
        country: {
          initialValue: edit ? selected.beneficiaryData.country : ''
        },
        email: {
          isRequired: {message: t("validation.isRequired", {name: t("deliveryDetails.email")})},
          isEmail: {message: t("validation.isEmail", {name: t("deliveryDetails.email")})},
          initialValue: edit ? selected.beneficiaryData.email : ''
        },
      }
      return inputs
}
