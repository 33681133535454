import {Suspense} from 'react';
import ErrorBoundary from 'react-error-boundary';
import ErrorFallback from 'ErrorFallback';
import {BrowserRouter, Route} from 'react-router-dom';
import {QueryParamProvider} from 'use-query-params';
import {withAuthorization} from '@postinumero/authorization';
import CssBaseline from '@material-ui/core/CssBaseline';
import Pages from './Pages';
import Spinner from './Spinner';
import theme from 'theme';
import {ThemeProvider} from '@material-ui/core/styles';
import {useDetectLoginChange} from 'login';
import {useUser} from '@postinumero/authorization';
import axios from 'axios';
import Keycloak from 'Keycloak';
import SnackBarProvider from 'shared/SnackBar/SnackbarContext';

function App() {
  useDetectLoginChange();
  const user = useUser();
  axios.defaults.headers.common['User-Role'] = user?.roles ? user.roles[0] : null;

  return (
    <Keycloak>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <BrowserRouter>
          <QueryParamProvider ReactRouterRoute={Route}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Suspense fallback={<Spinner/>}>
                <SnackBarProvider>
                  <Pages/>
                </SnackBarProvider>
              </Suspense>
            </ErrorBoundary>
          </QueryParamProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Keycloak>
  );
}

export default withAuthorization(App);
