import {Box, TableRow, TableCell} from "@material-ui/core";
import SmallTextField from "shared/FormComponents/SmallTextField";
import {formatDecimal} from 'shared/utility';
import {useDeepCompareEffect} from 'shared/useDeepCompareEffect';
import {inputValidatonToValidDecimal, ceiling} from 'shared/utility';
import RemoveRowIcon from '../RemoveRowIcon';

export const getNegotiationBasedData = (invoiceLine) => {
  const commissionFee = invoiceLine.commissionFee
  const netAmount = isNaN(invoiceLine.amount) ? 0 : invoiceLine.amount * 1; // weird fix, but okay
  const vatEUR = isNaN(netAmount * (invoiceLine.vat / 100)) ? 0 : netAmount * (invoiceLine.vat / 100);
  const totalEUR = isNaN(netAmount + vatEUR) ? 0 : netAmount + vatEUR;
  return {netAmount, vatEUR, totalEUR, commissionFee}
}

export default function NegotiationBased({invoiceLine, dataIndex, updateTotals, updateLine, disableInputs}) {
  const {vatEUR, totalEUR} = getNegotiationBasedData(invoiceLine)

  useDeepCompareEffect(() => {
    updateLine(dataIndex, invoiceLine);
  }, [invoiceLine]);

  const onQuantityChange = (event) => {
    updateLine(dataIndex, {...invoiceLine, amount: event.target.value})
  };

  const onDescriptionChange = (event) => {
    updateLine(dataIndex, {...invoiceLine, description: event.target.value})
  };

  const onVatChange = (event) => {
    updateLine(dataIndex, {...invoiceLine, vat: event.target.value})
  };

  const onRemove = () => {
    updateLine(dataIndex, null)
  }

  return (
    <>
      <TableRow>
        <TableCell>
          {disableInputs ? <Box>{invoiceLine.description}</Box> :
            <SmallTextField
              fullWidth
              value={invoiceLine.description}
              disabled={disableInputs}
              onInput={(e) => e.target.value = e.target.value.slice(0, 120)}
              onChange={onDescriptionChange}/>
          }
        </TableCell>
        <TableCell align={"right"}/>
        <TableCell align={"right"}/>
        <TableCell align={"right"}/>
        <TableCell align={"right"}>
          {disableInputs ? <Box>{invoiceLine.amount}</Box> :
            <SmallTextField
              style={{maxWidth: 150}}
              value={invoiceLine.amount}
              disabled={disableInputs}
              fullWidth
              onInput={(event) => event.target.value = inputValidatonToValidDecimal(event.target.value)}
              onChange={onQuantityChange}/>
          }
        </TableCell>
        <TableCell align={"right"}>
          {disableInputs ? <Box>{invoiceLine.vat}</Box> :
            <SmallTextField
              style={{width: 75}}
              value={invoiceLine.vat}
              disabled={disableInputs}
              onInput={(event) => event.target.value = ceiling(inputValidatonToValidDecimal(event.target.value), 100)}
              onChange={onVatChange}/>
          }
        </TableCell>
        <TableCell align={"right"}>
          {formatDecimal(vatEUR)}
        </TableCell>
        <TableCell align={"right"}>
          {formatDecimal(totalEUR)}
        </TableCell>
        {!disableInputs && <TableCell align="center">
          <RemoveRowIcon onRemove={onRemove} description={invoiceLine.description || 'unnamed'} />
        </TableCell>}
      </TableRow>
      <TableRow style={{height: '2px', background: 'white'}}/>
    </>
  );
}
