import {List, ListSubheader, Typography, Link, makeStyles, Grid, ListItem} from "@material-ui/core";
import Element from './Element';
import History from "./History";
import useModal from 'useModal';
import {useTranslation} from 'react-i18next';
import {Authorize} from '@postinumero/authorization';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: theme.spacing(2),
  },
  relativeRoot: {
    width: '100%'
  },
  align: {
    position: 'absolute',
    left: '0',
    paddingRight: theme.spacing(3),
    top: theme.mixins.toolbar.minHeight + 20,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: 0,
      marginBottom: '30px'
    }
  },
  relativeAlign: {
    top: '0',
    left: '0',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: 0
    }
  },
  subheader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'black',
    '& button': {}
  }
}));

export default function Status({statuses, relativePositioning, allowHistory=['Issuer agent', 'Account operator', 'Inspector']}) {
  const classes = useStyles();
  const [t] = useTranslation();
  const {open, handleOpen, handleClose} = useModal();
  return (
    <Grid container justify={relativePositioning ? "flex-start" : "flex-end"}
          className={relativePositioning ?
            [classes.relativeAlign, "no-print"].join(" ") :
            [classes.align, "no-print"].join(" ")}>
      <Grid item xs={12} md={relativePositioning ? 12 : 4}>
        <History onClose={handleClose} open={open} data={statuses}/>
        <List
          subheader={
            <ListSubheader disableGutters component="div" className={classes.subheader}>
              <Typography variant="h3">{t("status.title")}</Typography>
            </ListSubheader>
          }
          className={relativePositioning ? classes.relativeRoot : classes.root}
        >
          <Element status={statuses && statuses[0]}/>
          <Authorize allow={allowHistory}>
            <ListItem disableGutters dense style={{paddingTop: 0, paddingBottom: 0}}>
              {statuses &&
              <Link
                component="button"
                type="button"
                onClick={handleOpen}>
                <Typography variant="overline">{t("status.viewHistory")}</Typography>
              </Link>}
            </ListItem>
          </Authorize>
        </List>
      </Grid>
    </Grid>
  );
}
