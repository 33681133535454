import {Grid, Typography} from "@material-ui/core";
import {useTranslation} from 'react-i18next';

export default function CorporateActionDetails({corporateAction}) {
  const [t] = useTranslation();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h3">
          {t("payments.corporateAction")}
        </Typography>
      </Grid>
      {corporateAction.issuer &&
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle1" color="primary">{t("payments.issuerName")}</Typography>
        <Typography variant="body1">{corporateAction.issuer}</Typography>
      </Grid>}
      {corporateAction.actionEventType &&
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle1" color="primary">{t("payments.eventType")}</Typography>
        <Typography
          variant="body1">{corporateAction.actionEventType.type + " (" + corporateAction.actionEventType.description + ")"}</Typography>
      </Grid>}
      {corporateAction.issuerBusinessId &&
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle1" color="primary">{t("payments.issuerBusinessId")}</Typography>
        <Typography variant="body1">{corporateAction.issuerBusinessId}</Typography>
      </Grid>}
      {corporateAction.eventClassification &&
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle1" color="primary">{t("payments.eventClassification")}</Typography>
        <Typography variant="body1">{corporateAction.eventClassification}</Typography>
      </Grid>}
      {corporateAction.issuerISIN &&
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle1" color="primary">{t("payments.instrumentIsin")}</Typography>
        <Typography variant="body1">{corporateAction.issuerISIN}</Typography>
      </Grid>}
      {corporateAction.eventId &&
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle1" color="primary">{t("payments.eventId")}</Typography>
        <Typography variant="body1" style={{color: '#4D8400'}}>{corporateAction.eventId}</Typography>
      </Grid>}
    </Grid>
  );
}
