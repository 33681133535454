import {useTranslation} from 'react-i18next';
import MUIDataTable from 'mui-datatables';
import {MuiThemeProvider, TableFooter, TableCell, TableRow, Table} from '@material-ui/core';
import AmountBased from './Types/AmountBased';
import CommissionBased from "./Types/CommissionBased";
import NegotiationBasedFee from "./Types/NegotiationBasedFee";
import SelectBasedFee from "./Types/SelectBasedFee";
import {theme as tableTheme} from './theme';
import React, {useState} from 'react';
import CustomFooter from './CustomFooter';
import {formatDecimal} from 'shared/utility';
import {Alert} from '@material-ui/lab';

export default function DataTable({invoiceLines, updateLine, disableInputs}) {
  const [t] = useTranslation();
  const [totals, updateTotals] = useState({});
  const styles = {
    verticalAlign: 'bottom',
    paddingRight: 0
  };
  const rightAlign = () => {
    return {
      align: 'right',
      style: styles
    }
  };
  const bottomTitleWithoutAlign = () => {
    return {
      style: styles
    }
  };
  const columns = [
    {
      label: t("commissionFees.additionalInfo"),
      name: 'additionalInfo',
      options: {setCellHeaderProps: bottomTitleWithoutAlign}
    },
    {label: t("commissionFees.quantity"), name: 'quantity', options: {setCellHeaderProps: rightAlign}},
    {label: t("commissionFees.pricePerAmount"), name: 'pricePerAmount', options: {setCellHeaderProps: rightAlign}},
    {label: t("commissionFees.minimumPrice"), name: 'minimumPrice', options: {setCellHeaderProps: rightAlign}},
    {label: t("commissionFees.netAmount"), name: 'netAmount', options: {setCellHeaderProps: rightAlign}},
    {label: t("commissionFees.vatPercentage"), name: 'vatPercentage', options: {setCellHeaderProps: rightAlign}},
    {label: t("commissionFees.vatEUR"), name: 'vatEUR', options: {setCellHeaderProps: rightAlign}},
    {label: t("commissionFees.totalEUR"), name: 'totalEUR', options: {setCellHeaderProps: rightAlign}}
  ];
  const options = {
    pagination: false,
    print: false,
    download: false,
    filter: false,
    search: false,
    sort: false,
    responsive: 'standard',
    viewColumns: false,
    selectableRows: 'none',
    elevation: 0,
    customRowRender: (data, dataIndex) => {
      const invoiceLine = invoiceLines[dataIndex];
      const key = invoiceLine.commissionFee?.id || dataIndex
      //TODO: other fee types, switch case for other fee types, view only mode
      switch (invoiceLine.commissionFee?.type || "NEGOTIATED") {
        case "QUANTITY_BASED":
          return <AmountBased
            key={key}
            invoiceLine={invoiceLine}
            updateTotals={updateTotals}
            updateLine={updateLine}
            dataIndex={dataIndex}
            disableInputs={disableInputs}
          />;
        case "COMMISSION_BASED":
          return <CommissionBased
            key={key}
            invoiceLine={invoiceLine}
            updateTotals={updateTotals}
            updateLine={updateLine}
            dataIndex={dataIndex}
            disableInputs={disableInputs}
          />;
        case "NEGOTIATED":
          return <NegotiationBasedFee
            key={key}
            invoiceLine={invoiceLine}
            updateTotals={updateTotals}
            updateLine={updateLine}
            dataIndex={dataIndex}
            disableInputs={disableInputs}
          />;
        case "SELECTION_BASED":
          return <SelectBasedFee
            key={key}
            invoiceLine={invoiceLine}
            updateTotals={updateTotals}
            updateLine={updateLine}
            dataIndex={dataIndex}
            disableInputs={disableInputs}
          />;
        default:
          return "Fee type not found.";
      }
    },
    customTableBodyFooterRender: () => {
      return (<CustomFooter invoiceLines={invoiceLines} updateTotals={updateTotals} />);
    },
  };

  return (
    <>
      {invoiceLines && invoiceLines.length > 0 ?
        <MuiThemeProvider theme={tableTheme}>
          <MUIDataTable
            columns={columns}
            options={options}
            data={invoiceLines}
          />
          <Table>
            <TableFooter>
              <TableRow>
                <TableCell style={{
                  padding: '30px', textAlign: 'right', fontSize: '1.7rem',
                  fontWeight: '400'
                }}>
                  {t("commissionFees.grandTotal")}
                  <b style={{
                    fontSize: '2.5rem',
                    fontWeight: '600',
                    color: '#4D8400',
                  }}>{formatDecimal(Object.keys(totals).reduce((a, b) => a + totals[b].totalEUR, 0)) + ' EUR'}</b>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </MuiThemeProvider>
        : <Alert severity="warning">
          {t("commissionFees.noFees")}
        </Alert>
      }
    </>
  );
}
