import {Typography, Box} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import {ValidationContext} from "shared/useValidation";
import SmallDateInput from "shared/FormComponents/SmallDateInput";
import MomentAdapter from '@date-io/moment';


export default function DueDate({disableInputs, noInput = true}) {
  const [t] = useTranslation();
  const moment = new MomentAdapter();
  const {getInputProps, values, setValue} = useContext(ValidationContext);
  const dueDate = values.dueDate ? moment.date(values.dueDate).format('DD.MM.YYYY') : '';

  return (
    <>
      {noInput || disableInputs ?
      <Box>
        <Typography variant="subtitle1" color="primary">{t("invoice.dueDate")}</Typography>
        <Typography variant="body1">{dueDate}</Typography>
      </Box> :
        <SmallDateInput
          className={'no-print'}
          required
          label={t("invoice.dueDate")}
          disabled={disableInputs}
          helperText={null}
          {...getInputProps("dueDate", {
            onChange: (event) => {
              setValue('dueDate', event?.format('YYYY-MM-DD'))
            }
          })}
        />
      }
    </>
  );
}
