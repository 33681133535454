import useAxios, {reload, useAxiosSafe} from 'use-axios';
import axios from 'axios';

export function useInvoices() {
  return useAxios(`${process.env.API_PATH}/invoices`).data;
}

export function useConsolidatedInvoices() {
  const [, response] = useAxiosSafe(`${process.env.API_PATH}/consolidatedInvoices`);
  return response ? response.data : []
}

export function useConsolidatedInvoice(id) {
  return useAxios(`${process.env.API_PATH}/consolidatedInvoices/${id}`).data;
}

export async function postConsolidatedInvoice(data) {
  return await axios.post(`${process.env.API_PATH}/consolidatedInvoices`, data);
}

export async function putConsolidatedInvoice(data) {
  return await axios.put(`${process.env.API_PATH}/consolidatedInvoices`, data);
}

export async function removeConsolidatedInvoice(id) {
  return await axios.delete(`${process.env.API_PATH}/consolidatedInvoices/${id}`)
}

export async function exportConsolidatedInvoice(id) {
  return await axios.get(`${process.env.API_PATH}/consolidatedInvoices/xml/${id}`);
}

export function useReusableBeneficiaries() {
  const [, response] = useAxiosSafe(`${process.env.API_PATH}/reusableBeneficiaries`);
  // FIXME: handle errors
  return response ? response.data : []
}

export async function postReusableBeneficiary(data) {
  return await axios.post(`${process.env.API_PATH}/reusableBeneficiaries`, data);
}

export async function putReusableBeneficiary(data) {
  return await axios.put(`${process.env.API_PATH}/reusableBeneficiaries`, data);
}

export async function removeReusableBeneficiary(id) {
  return await axios.delete(`${process.env.API_PATH}/reusableBeneficiaries/${id}`);
}

export function useReusablePaymentDetails() {
  const [, response] = useAxiosSafe(`${process.env.API_PATH}/reusablePaymentDetails`);
  // FIXME: handle errors
  return response ? response.data : []
}

export async function postReusablePaymentDetails(data) {
  return await axios.post(`${process.env.API_PATH}/reusablePaymentDetails`, data);
}

export async function putReusablePaymentDetails(data) {
  return await axios.put(`${process.env.API_PATH}/reusablePaymentDetails`, data);
}

export async function deleteReusablePaytmentDetail(id) {
  return await axios.delete(`${process.env.API_PATH}/reusablePaymentDetails/${id}`);
}

export function useReusablePayerDetails(type) {
  const [, response] = useAxiosSafe(`${process.env.API_PATH}/reusablePayerDetails/type/${type}`);
  // FIXME: handle errors
  return response ? response.data : []
}

export async function postReusablePayerDetails(data) {
  return await axios.post(`${process.env.API_PATH}/reusablePayerDetails`, data);
}

export async function putReusablePayerDetails(data) {
  return await axios.put(`${process.env.API_PATH}/reusablePayerDetails`, data);
}

export async function deleteReusablePayerDetail(id) {
  return await axios.delete(`${process.env.API_PATH}/reusablePayerDetails/${id}`);
}

export function useCorporateActionEventTypes() {
  return useAxios(`${process.env.API_PATH}/corporateActionEvents/types`).data;
}

export async function postCorporateAction(data) {
  return await axios.post(`${process.env.API_PATH}/corporateActionEvents`, data);
}

export async function putCorporateAction(data) {
  return await axios.put(`${process.env.API_PATH}/corporateActionEvents`, data);
}

export async function removeCorporateAction(id) {
  return await axios.delete(`${process.env.API_PATH}/corporateActionEvents/${id}`);
}

export function useCorporateActions() {
  const [, response] = useAxiosSafe(`${process.env.API_PATH}/corporateActionEvents`)
  return response ? response.data : []
}

export function useCA(id) {
  return useAxios(`${process.env.API_PATH}/corporateActionEvents/${id}`).data;
}

export function useBankHolidaysDate(base, daysToAdd) {
  return useAxios(`${process.env.API_PATH}/bankHolidays/plusDays`, {params: {base: base, daysToAdd: daysToAdd}}).data;
}

export function useBankHolidaysWithinMonth() {
  const [, response] = useAxiosSafe(`${process.env.API_PATH}/bankHolidays/withinMonth`);
  return response ? response.data : [];
}

export async function postInvoicingPeriod(data) {
  await axios.post(`${process.env.API_PATH}/invoicingPeriods`, data);
}

export async function putInvoicingPeriod(data) {
  await axios.put(`${process.env.API_PATH}/invoicingPeriods`, data);
}

export async function removeInvoicingPeriod(id) {
  return await axios.delete(`${process.env.API_PATH}/invoicingPeriods/${id}`);
}

export function useInvoicingPeriod(id) {
  return useAxios(`${process.env.API_PATH}/invoicingPeriods/${id}`).data;
}

export async function postInvoice(data) {
  return await axios.post(`${process.env.API_PATH}/invoices`, data);
}

export async function putInvoice(data) {
  return await axios.put(`${process.env.API_PATH}/invoices`, data);
}

export async function removeInvoice(id) {
  return await axios.delete(`${process.env.API_PATH}/invoices/${id}`);
}

export function useInvoice(id) {
  const [, response] = useAxiosSafe(`${process.env.API_PATH}/invoices/${id}`);
  return response ? response.data : null
}

export function useFeeType(catype) {
  return useAxios(`${process.env.API_PATH}/tariffs/find`, {params: {caeventtype: catype}}).data;
}

export function useTariffs() {
  return useAxios(`${process.env.API_PATH}/tariffs`).data;
}

export async function postTariff(data) {
  return await axios.post(`${process.env.API_PATH}/tariffs`, data);
}

export async function putTariff(data) {
  return await axios.put(`${process.env.API_PATH}/tariffs`, data);
}

export async function removeTariff(id) {
  return await axios.delete(`${process.env.API_PATH}/tariffs/${id}`);
}

export function useItems() {
  return useAxios(`${process.env.API_PATH}/samples`).data;
}

export function useItem(id) {
  return useAxios(`${process.env.API_PATH}/samples/${id}`).data;
}

export async function postItem(item) {
  await axios.post(`${process.env.API_PATH}/samples`, item);
  reload(`${process.env.API_PATH}/samples`);
}

export async function deleteItems(items) {
  for (const {id} of items) {
    await deleteItem(id);
  }
}

export async function deleteItem(id) {
  await axios.delete(`${process.env.API_PATH}/samples/${id}`);
  reload(`${process.env.API_PATH}/samples`);
}

export async function exportInvoice(id) {
  return await axios.get(`${process.env.API_PATH}/invoices/xml/${id}`)
}

export function useNotifications() {
  return useAxios(`${process.env.API_PATH}/notifications/`).data;
}

export async function deleteNotification(id) {
  return await axios.delete(`${process.env.API_PATH}/notifications/${id}`);
}

// Poll API 😕 to get updates from other users and tabs
setInterval(() => reload(`${process.env.API_PATH}/samples`), 30000);
