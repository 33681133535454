import {FormControl, MenuItem, ListItemText, Checkbox, InputLabel, Select} from '@material-ui/core';
import {removeUnderscores} from 'shared/utility';

export default function MultiSelect({options, valueContainer, setValue, label, name}) {
  const handleChange = (event) => {
    setValue(event.target.value, name)
  };
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel style={{backgroundColor: 'white', padding: '0 2px'}} variant="outlined"
                  id={name + "-label"}>{label}</InputLabel>
      <Select
        labelId={name + "-label"}
        id={name}
        multiple
        value={valueContainer}
        onChange={handleChange}
        renderValue={(selected) => selected.map((value, index) => {
          return removeUnderscores(value) + (index + 1 !== selected.length ? ',' : '');
        })}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          }
        }}
      >
        {options?.sort()?.map((option, index) => (
          <MenuItem key={index} value={option}>
            <Checkbox checked={valueContainer?.indexOf(option) > -1}/>
            <ListItemText primary={removeUnderscores(option)}/>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
