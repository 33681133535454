import {useContext} from "react";
import {ValidationContext} from "shared/useValidation";
import {useTranslation} from 'react-i18next'
import SmallTextField from "shared/FormComponents/SmallTextField";
import {Typography, Box} from "@material-ui/core";

export default function AdditionalInformation({disableInputs}) {
  const [t] = useTranslation();
  const {getInputProps, values} = useContext(ValidationContext);
  return (
    <>
      {(values?.additionalInformation && disableInputs) &&
      <Box>
        <Typography variant="subtitle1" color="primary">{t("invoice.additionalInformation")}</Typography>
        <Typography variant="body1">{values.additionalInformation}</Typography>
      </Box>
      }
      {!disableInputs &&
        <SmallTextField
          fullWidth
          className={"no-print"}
          inputProps={{maxLength: 200}}
          multiline
          disabled={disableInputs}
          label={t("invoice.additionalInformation")}
          onInput={(e) => e.target.value = e.target.value.slice(0, 120)}
          {...getInputProps("additionalInformation")}
        />
      }
    </>
  );
}
