import Button from '@material-ui/core/Button';
import {useHistory} from 'react-router-dom';

import {useTranslation} from 'react-i18next';

export default function BackButton() {
  const [t] = useTranslation();
  const history = useHistory();
  return <Button onClick={history.goBack}>{t('form.back')}</Button>;
}
