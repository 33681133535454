import {Box, TableRow, TableCell} from "@material-ui/core";
import SmallTextField from "shared/FormComponents/SmallTextField";
import {formatDecimal, formatToInteger} from 'shared/utility';
import {useDeepCompareEffect} from 'shared/useDeepCompareEffect';
import {inputValidatonToValidDecimal} from 'shared/utility';
import RemoveRowIcon from '../RemoveRowIcon';

export const getQuantityBasedData = (invoiceLine) => {
  const commissionFee = invoiceLine.commissionFee
  const netAmount = isNaN(invoiceLine.quantity * commissionFee.pricePerQuantity) ?
    invoiceLine.commissionFee.minimumPrice :
    Math.max((invoiceLine.quantity * commissionFee.pricePerQuantity), commissionFee.minimumPrice);
  const vatEUR = isNaN(netAmount * (commissionFee.vat / 100)) ? 0 : netAmount * (commissionFee.vat / 100);
  const totalEUR = isNaN(netAmount + vatEUR) ? 0 : netAmount + vatEUR;
  return {netAmount, vatEUR, totalEUR, commissionFee}
}

export default function AmountBased({invoiceLine, dataIndex, updateLine, disableInputs}) {
  const {netAmount, vatEUR, totalEUR, commissionFee} = getQuantityBasedData(invoiceLine)

  useDeepCompareEffect(() => {
    updateLine(dataIndex, invoiceLine);
  }, [invoiceLine]);

  const onQuantityChange = (event) => {
    updateLine(dataIndex, {...invoiceLine, quantity: event.target.value})
  };

  const onRemove = () => {
    updateLine(dataIndex, null)
  }

  return (
    <>
      <TableRow style={{borderBottom: 5}}>
        <TableCell>
          {commissionFee.description}
        </TableCell>
        <TableCell align={"right"}>
          {disableInputs
            ? <Box>{invoiceLine.quantity}</Box>
            : <SmallTextField
              value={invoiceLine.quantity}
              disabled={disableInputs}
              onChange={onQuantityChange}
              onInput={(event) => event.target.value = inputValidatonToValidDecimal(event.target.value)}
              style={{width: 75}}/>
          }
        </TableCell>
        <TableCell align={"right"}>
          {formatDecimal(commissionFee.pricePerQuantity)}
        </TableCell>
        <TableCell align={"right"}>
          {commissionFee.minimumPrice && commissionFee.minimumPrice !== 0 ? formatDecimal(commissionFee.minimumPrice) : '-'}
        </TableCell>
        <TableCell align={"right"}>
          {formatDecimal(netAmount)}
        </TableCell>
        <TableCell align={"right"}>
          {formatToInteger(commissionFee.vat)}
        </TableCell>
        <TableCell align={"right"}>
          {formatDecimal(vatEUR)}
        </TableCell>
        <TableCell align={"right"}>
          {formatDecimal(totalEUR)}
        </TableCell>
        {!disableInputs && <TableCell align="center">
          <RemoveRowIcon onRemove={onRemove} description={invoiceLine.description || invoiceLine.commissionFee?.description || 'unnamed'} />
        </TableCell>}
      </TableRow>
      <TableRow style={{height: '2px', background: 'white'}}/>
    </>
  );
}
