
import {useContext} from 'react';
import {Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import MUIDataTable from 'mui-datatables';
import {MuiThemeProvider, TableFooter, TableCell, TableRow, Table} from '@material-ui/core';
import {theme as tableTheme} from './theme';
import {formatDecimal} from 'shared/utility';
import CustomFooter from './CustomFooter';
import CustomTableRow from './CustomTableRow';
import {ValidationContext} from "shared/useValidation";

export default function CommissionFees() {
  const [t] = useTranslation();

  const {values} = useContext(ValidationContext);
  const invoices = values?.invoices

  const styles = {
    verticalAlign: 'bottom',
    paddingRight: 0,
    whiteSpace: 'pre-line'
  };
  const rightAlign = () => {
    return {
      align: 'right',
      style: styles,
    }
  };
  const leftAlign = () => {
    return {
      align: 'left',
      style: styles
    }
  }

  const vatRates = [
    ...new Set(
      invoices
        .map(invoice => Object.keys(invoice.vatExcludedTotals))
        .reduce((a, b) => { return a.concat(b) }, [])
    )
  ]
  vatRates.sort()

  const columns = [
    {
      label: t("commissionFees.accountOperator"),
      name: 'organisation',
      options: {setCellHeaderProps: leftAlign}
    },
    {label: t("commissionFees.vatEUR"), name: 'totalVatAmount', options: {setCellHeaderProps: rightAlign}},
    {label: t("commissionFees.totalEUR"), name: 'totalAmount', options: {setCellHeaderProps: rightAlign}}
  ];

  vatRates.forEach((rate, index) => {
    const column = {label: t("commissionFees.netAmountVatAmount", {rate: rate}), name: 'vatExcludedTotals', options: {setCellHeaderProps: rightAlign}, rate: rate}
    columns.splice(1 + index, 0, column)
  })

  const options = {
    pagination: false,
    print: false,
    download: false,
    filter: false,
    search: false,
    sort: false,
    responsive: 'standard',
    viewColumns: false,
    selectableRows: 'none',
    elevation: 0,
    isRowSelectable: () => false,
    customRowRender: (data, dataIndex) => {
      return <CustomTableRow key={dataIndex} data={data} vatRates={vatRates} />
    },
    customTableBodyFooterRender: () => {
      return (<CustomFooter invoices={invoices} vatRates={vatRates}/>);
    },
  };

  return (
    <div style={{width: '100%'}}>
      <Typography variant="h3" style={{marginBottom: '10px'}}>{t('consolidatedInvoice.invoicesTitle')}</Typography>
      <MuiThemeProvider theme={tableTheme}>
        <MUIDataTable
          columns={columns}
          options={options}
          data={invoices}
        />
        <Table>
          <TableFooter>
            <TableRow>
              <TableCell style={{
                padding: '30px', textAlign: 'right', fontSize: '1.7rem',
                fontWeight: '400'
              }}>
                {t("commissionFees.grandTotal")}
                <b style={{
                    fontSize: '2.5rem',
                    fontWeight: '600',
                    color: '#4D8400',
                  }}>{formatDecimal(Object.keys(invoices).reduce((a, b) => a + invoices[b].totalAmount, 0)) + ' EUR'}</b>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </MuiThemeProvider>
    </div>
  )
}
