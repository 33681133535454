import {createMuiTheme} from '@material-ui/core';

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#007ACA',
      main: '#0062A3',
    },
    secondary: {
      main: '#4D8400'
    },
    tertiary: {
      main: '#646464'
    },
    background: {
      default: 'white'
    },
    text: {
      primary: '#282828'
    }
  },
  overrides: {
    MuiInputBase: {
      root: {
        backgroundColor: '#ffffff',
        fontFamily: ['Open Sans', 'Arial', 'sans-serif'].join(','),
        height: 25
      }
    },
    MuiTableRow: {
      root: {
        backgroundColor: '#F2F2F2',
        height: 30,
        overflow: 'hidden'
      },
      footer: {
        backgroundColor: 'transparent'
      }
    },
    MuiTableCell: {
      root: {
        padding: '5px 5px 5px 5px',
        borderBottom: 'none',
        fontFamily: 'Open Sans,Arial,sans-serif',
        fontSize: '1rem',
        height: 30,
        overflow: "hidden"
      },
      head: {
        padding: '0 10px 3px 5px',
        color: '#0062A3',
        fontSize: '0.9rem',
        fontWeight: '600',
        lineHeight: '1.3em',
        letterSpacing: '0.2px'
      },
      footer: {
        fontWeight: '700',
        fontSize: '1rem',
        color: '#282828',
        padding: '3px 10px 10px 5px'
      }
    },
    MuiTableFooter: {
      root: {
        '& .MuiToolbar-root': {
          backgroundColor: 'white',
        },
      },
    },
    MuiTypography: {
      body1: {
        letterSpacing: '0.02em',
        fontFamily: ['Open Sans', 'Arial', 'sans-serif'].join(','),
      }
    },
    MuiSelect: {
      root: {
        fontFamily: ['Open Sans', 'Arial', 'sans-serif'].join(','),
        height: 25,
        padding: 0,
        paddingLeft: 4
      }
    },
    MuiDialog: {
      paper: {
        padding: '30px 50px'
      }
    },
    MuiDialogTitle: {
      root: {
        textAlign: 'center',
        '& h1': {
          fontSize: '2.6rem',
          fontWeight: '300',
          fontFamily: 'Open Sans, Arial, sans-serif'
        }
      }
    },
    MuiDialogContent: {
      root: {
        fontFamily: 'Open Sans, Arial, sans-serif'
      }
    },
    MuiButton: {
      root: {
        fontFamily: 'Open Sans, Arial, sans-serif',
        fontSize: '1.1rem',
        textTransform: 'none',
        fontWeight: '700'
      }
    }
  },
});
