import {Typography, Button} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Beneficiary from './Beneficiary';

export default function Details({openDialog, beneficiary, disableInputs}) {
  const [t] = useTranslation();
  return (
    <>
      <Typography variant="h3">
        {t("invoice.beneficiary")}
        {!disableInputs &&
        <Button component="div" onClick={openDialog}>
          <Typography color="primary" variant="overline">
            {!beneficiary ?
              t("invoice.addBeneficiary") :
              t("invoice.editBeneficiary")
            }
          </Typography>
        </Button>}
      </Typography>
      <Beneficiary beneficiary={beneficiary} />
    </>
  );
}
