export const PUBLISHED = "PUBLISHED";
export const DRAFT = "DRAFT";
export const READYTOPUBLISH = "READY_TO_PUBLISH";
export const READYTOSEND = "READY_TO_SEND";
export const SENDINGREQUESTED = "SENDING_REQUESTED";
export const PAID = "PAID";
export const INVOICESENT = "INVOICE_SENT";
export const MARKEDPAID = "IA_MARKED_AS_PAID";
export const SENDINGFAILED = "SENDING_FAILED";
export const DONE = "DONE";
export const LOCKED = "LOCKED";
export const INVOICINGENABLED = "INVOICING_ENABLED";
export const INVALID = "INVALID";
