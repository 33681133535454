import {Button, Grid} from "@material-ui/core";
import {useContext} from "react";
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {ValidationContext} from "shared/useValidation";
import {
  DRAFT, READYTOSEND, SENDINGREQUESTED, PAID, INVOICESENT, MARKEDPAID, SENDINGFAILED, DONE
} from "shared/Statuses";
import useModal from 'shared/useModal';
import InvalidateConfirmationDialog from "./InvalidateConfirmationDialog";
import {corporateActions} from "../../index";

export default function Actions({status, invoiceExist, disableActions}) {
  const [t] = useTranslation();
  const {getActionProps, getDeletionConfirmationProp, getExportProps} = useContext(ValidationContext);
  const {open, handleOpen, handleClose} = useModal();
  const history = useHistory();
  const exportableStatuses = [SENDINGREQUESTED, SENDINGFAILED, INVOICESENT, MARKEDPAID, PAID]
  return (
    <>
      <InvalidateConfirmationDialog
        open={open}
        onClose={handleClose}
      />
      <Grid item>
        <Button
          onClick={() => history.push({pathname: corporateActions})}
          variant="outlined"
          size="large"
          color="primary">
          {t("form.cancel")}
        </Button>
      </Grid>
      {invoiceExist &&
      <Grid item>
        <Button
          onClick={window.print}
          variant="outlined"
          size="large"
          color="primary">
          {t("invoice.print")}
        </Button>
      </Grid>}
      {!disableActions && <>
        {status && invoiceExist && (status === DRAFT || status === READYTOSEND) &&
        <Grid item>
          <Button
            color="primary"
            size="large"
            variant="outlined"
            {...getDeletionConfirmationProp()}
          >
            {t('form.remove')}
          </Button>
        </Grid>}
        {invoiceExist && status && (
          status === INVOICESENT ||
          status === PAID ||
          status === MARKEDPAID ||
          status === DONE
        ) &&
        <Grid item>
          <Button
            variant="outlined"
            size="large"
            color="primary"
            onClick={handleOpen}
          >
            {t("invoice.invalidate")}
          </Button>
        </Grid>}
        {status && exportableStatuses.includes(status) &&
        <Grid item>
          <Button
            {...getExportProps()}
            variant="outlined"
            size="large"
            color="primary">
            {t("form.export")}
          </Button>
        </Grid>}
        {status && status === DRAFT &&
        <Grid item>
          <Button
            {...getActionProps(READYTOSEND)}
            variant="outlined"
            size="large"
            color="primary">
            {t("form.readyToSend")}
          </Button>
        </Grid>}
        {status && (status === DRAFT || status === READYTOSEND) && <Grid item>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            {...getActionProps(DRAFT, true)}>
            {t("form.save")}
          </Button>
        </Grid>}
        {invoiceExist && status && (status === DRAFT || status === READYTOSEND) &&
        <Grid item>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            {...getActionProps(SENDINGREQUESTED)}>
            {t("form.sendInvoice")}
          </Button>
        </Grid>}
        {status && (status === INVOICESENT || status === MARKEDPAID) &&
        <Grid item>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            {...getActionProps(PAID)}>
            {t("form.paid")}
          </Button>
        </Grid>}
      </>}
    </>
  );
}
