import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import FeeDialog from "./FeeDialog";
import {ValidationProvider} from 'shared/useValidation';
import {postTariff, putTariff} from 'api';
import {tariffs} from "../../../index";
import {useHistory} from 'react-router-dom';
import useSnackBar from 'shared/SnackBar/useSnackBar';

export default function PaymentInfo({fee, open, handleClose, activeTariff}) {
  const [t] = useTranslation();
  const history = useHistory();
  const [selectOptionsErrors, setSelectOptionsErrors] = useState([]);
  const [showOptionsErrors, setshowOptionsErrors] = useState(false);
  const {showError} = useSnackBar();

  const validateSelectOptions = (options) => {
    const errors = [];
    if (validateSelectOptionsRequired(options)) {
      errors.push(t("validation.selection.isRequired"));
    }

    if (validateSelectOptionsTwoDecimal(options)) {
      errors.push(t("validation.selection.twoDecimal"));
    }

    setSelectOptionsErrors(errors);
    return errors.length < 1;
  };

  const validateSelectOptionsRequired = (options) => {
    const isEmpty = (value) => (!value || value === '') && value !== 0;
    if (options.some(o => isEmpty(o.description.trim()) || isEmpty(o.amount) || isEmpty(o.vat))) {
      return true;
    }
  };

  const validateSelectOptionsTwoDecimal = (options) => {
    const regexp = /^[0-9]+(\.[0-9][0-9]?)?$/;
    if (options.some(o => !regexp.test(o.amount) || !regexp.test(o.vat))) {
      return true;
    }
  };

  const config = fields => ({
    inputs: {
      type: {
        isRequired: {
          message: t('validation.isRequired', {name: t('tariffs.feeDetails.type')})
        },
        initialValue: fee?.type ?? 'NEGOTIATED'
      },
      description: {
        isRequiredConditionalForFee: {
          message: t('validation.isRequired', {name: t('tariffs.feeDetails.description')}),
          type: fields.type,
          field: 'description'
        },
        initialValue: ''
      },
      amount: {
        isRequiredConditionalForFee: {
          message: t('validation.isTwoDecimal', {name: t('tariffs.feeDetails.amount')}),
          type: fields.type,
          field: 'amount'
        },
        isTwoDecimal: {
          message: t('validation.isTwoDecimal', {name: t('tariffs.feeDetails.amount')}),
          checkIf: fields.type === 'COMMISSION_BASED'
        },
        initialValue: ''
      },
      pricePerQuantity: {
        isRequiredConditionalForFee: {
          message: t('validation.isTwoDecimal', {name: t('tariffs.feeDetails.pricePerQuantity')}),
          type: fields.type,
          field: 'pricePerQuantity'
        },
        isTwoDecimal: {
          message: t('validation.isTwoDecimal', {name: t('tariffs.feeDetails.pricePerQuantity')}),
          checkIf: fields.type === 'QUANTITY_BASED'
        },
        initialValue: ''
      },
      minimumPrice: {
        isRequiredConditionalForFee: {
          message: t('validation.isTwoDecimal', {name: t('tariffs.feeDetails.minimumPrice')}),
          type: fields.type,
          field: 'minimumPrice'
        },
        isTwoDecimal: {
          message: t('validation.isTwoDecimal', {name: t('tariffs.feeDetails.minimumPrice')}),
          checkIf: fields.type === 'QUANTITY_BASED'
        },
        initialValue: ''
      },
      vat: {
        isRequiredConditionalForFee: {
          message: t('validation.isTwoDecimal', {name: t('tariffs.feeDetails.vat')}),
          type: fields.type,
          field: 'vat'
        },
        isTwoDecimal: {
          message: t('validation.isTwoDecimal', {name: t('tariffs.feeDetails.vat')}),
          checkIf: fields.type === 'QUANTITY_BASED' || fields.type === 'COMMISSION_BASED'
        },
        initialValue: ''
      },
      options: {
        initialValue: []
      }
    },
    onClick: async (state) => {
      if (!state.errors || Object.keys(state.errors).length === 0) {
        const feeToSave = {
          ...state.values,
          id: fee.id,
          corporateActionEventType: fee.corporateActionEventType
        };

        if (feeToSave.type === 'SELECTION_BASED') {
          if (!validateSelectOptions(state.values.options)) {
            setshowOptionsErrors(true);
            return;
          }

          if (selectOptionsErrors.length > 0) {
            setshowOptionsErrors(true);
            return;
          }
        }

        const commissionFees = activeTariff.commissionFees;
        if (feeToSave.id) {
          commissionFees[commissionFees.findIndex(fee => fee.id === feeToSave.id)] = feeToSave;
        } else {
          commissionFees.push(feeToSave);
        }

        const tariffToSave = {
          ...activeTariff,
          id: activeTariff.id === -1 ? null : activeTariff.id,
          commissionFees: commissionFees
        };

        const saveData = tariffToSave?.id === -1 ? postTariff : putTariff;
        await saveData(tariffToSave).then((response) => {
          const redirectId = response?.data?.id;
          history.push({pathname: tariffs.replace(":tariffId?", redirectId)});
          window.location.reload();
        }).catch((err) => {
          showError(err.response)
        });
      }
    }
  });

  return (
    <ValidationProvider config={config}>
      <FeeDialog onClose={handleClose}
                 open={open}
                 fee={fee}
                 selectOptionsErrors={selectOptionsErrors}
                 validateSelectOptions={validateSelectOptions}
                 showOptionsErrors={showOptionsErrors}
                 setShowOptionsErrors={setshowOptionsErrors}
      />
    </ValidationProvider>
  );
}
