import {Typography, Box, makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block', width: '100%',
    marginBottom: '10px'
  }
}));
export default function Beneficiary({beneficiary}) {

  const classes = useStyles();
  return (
    <>
      <Box className={classes.root}>
        {beneficiary?.beneficiaryName &&
        <Typography variant="body1">{beneficiary.beneficiaryName}</Typography>}
        {beneficiary?.beneficiaryNameSupplement &&
        <Typography variant="body1">{beneficiary.beneficiaryNameSupplement}</Typography>}
        {beneficiary?.spareLine &&
        <Typography variant="body1">{beneficiary.spareLine}</Typography>}
        {beneficiary?.deliveryAddress &&
        <Typography variant="body1">{beneficiary.deliveryAddress}</Typography>}
        {beneficiary?.deliveryAddressSecondLine &&
        <Typography variant="body1">{beneficiary.deliveryAddressSecondLine}</Typography>}
        {(beneficiary?.city && beneficiary.postalCode) &&
        <Typography variant="body1">{beneficiary.postalCode} {beneficiary.city}</Typography>}
        {beneficiary?.country &&
        <Typography variant="body1">{beneficiary.country}</Typography>}
        {beneficiary?.email &&
        <Typography variant="body1">{beneficiary.email}</Typography>}
        {beneficiary?.electronicInvoiceIntermediator &&
        <Typography variant="body1">{beneficiary.electronicInvoiceIntermediator}</Typography>}
        {beneficiary?.electronicInvoiceAddress &&
        <Typography variant="body1">{beneficiary.electronicInvoiceAddress}</Typography>}
      </Box>
    </>
  );
}
