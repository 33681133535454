import {useContext, useState} from 'react';
import {Grid, makeStyles} from '@material-ui/core';
import {useDeepCompareEffect} from 'shared/useDeepCompareEffect';
import {ValidationContext} from 'shared/useValidation';
import {ValidationErrors} from 'shared/FormComponents';
import Add from './Add';
import Edit from './Edit';
import {useReusableBeneficiaries, useReusablePaymentDetails, useReusablePayerDetails} from 'api';

const useStyles = makeStyles((theme) => ({
  row: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3)
    },
    '& h3': {
      marginBottom: theme.spacing(2)
    }
  },
  rowWithSpacing: {
    marginTop: theme.spacing(2)
  }
}));

export default function Layouts({invoicingPeriod, corporateAction, invoice}) {
  const classes = useStyles();
  const {setValue} = useContext(ValidationContext);
  const [invoiceState, setInvoiceState] = useState(invoice);
  const [useInitialLayout, setInitialLayout] = useState(!invoice);

  const updateState = (object, key) => {
    setInvoiceState({...invoiceState, [key]: object});
  }
  useDeepCompareEffect(() => {
    setValue('beneficiaryData', invoiceState?.beneficiaryData);
    setValue('paymentDetails', invoiceState?.paymentDetails);
    setValue('payerData', invoiceState?.payerData);
  }, [invoiceState, setValue]);

  const reusableBeneficiaries = useReusableBeneficiaries()
  const firstBeneficiary = reusableBeneficiaries.length ? reusableBeneficiaries[0] : null
  const reusablePaymentDetails = useReusablePaymentDetails()
  const firstPaymentDetail = reusablePaymentDetails.length ? reusablePaymentDetails[0] : null
  const reusablePayerDetails = useReusablePayerDetails("Consolidated")
  const firstPayerDetail = reusablePayerDetails.length ? reusablePayerDetails[0] : null

  useDeepCompareEffect(() => {
    setInvoiceState({
      ...invoiceState,
      beneficiaryData: invoice?.beneficiaryData ?? firstBeneficiary?.beneficiaryData,
      paymentDetails: invoice?.paymentDetails ?? firstPaymentDetail?.paymentDetails,
      payerData: invoice?.payerDetails ?? firstPayerDetail?.payerDetails
    })
  }, [])

  return (
    <Grid container>
      <Grid item xs={12}><ValidationErrors/></Grid>
      {useInitialLayout ?
        <Add
          setInitialLayout={setInitialLayout}
          classes={classes}
          invoicingPeriod={invoicingPeriod}
          corporateAction={corporateAction}
          invoiceState={invoiceState}
          updateState={updateState}
        />
        :
        <Edit
          classes={classes}
          invoicingPeriod={invoicingPeriod}
          corporateAction={corporateAction}
          invoiceState={invoiceState}
          updateState={updateState}
          status={invoice?.currentStatus ? invoice.currentStatus : invoice?.statuses?.length ? invoice.statuses[0].status : 'DRAFT'}
          id={invoice?.id}
        />
      }
    </Grid>
  );
}
