import Forbidden from './Pages/Forbidden';
import Landing from './Pages/Landing';

export default function Fallback() {
  const isHome = window.location.pathname === '/';
  return (
    <>
      {isHome ? <Landing/> : <Forbidden/>}
    </>
  );
}
