import MomentAdapter from '@date-io/moment';
import IBAN from 'iban';

export const formatDate = (value, format = 'DD.MM.YYYY') => {
  const moment = new MomentAdapter();
  const date = value ? moment.date(value) : null;
  return date ? moment.format(date, format) : null;
};
export const removeUnderscores = (value) => {
  return value.replace(/[_-]/g, " ");
};
export const formatDecimal = (value) => {
  return Number(value).toFixed(2).replace(',', '.').replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};
export const formatToInteger = (value) => {
  return Number(value).toFixed(0).replace(',', '.').replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};
export const formatClassification = (value) => {
  return value.toLowerCase().replace(/^.{1}/g, value[0].toUpperCase()) + ' (' + value.substr(0, 4) + ')';
};
export const dateIsLaterThanToday = (value) => {
  const varDate = new Date(value);
  varDate.setHours(0, 0, 0, 0);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return varDate > today;
};
export const dateIsTodayOrEarlier = (value) => {
  const varDate = new Date(value);
  varDate.setHours(0, 0, 0, 0);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return varDate <= today;
};
export const inputValidatonToValidDecimal = (value) => {
  const countDecimals = (val) => {
    if (Math.floor(val) === val || !val.includes('.')) {
      return 0;
    }

    return val.toString().split(".")[1].length || 0;
  };

  const cleanedValue = value.replace(/[^\d.-]/g, '');
  if (cleanedValue.includes('.')) {
    if (cleanedValue.indexOf('.', cleanedValue.indexOf('.') + 1) !== -1) {
      const cleanedSubString = cleanedValue.substr(0, cleanedValue.indexOf('.', cleanedValue.indexOf('.') + 1));
      return countDecimals(cleanedSubString) > 2 ? parseFloat(cleanedSubString).toFixed(2) : cleanedSubString;
    }
  }
  return countDecimals(cleanedValue) > 2 ? parseFloat(cleanedValue).toFixed(2) : cleanedValue;
};

export const ceiling = (value, ceiling) => {
  return value > ceiling ? ceiling : value;
};

export const formatIBAN = (value) => {
  const spaceIsLast = value && value[value.length - 1] === ' '
  const res = IBAN.printFormat(value)
  return spaceIsLast ? res + ' ' : res
}
