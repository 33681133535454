import {Box, makeStyles} from '@material-ui/core';
import BeneficiaryInfo from 'App/Pages/Invoice/BeneficiaryInfo';
import CorporateActionDetails from '../../Invoice/CorporateActionDetails';
import PaymentInfo from 'App/Pages/Invoice/PaymentInfo';
import Fields from 'App/Pages/Invoice/Fields';
import AdditionalInformation from 'App/Pages/Invoice/AdditionalInformation';
import DueDate from 'App/Pages/Invoice/DueDate';
import VatExclusion from 'App/Pages/Invoice/VatExclusion';
import ReferenceNumber from 'App/Pages/Invoice/ReferenceNumber';
import PayerData from '../PayerData';
import CommissionFees from './CommissionFees';

const useStyles = makeStyles((theme) => ({
  tableCell: {
    verticalAlign: 'top',
    padding: theme.spacing(1)
  }
}));


export default function Print({corporateAction, invoiceState, updateState, disableInputs}) {
  const classes = useStyles()
  return (
    <Box display="none" displayPrint="inline" style={{width: '100%'}}>
      <table className={classes.printTable} style={{width: '100%'}}>
        <tbody>
          <tr>
            <td width="50%" className={classes.tableCell}>
              <PayerData payer={invoiceState?.payerData} setInvoice={updateState} disableInputs={disableInputs}/>
            </td>
            <td width="50%" className={classes.tableCell}>
              <Fields
                invoiceDate={invoiceState?.invoiceDate}
                supplyOfService={corporateAction?.recordDate}
                disableInputs={disableInputs}/>
            </td>
          </tr>
          <tr>
            <td className={classes.tableCell}>
              <CorporateActionDetails corporateAction={corporateAction}/>
            </td>
            <td className={classes.tableCell}>
              <PaymentInfo
                  paymentDetails={invoiceState?.paymentDetails}
                  setInvoice={updateState}
                  disableInputs={disableInputs}
                />
            </td>
          </tr>
          <tr>
            <td className={classes.tableCell}>
              <ReferenceNumber disableInputs={disableInputs}/>
              <DueDate disableInputs={disableInputs} />
              <VatExclusion disableInputs={disableInputs}/>
              <AdditionalInformation disableInputs={disableInputs}/>
            </td>
            <td className={classes.tableCell}>
              <BeneficiaryInfo
                beneficiary={invoiceState?.beneficiaryData}
                setInvoice={updateState}
                disableInputs={disableInputs}
              />
            </td>
          </tr>
          <tr>
            <td colSpan="2" className={classes.tableCell}>
              <CommissionFees/>
            </td>
          </tr>
        </tbody>
      </table>
    </Box>
  )
}
