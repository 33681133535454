import {useTranslation} from 'react-i18next';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Link from 'Link';
import {Authenticated, Authorize} from '@postinumero/authorization';
import {corporateActions, notifications, tariffs, reusables, invoices} from 'App/Pages';

export default function NavItems(props) {
  const [t] = useTranslation();
  return (
    <List className={props.className}>
      <Authenticated>
        <Authorize allow={["Issuer agent", "Account operator"]}>
          <ListItem button component={Link} to={notifications}>
            <ListItemText primary={t('notifications.title')}/>
          </ListItem>
        </Authorize>
        <Authorize allow={["Issuer agent", "Account operator", "Inspector"]}>
          <ListItem button component={Link} to={corporateActions}>
            <ListItemText primary={t('events.title')}/>
          </ListItem>
        </Authorize>
        <Authorize allow={["Issuer agent", "Account operator", "Inspector", 'Administrator']}>
          <ListItem button component={Link} to={tariffs.replace(':tariffId?', '')}>
            <ListItemText primary={t('tariffs.title')}/>
          </ListItem>
        </Authorize>
        <Authorize allow={["Issuer agent", "Account operator"]}>
          <ListItem button component={Link} to={reusables}>
            <ListItemText primary={t('reusables.naviTitle')}/>
          </ListItem>
        </Authorize>
        <Authorize allow={["Issuer agent", "Account operator", "Inspector"]}>
          <ListItem button component={Link} to={invoices}>
            <ListItemText primary={t('invoices.naviTitle')}/>
          </ListItem>
        </Authorize>
      </Authenticated>
      {/* {<Authorize allow="admin">
                <Divider />
                <ListItem button component={Link} to={admin}>
                <ListItemIcon>
                    <HammerWrench fontSize="large" />
                </ListItemIcon>
                <ListItemText primary={t('admin.title')} />
                </ListItem>
            </Authorize>} */}
    </List>
  );
}
