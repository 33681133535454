import {useContext} from "react";
import {ValidationContext} from "shared/useValidation";
import {useTranslation} from 'react-i18next'
import SmallTextField from "shared/FormComponents/SmallTextField";
import {Typography, Box} from "@material-ui/core";

export default function VatExclusion({disableInputs}) {
  const [t] = useTranslation();
  const {getInputProps, values} = useContext(ValidationContext);
  return (
    <>
      {(values?.referenceNumber && disableInputs) &&
      <Box>
        <Typography variant="subtitle1" color="primary">{t("invoice.referenceNumber")}</Typography>
        <Typography variant="body1">{values.referenceNumber}</Typography>
      </Box>
      }
      {!disableInputs && <>
        <SmallTextField
          className={"no-print"}
          label={t("invoice.referenceNumber")}
          inputProps={{maxLength: 20}}
          onInput={(e) => e.target.value = e.target.value.slice(0, 20)}
          disabled={disableInputs}
          {...getInputProps("referenceNumber")}
        />
        <br />
        <Box display="block" displayPrint="none">
          <Typography variant="caption">{t("invoice.generated")}</Typography>
        </Box>
      </>}
    </>
  );
}
