import HasRelatedData from './HasRelatedData';
import {Alert} from '@material-ui/lab';
import BackButton from 'App/BackButton';

export default function HasItemId({invoice}) {
  return (
    <>
      {invoice ?
        <HasRelatedData invoicingPeriodId={invoice?.invoicingPeriodId} invoice={invoice}/>
        :
        <>
          <Alert severity="warning">
            Invoice not found.
          </Alert>
          <BackButton/>
        </>
      }
    </>
  );
}
