import {makeStyles} from '@material-ui/core/styles';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import CallMergeOutlinedIcon from '@material-ui/icons/CallMergeOutlined';
import CallMadeOutlinedIcon from '@material-ui/icons/CallMadeOutlined';
import CallReceivedOutlinedIcon from '@material-ui/icons/CallReceivedOutlined';
import {Grid} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.primary.light,
    color: 'white',
    margin: '0',
    width: '100vw',
    left: 0,
    textAlign: 'center',
    paddingBottom: '50px',
    position: 'absolute',
    bottom: '10vh',
    '& h1': {
      fontSize: '3.4rem'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '7.1rem',
    }
  }
}));
export default function Landing() {
  const classes = useStyles();
  return (
    <>
      <Grid container justify="center" className={classes.root}>
        <Grid item sm={12}><h1>commission fee invoicing</h1></Grid>
        <Grid item sm={2}><WorkOutlineIcon/></Grid>
        <Grid item sm={2}><AssignmentOutlinedIcon/></Grid>
        <Grid item sm={2}><CallMergeOutlinedIcon/></Grid>
        <Grid item sm={2}><CallMadeOutlinedIcon/></Grid>
        <Grid item sm={2}><CallReceivedOutlinedIcon style={{transform: ' rotate(270deg)'}}/></Grid>
      </Grid>
    </>
  );
}
