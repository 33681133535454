import {useCA} from 'api';
import Form from '../Form';
import Status from '../../CorporateAction/Status';


export default function HasCA({caId}) {
  const item = useCA(caId);

  return (
    <>
      <Status/>
      <Form corporateAction={item}/>
    </>
  );
}
