import {TableRow, TableCell} from "@material-ui/core";
import {formatDecimal, formatToInteger} from 'shared/utility';
import {useDeepCompareEffect} from 'shared/useDeepCompareEffect';
import RemoveRowIcon from '../RemoveRowIcon';

export const getCommissionBasedData = (invoiceLine) => {
  const commissionFee = invoiceLine.commissionFee
  const netAmount = isNaN(commissionFee.amount) ? 0 : commissionFee.amount;
  const vatEUR = isNaN(netAmount * (commissionFee.vat / 100)) ? 0 : netAmount * (commissionFee.vat / 100);
  const totalEUR = isNaN(netAmount + vatEUR) ? 0 : netAmount + vatEUR;
  return {netAmount, vatEUR, totalEUR, commissionFee}
}

export default function CommissionBased({invoiceLine, dataIndex, updateLine, disableInputs}) {
  const {netAmount, vatEUR, totalEUR, commissionFee} = getCommissionBasedData(invoiceLine)

  useDeepCompareEffect(() => {
    updateLine(dataIndex, invoiceLine);
  }, [invoiceLine]);

  const onRemove = () => {
    updateLine(dataIndex, null)
  }

  return (
    <>
      <TableRow>
        <TableCell>
          {commissionFee.description}
        </TableCell>
        <TableCell align={"right"}/>
        <TableCell align={"right"}/>
        <TableCell align={"right"}/>
        <TableCell align={"right"}>
          {formatDecimal(netAmount)}
        </TableCell>
        <TableCell align={"right"}>
          {formatToInteger(commissionFee.vat)}
        </TableCell>
        <TableCell align={"right"}>
          {formatDecimal(vatEUR)}
        </TableCell>
        <TableCell align={"right"}>
          {formatDecimal(totalEUR)}
        </TableCell>
        {!disableInputs && <TableCell align="center">
          <RemoveRowIcon onRemove={onRemove} description={invoiceLine.description || invoiceLine.commissionFee?.description || 'unnamed'} />
        </TableCell>}
      </TableRow>
      <TableRow style={{height: '2px', background: 'white'}}/>
    </>
  );
}
