import {useState} from 'react';
import {useTranslation} from "react-i18next";
import AddDetailsDialog from "./AddDetailsDialog";
import Details from './Details';
import useModal from 'shared/useModal';
import {formatIBAN} from 'shared/utility';
import {ValidationProvider} from 'shared/useValidation';
import {useReusablePaymentDetails} from 'api';

export default function PaymentInfo({paymentDetails, setInvoice, disableInputs}) {
  const [t] = useTranslation();
  const {open, handleOpen, handleClose} = useModal();

  const reusablePaymentDetails = useReusablePaymentDetails()
  const sortedPaymentDetails = reusablePaymentDetails.sort((a, b) => a.dataName.localeCompare(b.dataName));
  const [selected, setSelected] = useState(sortedPaymentDetails.length ? sortedPaymentDetails[0].id : -1)
  const details = [{ id: -1, dataName: t("invoice.manually")}].concat(sortedPaymentDetails)

  const config = {
    inputs: {
      bankAccount: {
        isRequired: {message: t('validation.isRequired', {name: t('paymentDetails.bankAccount')})},
        isValidIBAN: {message: t('validation.isValidIBAN', {name: t('paymentDetails.bankAccount')})},
        initialValue: paymentDetails?.bankAccount ?? '',
        mask: formatIBAN
      },
      swift: {
        isRequired: {message: t('validation.isRequired', {name: t('paymentDetails.swift')})},
        isValidBIC: {message: t('validation.isValidBIC', {name: t('paymentDetails.swift')})},
        initialValue: paymentDetails?.swift ?? ''
      },
      businessId: {
        isRequired: {message: t('validation.isRequired', {name: t('paymentDetails.businessId')})},
        initialValue: paymentDetails?.businessId ?? ''
      },
      vatId: {
        isRequired: {message: t('validation.isRequired', {name: t('paymentDetails.vatId')})},
        initialValue: paymentDetails?.vatId ?? ''
      }
    },
    onClick: (state) => {
      if (selected > -1) {
        const detail = reusablePaymentDetails.find(detail => detail.id === selected)
        setInvoice(detail.paymentDetails, "paymentDetails")
        handleClose();
      } else if (!state.errors || Object.keys(state.errors).length === 0) {
        setInvoice(state.values, "paymentDetails");
        handleClose();
      }
    }
  };

  return (
    <ValidationProvider config={config}>
      {!disableInputs && <AddDetailsDialog
        onClose={handleClose}
        open={open}
        selected={selected}
        setSelected={setSelected}
        reusablePaymentDetails={details}
      />}
      <Details paymentDetails={paymentDetails} openDialog={handleOpen} disableInputs={disableInputs}/>
    </ValidationProvider>
  );
}
