import {useTranslation} from 'react-i18next';
import useModal from 'shared/useModal';
import {
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Typography,
  Link
} from '@material-ui/core';
import React, {useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import {Authorize} from '@postinumero/authorization';
import {useCorporateActionEventTypes} from 'api';
import FeeDetails from "./FeeDetails";
import {putTariff} from 'api';
import {tariffs} from "../../index";
import {useHistory} from 'react-router-dom';
import {DRAFT} from "shared/Statuses";
import {useDeepCompareEffect} from "../../../../shared/useDeepCompareEffect";
import {MuiThemeProvider} from '@material-ui/core';
import theme from './theme';
import useSnackBar from 'shared/SnackBar/useSnackBar';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  container: {
    maxHeight: 600,
  }
});

const groupBy = (list, keyGetter) => {
  const map = new Map();
  list.forEach(item => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

const columnWidths = ['15%', '28%', '21%', '8%', '12%', '16%'];
const innerColumnWidths= ['49%', '37%', '14%'];

const resolveStatus = (tariff) => {
  return tariff?.statuses?.length > 0 ? tariff?.statuses[0].status : 'NOT_SAVED';
};

export default function FeeTable({activeTariff}) {
  const [t] = useTranslation();
  const history = useHistory();

  const classes = useStyles();
  const [feeInDialog, setFeeInDialog] = useState(null);
  const {open, handleOpen, handleClose} = useModal();
  const corporateActionEventTypes = useCorporateActionEventTypes();
  const [grouped, setGrouped] = useState(groupBy([], fee => fee.corporateActionEventType.id));
  const status = resolveStatus(activeTariff);
  const {showError} = useSnackBar();

  useDeepCompareEffect(() => {
    setGrouped(groupBy(activeTariff?.commissionFees ?? [], fee => fee.corporateActionEventType.id));
  }, [activeTariff]);

  const createRow = (key, value, t) => {
    const sortedRows = value?.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
    return (
      <TableRow key={key.id}>
        <TableCell width={'25%'} style={{verticalAlign: 'top'}}>
          <Typography variant={"h3"} style={{display: 'inline'}}>
            {key.type + ' (' + key.eventClassification.slice(0, 4) + ') '}
          </Typography>
          {status === DRAFT &&
          <Authorize allow={'Administrator'}>
            <Link component="button" type="button" onClick={() => initializeAndOpenDialog(key)}>
              <Typography variant="overline">{t("tariffs.addFee")}</Typography>
            </Link>
          </Authorize>}
          <Typography variant={"body1"}>
            {key.description}
          </Typography>
        </TableCell>
        <TableCell width={'75%'}>
          <Table width={'100%'}>
            <TableBody>
              {value && sortedRows.map((row, index) => createInnerRow(row, t, index))}
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    );
  };

  const createInnerRow = (row, t, index) => {
    switch (row.type) {
      case "QUANTITY_BASED":
        return (
          <TableRow key={index}>
            <TableCell width={columnWidths[0]}>
              <Typography variant={"subtitle1"} color={"primary"}>
                {t("tariffs.feeDetails.type")}
              </Typography>
              <Typography variant={"body1"}>
                {t("tariffs.feeType.quantity")}
              </Typography>
            </TableCell>
            <TableCell width={columnWidths[1]}>
              <Typography variant={"subtitle1"} color={"primary"}>
                {t("tariffs.feeDetails.description")}
              </Typography>
              <Typography variant={"body1"}>
                {row.description}
              </Typography>
            </TableCell>
            <TableCell width={columnWidths[2]}>
              <Typography variant={"subtitle1"} color={"primary"}>
                {t("tariffs.feeDetails.quantityPriceNoVat")}
              </Typography>
              <Typography variant={"body1"}>
                {'à ' + row.pricePerQuantity + ' EUR'}
              </Typography>
            </TableCell>
            <TableCell width={columnWidths[3]}>
              <Typography variant={"subtitle1"} color={"primary"}>
                {t("tariffs.feeDetails.vat")}
              </Typography>
              <Typography variant={"body1"}>
                {row.vat + '%'}
              </Typography>
            </TableCell>
            <TableCell width={columnWidths[4]}>
              <Typography variant={"subtitle1"} color={"primary"}>
                {t("tariffs.feeDetails.minimumPrice")}
              </Typography>
              <Typography variant={"body1"}>
                {'min ' + row.minimumPrice + ' EUR'}
              </Typography>
            </TableCell>
            {status === DRAFT &&
            <Authorize allow={'Administrator'}>
              <TableCell style={{verticalAlign: 'top'}}  width={columnWidths[5]}>
                <Link component="button" type="button" onClick={() => initializeAndOpenDialog(null, row)}>
                  <Typography variant="overline">{t("tariffs.editFee")}</Typography>
                </Link>
                |
                <Link component="button" type="button" onClick={() => removeFee(row.id)}>
                  <Typography variant="overline">{t("tariffs.removeFee")}</Typography>
                </Link>
              </TableCell>
            </Authorize>}
          </TableRow>
        );
      case "COMMISSION_BASED":
        return (
          <TableRow key={index}>
            <TableCell  width={columnWidths[0]}>
              <Typography variant={"subtitle1"} color={"primary"}>
                {t("tariffs.feeDetails.type")}
              </Typography>
              <Typography variant={"body1"}>
                {t("tariffs.feeType.commission")}
              </Typography>
            </TableCell>
            <TableCell width={columnWidths[1]}>
              <Typography variant={"subtitle1"} color={"primary"}>
                {t("tariffs.feeDetails.description")}
              </Typography>
              <Typography variant={"body1"}>
                {row.description}
              </Typography>
            </TableCell>
            <TableCell width={columnWidths[2]}>
              <Typography variant={"subtitle1"} color={"primary"}>
                {t("tariffs.feeDetails.priceNoVat")}
              </Typography>
              <Typography variant={"body1"}>
                {row.amount + ' EUR / commission'}
              </Typography>
            </TableCell>
            <TableCell width={columnWidths[3]}>
              <Typography variant={"subtitle1"} color={"primary"}>
                {t("tariffs.feeDetails.vat")}
              </Typography>
              <Typography variant={"body1"}>
                {row.vat + '%'}
              </Typography>
            </TableCell>
            <TableCell width={columnWidths[4]}/>
            {status === DRAFT &&
            <Authorize allow={'Administrator'}>
              <TableCell style={{verticalAlign: 'top'}} width={columnWidths[5]}>
                <Link component="button" type="button" onClick={() => initializeAndOpenDialog(null, row)}>
                  <Typography variant="overline">{t("tariffs.editFee")}</Typography>
                </Link>
                |
                <Link component="button" type="button" onClick={() => removeFee(row.id)}>
                  <Typography variant="overline">{t("tariffs.removeFee")}</Typography>
                </Link>
              </TableCell>
            </Authorize>}
          </TableRow>
        );
      case "SELECTION_BASED":
        return (
          <TableRow key={index}>
            <TableCell style={{verticalAlign: 'top'}} width={columnWidths[0]}>
              <Typography variant={"subtitle1"} color={"primary"}>
                {t("tariffs.feeDetails.type")}
              </Typography>
              <Typography variant={"body1"}>
                {t("tariffs.feeType.selection")}
              </Typography>
            </TableCell>
            <TableCell colSpan={3} width={'57%'}>
              <Typography variant={"subtitle1"} color={"primary"}>
                {t("tariffs.feeDetails.description")}
              </Typography>
              <Typography variant={"body1"}>
                {row.description}
              </Typography>
              <Table width={'100%'}>
                <TableBody>
                  {row.options.map((row, index) => createSelectionRow(row, t, index))}
                </TableBody>
              </Table>
            </TableCell>
            <TableCell  width={columnWidths[4]}/>
            {status === DRAFT &&
            <Authorize allow={'Administrator'}>
              <TableCell style={{verticalAlign: 'top'}}  width={columnWidths[5]}>
                <Link component="button" type="button" onClick={() => initializeAndOpenDialog(null, row)}>
                  <Typography variant="overline">{t("tariffs.editFee")}</Typography>
                </Link>
                |
                <Link component="button" type="button" onClick={() => removeFee(row.id)}>
                  <Typography variant="overline">{t("tariffs.removeFee")}</Typography>
                </Link>
              </TableCell>
            </Authorize>}
          </TableRow>
        );
      case "NEGOTIATED":
        return (
          <TableRow key={index}>
            <TableCell  width={columnWidths[0]}>
              <Typography variant={"subtitle1"} color={"primary"}>
                {t("tariffs.feeDetails.type")}
              </Typography>
              <Typography variant={"body1"}>
                {t("tariffs.feeType.negotiated")}
              </Typography>
            </TableCell>
            <TableCell width={columnWidths[1]}/>
            <TableCell width={columnWidths[2]}/>
            <TableCell width={columnWidths[3]}/>
            <TableCell width={columnWidths[4]}/>
            {status === DRAFT &&
            <Authorize allow={'Administrator'}>
              <TableCell style={{verticalAlign: 'top'}} width={columnWidths[5]}>
                <Link component="button" type="button" onClick={() => initializeAndOpenDialog(null, row)}>
                  <Typography variant="overline">{t("tariffs.editFee")}</Typography>
                </Link>
                |
                <Link component="button" type="button" onClick={() => removeFee(row.id)}>
                  <Typography variant="overline">{t("tariffs.removeFee")}</Typography>
                </Link>
              </TableCell>
            </Authorize>}
          </TableRow>
        );
      default:
        return null;
    }
  };

  const createSelectionRow = (row, t, index) => {
    return (
      <TableRow key={index}>
        <TableCell style={{paddingLeft: 0}} width={innerColumnWidths[0]}>
          <Typography variant={"subtitle1"} color={"primary"}>
            {t("tariffs.feeDetails.selection")}
          </Typography>
          <Typography variant={"body1"}>
            {row.description}
          </Typography>
        </TableCell>
        <TableCell width={innerColumnWidths[1]}>
          <Typography variant={"subtitle1"} color={"primary"}>
            {t("tariffs.feeDetails.priceNoVat")}
          </Typography>
          <Typography variant={"body1"}>
            {row.amount + ' EUR / commission'}
          </Typography>
        </TableCell>
        <TableCell width={innerColumnWidths[2]}>
          <Typography variant={"subtitle1"} color={"primary"}>
            {t("tariffs.feeDetails.vat")}
          </Typography>
          <Typography variant={"body1"}>
            {row.vat + '%'}
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  const initializeAndOpenDialog = (caeType, fee) => {
    if (fee) {
      setFeeInDialog(fee);
    } else {
      setFeeInDialog({
        corporateActionEventType: caeType,
        description: '',
        pricePerQuantity: '',
        minimumPrice: '',
        vat: '',
        amount: '',
        options: [
          {
            id: null,
            description: '',
            amount: '',
            vat: ''
          }
        ]
      });
    }

    handleOpen();
  };

  async function removeFee(id) {
    const newFees = activeTariff.commissionFees;
    newFees.splice(activeTariff.commissionFees.findIndex(fee => fee.id === id), 1);

    const tariffToSave = {
      ...activeTariff,
      commissionFees: newFees
    };

    await putTariff(tariffToSave).then((response) => {
      const redirectId = response?.data?.id;
      history.push({pathname: tariffs.replace(":tariffId?", redirectId)});
      window.location.reload();
    }).catch((err) => {
      showError(err.response)
    });
  }

  return (
    <MuiThemeProvider theme={theme}>
      <FeeDetails
        handleClose={handleClose}
        open={open}
        fee={feeInDialog}
        activeTariff={activeTariff}
      />
      <TableContainer component={Paper} className={classes.container}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell variant={"head"}>
                <Typography variant={"h3"}>
                  {t("tariffs.actionEventType")}
                </Typography>
              </TableCell>
              <TableCell variant={"head"}>
                <Typography variant={"h3"}>
                  {t("tariffs.fees")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {corporateActionEventTypes.map(caet => createRow(caet, grouped.get(caet.id), t))}
          </TableBody>
        </Table>
      </TableContainer>
    </MuiThemeProvider>
  );
}
