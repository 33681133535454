import BackButton from 'App/BackButton';
import {useTranslation} from 'react-i18next';
import Alert from "@material-ui/lab/Alert";
import {useKeycloak} from '@react-keycloak/web';
import {Typography} from '@material-ui/core';

export default function Forbidden() {
  const [t] = useTranslation('error');
  const {keycloak} = useKeycloak();
  return (
    <>
      <Alert severity="error">
        <Typography variant="h3">{t('forbidden')}</Typography>
        {!keycloak.authenticated && t("login")}
      </Alert>
      {keycloak.authenticated && <BackButton/>}
    </>
  );
}
