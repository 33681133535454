import {Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import MUIDataTable from 'mui-datatables';
import {MuiThemeProvider} from '@material-ui/core';
import {theme as tableTheme} from './theme';
import CustomTableRow from './CustomTableRow';

export default function PaymentTable({invoices}) {
  const [t] = useTranslation();

  const styles = {
    verticalAlign: 'bottom',
    paddingRight: 0,
    whiteSpace: 'pre-line'
  };
  const rightAlign = () => {
    return {
      align: 'right',
      style: styles,
    }
  };

  const columns = [
    {label: t("payments.beneficiary"), name: 'beneficiary', options: {setCellHeaderProps: rightAlign}},
    {label: t("payments.invoiceNumber"), name: 'invoiceNumber', options: {setCellHeaderProps: rightAlign}},
    {label: t("payments.swift"), name: 'swift', options: {setCellHeaderProps: rightAlign}},
    {label: t("payments.bankAccount"), name: 'bankAccount', options: {setCellHeaderProps: rightAlign}},
    {label: t("payments.referenceNumber"), name: 'referenceNumber', options: {setCellHeaderProps: rightAlign}},
    {label: t("payments.total"), name: 'total', options: {setCellHeaderProps: rightAlign}}
  ];

  const options = {
    pagination: false,
    print: false,
    download: false,
    filter: false,
    search: false,
    sort: false,
    responsive: 'standard',
    viewColumns: false,
    selectableRows: 'none',
    elevation: 0,
    isRowSelectable: () => false,
    customRowRender: (data, dataIndex) => {
      return <CustomTableRow key={dataIndex} invoice={invoices[dataIndex]} />
    }
  };

  return (
    <div style={{width: '100%'}}>
      <Typography variant="h3" style={{marginBottom: '10px'}}>{t('payments.paymentsTitle')}</Typography>
      <MuiThemeProvider theme={tableTheme}>
        <MUIDataTable
          columns={columns}
          options={options}
          data={invoices}
        />
      </MuiThemeProvider>
    </div>
  )
}
