import {useContext} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {makeStyles} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import {useTranslation} from 'react-i18next';
import {ValidationContext} from 'shared/useValidation';
import {ValidationErrors} from 'shared/FormComponents';
import Form from './Form';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialogContent-root': {
      padding: '20px 8px 0px 8px',
      '& > *': {
        marginBottom: theme.spacing(3)
      },
      overflowY: 'visible'
    }
  }
}));
export default function DetailsDialog({open, onClose}) {
  const [t] = useTranslation();
  const classes = useStyles();
  const {getActionProps} = useContext(ValidationContext);
  return (
    <Dialog className={classes.root} onClose={onClose} open={open}>
      <DialogTitle disableTypography>
        <Typography variant="h1">{t("reusables.payerAdd")}</Typography>
      </DialogTitle>
      <DialogContent>
        <ValidationErrors/>
        <Form />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={() => {
          onClose();
        }}>
          {t('form.cancel')}
        </Button>
        <Button
          color="secondary"
          variant="contained"
          {...getActionProps()}
        >
          {t('form.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
