import React from "react";
import {
  Typography,
  makeStyles,
  Grid,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  InputLabel
} from "@material-ui/core";
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: theme.spacing(2),
  },
  align: {
    position: 'absolute',
    left: '0',
    top: theme.mixins.toolbar.minHeight + 50,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: 0,
      marginBottom: '30px'
    }
  },
  subheader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'black',
    '& button': {}
  },
  bottomPad: {
    paddingBottom: '0.5em'
  },
  rightPad: {
    paddingRight: '1em'
  },
  blueRadio: {
    root: {
      color: "#0062A3",
      '&$checked': {
        color: "#0062A3"
      },
    },
  }
}));

export default function TariffSearch({issuerAgents, validityFilter, iaFilter, setIAFilter, setValidityFilter}) {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <Grid container justify={"flex-end"}
          className={[classes.align, "no-print"].join(" ")}>
      <Grid item container xs={12} md={3}>
        <Grid item xs={12} className={classes.bottomPad}>
          <Typography variant={"h3"}>Search tariffs</Typography>
        </Grid>
        <Grid item xs={5} className={classes.rightPad}>
          <FormControl fullWidth variant="outlined" style={{marginTop: '0.5rem'}}>
            <InputLabel id="label-selected">{'Account operator'}</InputLabel>
            <Select
              labelId="label-selected"
              onChange={(event) => setIAFilter(event.target.value)}
              label={'Account operator'}
              value={iaFilter}
              fullWidth
            >
              <MenuItem key={'all'} value={'all'}>{t("tariffs.searchAll")}</MenuItem>
              {issuerAgents.map(issuerAgent =>
                <MenuItem key={issuerAgent} value={issuerAgent}>{issuerAgent}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={7}>
          <Typography variant={"subtitle1"} color={"primary"}>
            {t("tariffs.searchShow")}
          </Typography>
          <RadioGroup value={validityFilter}
                      onChange={(event) => setValidityFilter(event.target.value)}>
            <FormControlLabel value="valid" control={<Radio size={"small"} className={classes.blueRadio}/>} label={t("tariffs.showValid")}
                              style={{height: 24}}/>
            <FormControlLabel value="all" control={<Radio size={"small"} className={classes.blueRadio}/>} label={t("tariffs.showAll")}
                              style={{height: 24}}/>
          </RadioGroup>
        </Grid>
      </Grid>
    </Grid>
  );
}
