import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {Authorize} from '@postinumero/authorization';
import Page from '../../../Page';
import Forbidden from 'App/Pages/Forbidden';
import HasCA from './HasCA';


export default function NewInvoicingPeriod() {
  const [t] = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const caId = params.get('ca');

  return (
    <Page title={t("invoicingPeriod.addTitle")}>
      <Authorize allow="Issuer agent" fallback={<Forbidden/>}>
        <HasCA caId={caId}/>
      </Authorize>
    </Page>
  );
}
