import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import {useUser} from '@postinumero/authorization';
import {Typography, DialogTitle} from '@material-ui/core';
import localStorageJson from '@postinumero/storage/localStorageJson';
import useLocalStorageJsonValue from '@postinumero/storage/useLocalStorageJsonValue';


const useStyles = makeStyles(theme => ({
  root: {
    '& p': {
      marginBottom: theme.spacing(3)
    },
    '& .MuiButton-root': {
      marginBottom: theme.spacing(2),
    },
  },
  title: {
    paddingBottom: 0
  }
}));
export default function SelectRoleDialog({open, onClose}) {
  const [t] = useTranslation();
  const classes = useStyles();
  const user = useUser();
  const roleInfo = useLocalStorageJsonValue('roleInfo');
  return (
    <Dialog className={classes.root} open={roleInfo?.options?.length > 1 && user?.roles.length !== 1}>
      <DialogTitle className={classes.title}>{t('role.title')}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">Select the user role you want to use the system as.</Typography>
        {roleInfo?.options?.map((item, i) => {
          return <Button
            color="primary"
            fullWidth
            variant="contained"
            key={i}
            onClick={() => {
              localStorageJson.setItem('roleInfo', {...roleInfo, selected: item});
            }}
          >
            {item}
          </Button>
        })}
      </DialogContent>
    </Dialog>
  );
}
