import {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {Grid, TextField, Typography} from '@material-ui/core';
import {DateInput, DeliveryDetails} from 'shared/FormComponents';
import {ValidationContext} from 'shared/useValidation';
import Details from 'App/Pages/CorporateActions/Details';
import {formatDate} from 'shared/utility';
import {LOCKED, INVOICINGENABLED, PUBLISHED, INVALID} from 'shared/Statuses'
import ReusableSelect from './ReusableSelect'
import SelectedDetail from './SelectedDetail'

export default function Fields({corporateAction, status, details, selected, setSelected}) {
  const [t] = useTranslation();
  const {getInputProps, setValue} = useContext(ValidationContext);
  const disableInputs = status === LOCKED || status === PUBLISHED || status === INVOICINGENABLED || status === INVALID;
  return (
    <>
      <Grid item container lg={4} md={6} spacing={2} alignContent={'flex-start'}>
        <Grid item xs={12}>
          <Details corporateAction={corporateAction} show={true}/>
        </Grid>
        <Grid item sm={12}>
          <Typography variant="h3">{t("invoicingPeriod.formGroupInformation")}</Typography>
        </Grid>
        <Grid item sm={6} xs={12}>
          <DateInput
            label={t('invoicingPeriod.startDate')}
            required
            helperText={t("invoicingPeriod.startDateHelper")}
            {...getInputProps('startDate', {
              onChange: (event) => {
                setValue('startDate', formatDate(event, 'YYYY-MM-DD'))
              }
            })}
            disabled={disableInputs}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <DateInput
            label={t('invoicingPeriod.endDate')}
            required
            helperText={t("invoicingPeriod.endDateHelper")}
            {...getInputProps('endDate', {
              onChange: (event) => {
                setValue('endDate', formatDate(event, 'YYYY-MM-DD'))
              }
            })}
            disabled={disableInputs && status !== LOCKED && status !== PUBLISHED && status !== INVOICINGENABLED}
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            variant="outlined"
            label={t('invoicingPeriod.instructions')}
            multiline
            rows={4}
            fullWidth
            helperText={t('invoicingPeriod.instructionsHelper')}
            {...getInputProps('instructionsForAO')}
            disabled={disableInputs}
          />
        </Grid>
      </Grid>
      <Grid item container lg={4} md={6} spacing={2} alignContent={'flex-start'}>
        <Grid item sm={12}>
          <Typography variant="h3">{t("invoicingPeriod.formGroupPayer")}</Typography>
        </Grid>
        <Grid item sm={12}>
          <ReusableSelect details={details} selected={selected} setSelected={setSelected}
                          disableInputs={disableInputs}/>
        </Grid>
        {selected === -1 ? <>
          <Grid item sm={12}>
            <TextField
              variant="outlined"
              required
              label={t('invoicingPeriod.payerName')}
              fullWidth
              {...getInputProps('payerName')}
              disabled={disableInputs}
            />
          </Grid>
          <Grid item sm={12}>
            <TextField
              variant="outlined"
              fullWidth
              label={t("deliveryDetails.payerNameSupplement")}
              helperText={t("deliveryDetails.nameSuplementHelper")}
              {...getInputProps('payerNameSupplement')}
              disabled={disableInputs}
            />
          </Grid>
          <DeliveryDetails
            disableInputs={disableInputs}
          />
        </> : <Grid item sm={12}>
          <SelectedDetail payer={details.find(detail => detail.id === selected)}/>
        </Grid>
        }
        <Grid item sm={12}>
          <Typography variant="h3">{t("invoicingPeriod.dueDateInformation")}</Typography>
        </Grid>
        <Grid item sm={6} xs={12}>
          <DateInput
            label={t('invoicingPeriod.dueDateTitle')}
            required
            {...getInputProps('dueDate', {
              onChange: (event) => {
                setValue('dueDate', formatDate(event, 'YYYY-MM-DD'))
              }
            })}
            disabled={disableInputs}
          />
        </Grid>
      </Grid>
    </>
  );
}
