import {Box, TableRow, TableCell, MenuItem, Select, Typography, Grid, MuiThemeProvider} from "@material-ui/core";
import {formatDecimal, formatToInteger} from 'shared/utility';
import {useDeepCompareEffect} from 'shared/useDeepCompareEffect';
import {theme as tableTheme} from "../theme";
import RemoveRowIcon from '../RemoveRowIcon';

export const getSelectBasedData = (invoiceLine) => {
  const commissionFee = invoiceLine.commissionFee;
  const selectedValue = invoiceLine?.commissionFee?.options?.find(option => option.id === invoiceLine.selectionId);
  const netAmount = isNaN(selectedValue?.amount) ? 0 : selectedValue.amount;
  const vatEUR = !selectedValue || isNaN(netAmount * (selectedValue.vat / 100)) ? 0 : netAmount * (selectedValue.vat / 100);
  const totalEUR = isNaN(netAmount + vatEUR) ? 0 : netAmount + vatEUR;
  return {netAmount, vatEUR, totalEUR, commissionFee}
}

export default function SelectBasedFee({invoiceLine, dataIndex, updateLine, disableInputs}) {
  const selectedValue = invoiceLine?.commissionFee?.options?.find(option => option.id === invoiceLine.selectionId);
  const {netAmount, vatEUR, totalEUR, commissionFee} = getSelectBasedData(invoiceLine)

  useDeepCompareEffect(() => {
    updateLine(dataIndex, invoiceLine);
  }, [invoiceLine]);

  const onSelectChange = (event) => {
    updateLine(dataIndex, {...invoiceLine, selectionId: event.target.value})
  };

  const onRemove = () => {
    updateLine(dataIndex, null)
  }

  return (
    <>
      <MuiThemeProvider theme={tableTheme}>
        <TableRow>
          <TableCell>
            <Grid item container xs={12}>
              <Grid item xs={7}>
                <Typography>{commissionFee.description + "  "}</Typography>
              </Grid>
              <Grid item xs={5}>
                {disableInputs
                  ? <Box>{invoiceLine.commissionFee.options.find(option => option.id === invoiceLine.selectionId).description}</Box>
                  : <Select variant={"outlined"} disabled={disableInputs} value={invoiceLine.selectionId} onChange={onSelectChange} fullWidth>
                    {commissionFee.options.map(option =>
                      <MenuItem key={option.id} value={option.id}>{option.description}</MenuItem>)}
                  </Select>
                }
              </Grid>
            </Grid>
          </TableCell>
          <TableCell align={"right"}/>
          <TableCell align={"right"}/>
          <TableCell align={"right"}/>
          <TableCell align={"right"}>
            {formatDecimal(netAmount)}
          </TableCell>
          <TableCell align={"right"}>
            {formatToInteger(isNaN(selectedValue?.vat) ? 0 : selectedValue.vat)}
          </TableCell>
          <TableCell align={"right"}>
            {formatDecimal(vatEUR)}
          </TableCell>
          <TableCell align={"right"}>
            {formatDecimal(totalEUR)}
          </TableCell>
          {!disableInputs && <TableCell align="center">
            <RemoveRowIcon onRemove={onRemove} description={invoiceLine.description || invoiceLine.commissionFee?.description || 'unnamed'} />
          </TableCell>}
        </TableRow>
        <TableRow style={{height: '2px', background: 'white'}}/>
      </MuiThemeProvider>
    </>
  );
}
