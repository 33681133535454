import {Button, Grid} from '@material-ui/core';
import {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {ValidationContext} from 'shared/useValidation';
import {DRAFT, LOCKED, PUBLISHED, READYTOPUBLISH, INVOICINGENABLED} from 'shared/Statuses';
import useModal from 'shared/useModal';
import LockReverseConfirmationDialog from './LockReverseConfirmationDialog';
import InvalidateConfirmationDialog from './InvalidateConfirmationDialog';

export default function Actions({status}) {
  const {getActionProps, getDeletionConfirmationProp} = useContext(ValidationContext);
  const {open, handleOpen, handleClose} = useModal();
  const {open: show, handleOpen: handleShow, handleClose: handleHide} = useModal();
  const [t] = useTranslation();
  const history = useHistory();

  return (
    <>
      <LockReverseConfirmationDialog
        open={open}
        onClose={handleClose}
      />
      <InvalidateConfirmationDialog
        open={show}
        onClose={handleHide}
      />
      <Grid item container justify="flex-end" lg={8} spacing={5}>
        <Grid item>
          <Button
            color="primary"
            size="large"
            variant="outlined"
            onClick={history.goBack}>
            {t('form.cancel')}
          </Button>
        </Grid>
        {status && (status === INVOICINGENABLED || status === LOCKED) &&
          <Grid item>
            <Button
              size="large"
              variant="outlined"
              color="primary"
              onClick={handleShow}
            >{t('invoicingPeriod.invalidate')}</Button>
          </Grid>}
        {!status || status !== PUBLISHED ?
          <>
            {status && (status === DRAFT || status === READYTOPUBLISH) &&
            <Grid item>
              <Button
                color="primary"
                size="large"
                variant="outlined"
                {...getDeletionConfirmationProp()}
              >
                {t('form.remove')}
              </Button>
            </Grid>}
            {status && (status === 'NOT_SAVED' || status === DRAFT || status === READYTOPUBLISH) &&
            <Grid item>
              <Button
                size="large"
                variant="outlined"
                color="primary"
                {...getActionProps(DRAFT)}
              >{t('form.saveDraft')}</Button>
            </Grid>}
            {status && (status === DRAFT || status === 'NOT_SAVED' || status === READYTOPUBLISH) &&
            <Grid item>
              <Button
                size="large"
                variant="contained"
                color="secondary"
                {...getActionProps(READYTOPUBLISH)}
              >{t('event.readyToPublish')}</Button>
            </Grid>}
            {status && (status === DRAFT || status === READYTOPUBLISH || status === 'NOT_SAVED') &&
            <Grid item>
              <Button
                size="large"
                variant="contained"
                color="secondary"
                {...getActionProps(PUBLISHED)}
              >{t('form.savePublish')}</Button>
            </Grid>}
          </> : null}
          {status && (status === PUBLISHED || status === LOCKED || status === INVOICINGENABLED) &&
            <Grid item>
              <Button
                size="large"
                variant="contained"
                color="secondary"
                onClick={handleOpen}
              >{t('form.save')}</Button>
            </Grid>}
      </Grid>
    </>
  );
}
