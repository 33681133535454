import {useReducer, useMemo, createContext} from 'react';
import validationReducer from './validationReducer';
import * as validators from './validators';
import {useDeepCompareEffect} from 'shared/useDeepCompareEffect';

export const useValidation = config => {
  const [state, dispatch] = useReducer(validationReducer, getInitialState(config));
  if (typeof config === 'function') {
    config = config(state.values);
  }
  useDeepCompareEffect(() => {
    const errors = validateInputs(state.values, config.inputs);
    dispatch({type: 'validate', payload: errors});
    if (config.setInitialValues) {
      dispatch({type: 'change', payload: getInitialState(config).values })
    }
  }, [state.values, config.inputs, config.showErrorsOnSubmit]);

  const errors = useMemo(() => getErrors(state, config), [state, config]);
  const isFormValid = useMemo(() => errors && Object.keys(errors).length > 0 ? false : true, [errors]);
  return {
    errors,
    isFormValid,
    submitted: state.submitted,
    values: state.values,
    getActionProps: (status, closeDialog) => ({
      onClick: e => {
        e.preventDefault();
        dispatch({type: 'submit'});
        if (config.onClick) {
          config.onClick(state, status, closeDialog);
        }
      },
    }),
    getDeleteProp: () => ({
      onClick: e => {
        e.preventDefault();
        if (config.deleteFunction) {
          config.deleteFunction();
        }
      }
    }),
    getDeletionConfirmationProp: () => ({
      onClick: e => {
        e.preventDefault();
        if (config.deletionConfirmationFunction) {
          config.deletionConfirmationFunction();
        }
      }
    }),
    setValue: (inputName, inputValue) => {
      dispatch({
        type: 'change',
        payload: {[inputName]: inputValue},
      });
    },
    getInputProps: (inputName, otherProps) => ({
      onChange: (event) => {
        const value = event?.target?.value;
        dispatch({
          type: 'change',
          payload: {[inputName]: value},
        });
      },
      name: inputName,
      error: errors[inputName] ? true : false,
      value: config.inputs[inputName].mask
        ? config.inputs[inputName].mask(state.values[inputName] ?? null)
        : state.values[inputName] ?? null,
      ...otherProps
    }),
    getExportProps: () => ({
      onClick: e => {
        e.preventDefault();
        if (config.exportXml) {
          config.exportXml()
        }
      }
    })
  };
};
export const ValidationContext = createContext({});
export const ValidationProvider = props => {
  const context = useValidation(props.config);
  const memoizedContext = useMemo(() => context, [context]);
  return (
    <ValidationContext.Provider value={memoizedContext}>
      {props.children}
    </ValidationContext.Provider>
  )
};
const validateInput = (value = '', config) => {
  const specialProps = ['initialValue', 'mask'];
  for (let validatorName in config) {
    if (specialProps.includes(validatorName)) {
      continue;
    }
    const validatorConfig = config[validatorName];
    const configuredValidator = validators[validatorName](validatorConfig);
    const errorMessage = configuredValidator(value);
    if (errorMessage) {
      return errorMessage;
    }
  }
  return null;
};

const validateInputs = (values, configs) => {
  const errors = {};
  for (let inputName in configs) {
    const config = configs[inputName];
    const val = values[inputName];
    const errorMessage = validateInput(val, config);
    if (errorMessage !== null) {
      errors[inputName] = validateInput(val, config);
    }
  }
  return errors;
};

const getInitialState = (config) => {
  const initialValues = {};
  if (typeof config === 'function') {
    config = config({});
  }
  for (let inputName in config.inputs) {
    initialValues[inputName] = config.inputs[inputName].initialValue ?? null;
  }
  return {
    values: initialValues,
    submitted: false,
    errors: {},
  };
};
const getErrors = (state, config) => {
  const errors = state.errors
  if (config.extraErrors) {
    for (let errorField in config.extraErrors) {
      errors[errorField] = config.extraErrors[errorField]
    }
  }
  if (config.showErrors === 'always') {
    return errors;
  }
  return state.submitted ? errors : {};
};
