import TextField from '@material-ui/core/TextField';

//used for invoices
export default function SmallTextField(props) {
  return (
    <>
      <TextField
        variant="outlined"
        size="small"
        className={"no-print"}
        style={{marginBottom: '0px'}}
        InputLabelProps={{
          shrink: true,
          style: {
            position: 'relative',
            transform: 'unset',
            fontSize: '0.9rem',
            fontWeight: '600',
            letterSpacing: '0.2px',
            lineHeight: '1.3em',
            color: '#0062A3'
          }
        }}
        {...props}
        InputProps={{notched: false}}
        inputProps={{
          style: {padding: '6px 14px'}
        }}
      />

    </>
  );
}
