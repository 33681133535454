import {Typography, Grid} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {formatIBAN} from 'shared/utility';

export default function SelectedDetail({paymentDetails}) {
  const [t] = useTranslation();
  return (
    <>
      <Grid container spacing={1}>
        {paymentDetails?.bankAccount ? <Grid item xs={12} sm={6}>
          <Typography color="primary" variant="subtitle1">
            {t("paymentDetails.bankAccount")}
          </Typography>
          <Typography variant="body1">
            {formatIBAN(paymentDetails.bankAccount)}
          </Typography>
        </Grid> : null}
        {paymentDetails?.businessId ? <Grid item xs={12} sm={6}>
          <Typography color="primary" variant="subtitle1">
            {t("paymentDetails.businessId")}
          </Typography>
          <Typography variant="body1">
            {paymentDetails.businessId}
          </Typography>
        </Grid> : null}
        {paymentDetails?.swift ? <Grid item xs={12} sm={6}>
          <Typography color="primary" variant="subtitle1">
            {t("paymentDetails.swift")}
          </Typography>
          <Typography variant="body1">
            {paymentDetails.swift}
          </Typography>
        </Grid> : null}
        {paymentDetails?.vatId ? <Grid item xs={12} sm={6}>
          <Typography color="primary" variant="subtitle1">
            {t("paymentDetails.vatId")}
          </Typography>
          <Typography variant="body1">
            {paymentDetails.vatId}
          </Typography>
        </Grid> : null}
      </Grid>
    </>
  );
}
