import {Typography, Button} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import SelectedDetail from './SelectedDetail';

export default function Details({openDialog, paymentDetails, disableInputs}) {
  const [t] = useTranslation();

  return (
    <>
      <Typography variant="h3">
        {t("invoice.paymentDetails")}
        {!disableInputs &&
        <Button component="div" onClick={openDialog}>
          <Typography color="primary" variant="overline">
            {!paymentDetails ?
              t("invoice.addPaymentDetails") :
              t("invoice.editPaymentDetails")
            }
          </Typography>
        </Button>
        }
      </Typography>
      <SelectedDetail paymentDetails={paymentDetails} />
    </>
  );
}
