import {Select, MenuItem, FormControl, InputLabel, Box} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

export default function List({payersData, selected, setSelected, type}) {
  const [t] = useTranslation();

  return (
    <div>
      <Box my={2}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>
            {t(`reusables.${type}PayerLabel`)}
          </InputLabel>
          <Select
            labelId="label-selected"
            onChange={(event) => setSelected(event.target.value)}
            value={selected}
            label={t(`reusables.${type}PayerLabel`)}
            fullWidth
          >
            {payersData.map((payer =>
              <MenuItem key={payer.id} value={payer.id}>
                {payer.dataName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
}
