import {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {TextField, Grid} from "@material-ui/core";
import {DeliveryDetails} from 'shared/FormComponents';
import {ValidationContext} from 'shared/useValidation';

export default function Form() {
  const [t] = useTranslation();
  const {getInputProps} = useContext(ValidationContext);

  return (
    <Grid container spacing={3}>
      <Grid item sm={12}>
        <TextField
          label={t("paymentDetails.dataName")}
          required
          variant="outlined"
          fullWidth
          {...getInputProps('dataName')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          label={t("payer.payerName")}
          required
          {...getInputProps("payerName")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          label={t("payer.payerNameSupplement")}
          helperText={t("payer.nameSupplementHelper")}
          {...getInputProps("payerNameSupplement")}
        />
      </Grid>
      <DeliveryDetails/>
    </Grid>
  );
}
