import {useContext} from "react";
import {ValidationContext} from "shared/useValidation";
import {useTranslation} from 'react-i18next'
import SmallTextField from "shared/FormComponents/SmallTextField";
import {Typography, Box} from "@material-ui/core";

export default function VatExclusion({disableInputs}) {
  const [t] = useTranslation();
  const {getInputProps, values} = useContext(ValidationContext);
  return (
    <>
      {(values?.vatExclusionReason && disableInputs) &&
      <Box>
        <Typography variant="subtitle1" color="primary">{t("invoice.vatExclusionReason")}</Typography>
        <Typography variant="body1">{values.vatExclusionReason}</Typography>
      </Box>}
      {!disableInputs &&
        <SmallTextField
          fullWidth
          className={"no-print"}
          inputProps={{maxLength: 60}}
          disabled={disableInputs}
          onInput={(e) => e.target.value = e.target.value.slice(0, 60)}
          label={t("invoice.vatExclusionReason")}
          {...getInputProps("vatExclusionReason")}
        />
      }
    </>
  );
}
