import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {Authorize} from '@postinumero/authorization';
import Page from '../../../Page';
import Forbidden from '../../Forbidden';
import BackButton from 'App/BackButton';
import HasId from './HasId';
import {editCA} from 'App/Pages';
import Alert from "@material-ui/lab/Alert";

export default function Edit() {
  const [t] = useTranslation();
  const {caId} = useParams();
  return (
    <Page title={t("event.editTitle")}>
      {caId ? (
        <Authorize allow="Issuer agent" fallback={<Forbidden/>}>
          <HasId caId={caId}/>
        </Authorize>
      ) : (
        <>
          <Alert severity="warning">
            {t('error:missingRouteParam', {path: editCA})}
          </Alert>
          <BackButton/>
        </>
      )}
    </Page>
  );
}
