import {List,makeStyles} from "@material-ui/core";
import {useAuthorize} from '@postinumero/authorization';
import InvoiceIA from './CommissionFeeInvoiceList/InvoiceIA';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0'
  }
}))

export default function ConsolidatedInvoiceList({invoices}) {
  const classes = useStyles();
  const isIA = useAuthorize({allow: ['Issuer agent', 'Inspector']});
  return (
    <>
    {isIA && invoices?.length ?
      <List className={classes.root}>
          {invoices.map((item) => {
            return (
              <li key={item.id}>
                  <InvoiceIA consolidated key={item.id} invoiceData={item}/>
              </li>
            );
          })
        }
      </List>
    : null }
    </>
  );
}
