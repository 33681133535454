import {makeStyles} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import NavItems from './NavItems';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: 0,
    height: '60px',
    '& a': {
      color: 'white',
      padding: '20px 15px 10px 15px',
      textDecoration: 'none',
      whiteSpace: 'nowrap',
      fontWeight: '600',
      fontSize: '1.1rem',
      "&:hover": {
        textDecoration: 'none'
      }
    }
  }
}));

export default function Navigation() {
  const classes = useStyles();
  return (
    <Hidden xsDown>
      <NavItems className={classes.root}/>
    </Hidden>
  );
}
