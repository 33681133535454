import MomentUtils from "@date-io/moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import EventIcon from '@material-ui/icons/Event';

export default function DateInput({label, required, ...formstate}) {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        inputVariant="outlined"
        variant="inline"
        format="DD.MM.YYYY"
        autoOk
        fullWidth
        label={label}
        required={required}
        mask="__.__.____"
        {...formstate}
        InputProps={{
          required: required,
          endAdornment: (
            <EventIcon color="primary"/>
          ),
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
