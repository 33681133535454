import {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {useHistory, useLocation} from 'react-router-dom';
import Table from './Table';
import Filters from './Filters';
import {useDeepCompareEffect} from 'shared/useDeepCompareEffect';
import {useUser} from '@postinumero/authorization';
import MomentAdapter from '@date-io/moment';


export default function Layout({invoices, consolidatedInvoices}) {
  const moment = new MomentAdapter();
  const history = useHistory();
  const location = useLocation();
  const user = useUser();
  const allInvoices = invoices.concat(consolidatedInvoices.map(obj => { return  {...obj, isCons: true }}))

  const params = new URLSearchParams(location.search);

  const date = new Date();
  date.setMonth(date.getMonth() - 1);

  const initialFilter = {
    statuses: params.get('statuses')?.split(',') ?? [],
    types: params.get('types')?.split(',') ?? [],
    search: params.get('search') ?? '',
    startDate: params.get('startDate') ?? moment.date(date.setHours(0, 0, 0, 0)),
    endDate: params.get('endDate') ?? null
  };

  const [activeFilters, setActiveFilters] = useState(initialFilter);
  const [visibleInvoices, setVisibleInvoices] = useState(allInvoices);

  const resetFilter = () => {
    history.push({pathname: '/invoices'});
    setActiveFilters({statuses: [], types: [], search: '', startDate: null, endDate: null});
  };

  const updateParams = () => {
    const esc = encodeURIComponent;
    const query = Object.keys(activeFilters)
      .filter(key => (activeFilters[key] && (activeFilters[key]?.length > 0 || Object.keys(activeFilters[key]).length > 0)))
      .map(key => esc(key) + '=' + (Array.isArray(activeFilters[key]) ? esc(activeFilters[key]?.join(',')) : activeFilters[key] instanceof Object && activeFilters[key]?.isValid() ? activeFilters[key].format('YYYY-MM-DD') : esc(activeFilters[key])));
    if (query) {
      history.push({pathname: '/invoices', search: query.join('&')});
    }
  };

  useDeepCompareEffect(() => {
    updateParams();
    const filtered = allInvoices.filter(invoice => {
      const {paymentDetails, payerDetails, beneficiaryData, invoiceNumber, referenceNumber, vatExclusionReason, additionalInformation, corporateAction, invoicingPeriod} = invoice;
      const prefixedObject = (data, prefix) => {
        return data ? Object.assign(
          {},
          ...Object.keys(data).map(key => ({[prefix + key]: data[key]}))
        ) : null
      };
      const textSearchObject = {
        invoiceNumber,
        referenceNumber,
        vatExclusionReason,
        additionalInformation,
        ...prefixedObject(beneficiaryData, 'b_'),
        ...prefixedObject(paymentDetails, 'payment_'),
        payment_BankAccountSpaced: paymentDetails.bankAccount?.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim(),
        ...prefixedObject(payerDetails, 'payer_'),
        ...prefixedObject(corporateAction, 'ca_'),
        ...prefixedObject(corporateAction.actionEventType, 'cae_'),
        ...prefixedObject(invoicingPeriod, 'period_'),
        commissionFeesDescriptions: invoice.invoiceLines?.length ? invoice.invoiceLines.map(line => line.description).join(', ') : '',
        accountOperatorNames: invoice.invoices?.length ? invoice.invoices.map(inv => inv.organisation).join(', ') : ''
      };

      const invoiceDate = new Date(invoice.invoiceDate).setHours(0, 0, 0, 0);
      const dueDate = new Date(invoice.dueDate).setHours(0, 0, 0, 0);
      const supplyOfServiceDate = new Date(invoice.supplyOfService).setHours(0, 0, 0, 0);
      const start = activeFilters.startDate ? new Date(activeFilters.startDate).setHours(0, 0, 0, 0) : null;
      const end = activeFilters.endDate ? new Date(activeFilters.endDate).setHours(0, 0, 0, 0) : null;
      return (
        (activeFilters.statuses.indexOf(invoice.currentStatus) > -1 || activeFilters.statuses.length === 0)
        && (
          (activeFilters.types.indexOf('Own_consolidated_invoices') > -1 && invoice.isCons) ||
          (activeFilters.types.indexOf('Own_commission_fee_invoices') > -1 && !invoice.isCons && invoice.organisation === user.groups) ||
          (activeFilters.types.indexOf('Received_commission_fee_invoices') > -1 && !invoice.isCons && invoice.corporateAction.organisation === user.groups) ||
          activeFilters.types.length === 0)
        && Object.values(textSearchObject).filter(Boolean).find(val => val.toString().toLowerCase().includes(activeFilters.search.toLowerCase()))
        && (
          ((start <= invoiceDate || !start) && (end >= invoiceDate || !end))
          || ((start <= dueDate || !start) && (end >= dueDate || !end))
          || ((start <= supplyOfServiceDate || !start) && (end >= supplyOfServiceDate || !end)))
      );
    });
    setVisibleInvoices(filtered);

  }, [activeFilters, allInvoices]);

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Filters activeFilters={activeFilters} setFilters={setActiveFilters} reset={resetFilter} />
      </Grid>
      <Grid item xs={12}>
        <Table invoices={visibleInvoices} user={user} />
      </Grid>
    </Grid>
  );
}
