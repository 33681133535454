import {Accordion, AccordionSummary, Typography, AccordionDetails, Grid, Link} from '@material-ui/core';
import {Authorize, useUser} from '@postinumero/authorization';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import {generatePath, Link as RouterLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {editInvoicingPeriod, newConsolidatedInvoice} from 'App/Pages';
import CommissionFeeInvoiceList from './CommissionFeeInvoiceList';
import ConsolidatedInvoiceList from './ConsolidatedInvoiceList';
import {newInvoice} from 'App/Pages';
import {LOCKED, INVALID, INVOICINGENABLED} from 'shared/Statuses';
import {formatDate, removeUnderscores} from 'shared/utility';

export default function InvoicingPeriodList({invoicingPeriod, classes, organisation, openHistory}) {
  const [t] = useTranslation();
  const status = invoicingPeriod?.statuses[0];
  const userOrganisation = useUser()?.groups;
  const statusDate = formatDate(status?.timestamp);
  const startDate = formatDate(invoicingPeriod.startDate);
  const endDate = formatDate(invoicingPeriod.endDate);
  const showAddNewConsolidatedInvoice = (
    invoicingPeriod.currentStatus === LOCKED &&
    !invoicingPeriod.consolidatedInvoices.find(invoice => invoice.currentStatus !== INVALID) &&
    organisation === userOrganisation &&
    invoicingPeriod.invoices.length > 0
  )
  return (
    <>
      <Accordion key={invoicingPeriod.id} defaultExpanded={true} className={classes.childRoot}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="primary"/>}
          className={classes.summary}
          aria-controls={'panel_' + invoicingPeriod.id}
          id={invoicingPeriod.id}
        >
          <AssignmentOutlinedIcon style={{marginRight: '10px'}}/>
          <Typography variant="h3">
            {t("invoicingPeriod.summary",
              {start: startDate, end: endDate, status: removeUnderscores(status?.status), statusDate: statusDate})}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.innerContent}>
          <Grid item xs={12} className={classes.actions}>
            <Authorize allow="Issuer agent">
              {organisation === userOrganisation &&
              <Typography variant="overline">
                <Link
                  component={RouterLink}
                  to={generatePath(editInvoicingPeriod, {id: invoicingPeriod.id})}>{t("invoicingPeriod.editTitle")}</Link>
              </Typography>}
              {showAddNewConsolidatedInvoice &&
              <Typography variant="overline">
                <Link
                  component={RouterLink}
                  to={generatePath(newConsolidatedInvoice + '?ip=' + invoicingPeriod.id)}>{t("consolidatedInvoice.addTitle")}</Link>
              </Typography>}
            </Authorize>
            <Authorize allow="Account operator">
              {invoicingPeriod.currentStatus === INVOICINGENABLED &&
              <Typography variant="overline">
                <Link
                  to={{pathname: newInvoice, search: '?ip=' + invoicingPeriod.id}}
                  component={RouterLink}>
                  {t("invoice.addTitle")}
                </Link>
              </Typography>}
            </Authorize>
            <Authorize allow="Inspector">
              {organisation === userOrganisation && <Typography variant="overline">
                <Link type="button" onClick={() => openHistory(invoicingPeriod?.statuses)}>
                  {t("events.viewHistory")}
                </Link>
              </Typography>
              }
            </Authorize>
          </Grid>
          <Grid item xs={12}>
            <ConsolidatedInvoiceList invoices={invoicingPeriod?.consolidatedInvoices}/>
            <CommissionFeeInvoiceList invoices={invoicingPeriod?.invoices} CAOrg={organisation}/>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
