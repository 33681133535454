import {TableCell, TableRow} from '@material-ui/core';
import {formatDecimal} from 'shared/utility';

export default function CustomTableRow({invoice}) {
  return (
    <>
      <TableRow style={{borderBottom: 5}}>
        <TableCell>{invoice?.beneficiaryData?.beneficiaryName}</TableCell>
        <TableCell>{invoice?.invoiceNumber}</TableCell>
        <TableCell>{invoice?.paymentDetails?.swift}</TableCell>
        <TableCell>{invoice?.paymentDetails?.bankAccount}</TableCell>
        <TableCell>{invoice?.referenceNumber}</TableCell>
        <TableCell>{invoice ? formatDecimal(invoice.totalAmount) : null}</TableCell>
      </TableRow>
      <TableRow style={{height: '2px', background: 'white'}}/>
    </>
  )
}
