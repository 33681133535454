import Page from 'App/Page';
import BackButton from 'App/BackButton';
import {useTranslation} from 'react-i18next';
import Alert from '@material-ui/lab/Alert';

export default function NotFound() {
  const [t] = useTranslation('error');

  return (
    <Page>
      <Alert severity="info">
        {t('noMatch')}
      </Alert>
      <BackButton/>
    </Page>
  );
}
